import React, { useEffect, useContext, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import get from 'lodash/get';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import toSafeInteger from 'lodash/toSafeInteger';

import moment from 'moment';
import styled from 'styled-components';
import {
  Spin,
  Descriptions,
  Button,
  Form,
  Input,
  Tooltip,
  Icon,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  AutoComplete,
} from 'antd';

import { isPassword } from '../../utils/form';

import { MainContext } from '../../context/main';
import { LongLine } from '../../style/global';

import { convertToDatetimeBE } from '../../utils'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 3 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
};
export default Form.create({ name: 'password' })(({ form }) => {
  const history = useHistory();

  const { request, userProfile, getProfile } = useContext(MainContext);

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [confirmDirty, setConfirmDirty] = useState(false);

  const back = () => {
    // history.push('/transactions');
    history.goBack();
  };

  const handleChangePassword = (e) => {
    console.log({
      e,
    });
    // e.preventDefault();
    form.validateFields(async (err, values) => {
      console.log({
        err,
        values,
      });
      if (!err) {
        const body = {
          old_password: values.old_password,
          new_password: values.password,
        };
        try {
          setLoading(true);

          console.log({
            body,
          });

          const res = await request.post(`/auth/change_password`, body);

          console.log({
            res,
          });

          if (get(res, ['success'])) {
            form.setFieldsValue({
              old_password: null,
              new_password: null,
              confirm: null,
            });
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();

    const body = omitBy(
      {
        first_name: form.getFieldValue('first_name'),
        last_name: form.getFieldValue('last_name'),
      },
      (v) => isNil(v) || v === '',
    );

    try {
      setLoading(true);

      const res = await request.post(`/auth/profile`, body);

      if (get(res, ['success'])) {
        await getProfile();
        form.setFieldsValue({
          first_name: null,
          last_name: null,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmBlur = (e) => {
    const { value } = e.target;
    setConfirmDirty(confirmDirty || !!value);
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('password')) {
      return callback('รหัสผ่านใหม่ไม่ตรงกัน');
    }

    if (!isPassword.test(value))
      return callback(
        'รหัสผ่าน ต้องไม่น้อยกว่า 8 ตัวอักษร ประกอบด้วยตัวใหญ่, ตัวเล็ก และตัวเลข',
      );

    callback();
  };

  const validateToNextPassword = (rule, value, callback) => {
    if (value && confirmDirty) {
      form.validateFields(['confirm'], { force: true });

      return callback();
    }

    if (!isPassword.test(value))
      return callback(
        'รหัสผ่าน ต้องไม่น้อยกว่า 8 ตัวอักษร ประกอบด้วยตัวใหญ่, ตัวเล็ก และตัวเลข',
      );

    callback();
  };

  const { getFieldDecorator } = form;

  return (
    <Spin spinning={loading}>
      <h2>ข้อมูลส่วนตัว</h2>
      <LongLine />
      <Descriptions title="" layout="horizontal">
        <Descriptions.Item label="ชื่อ">
          {get(userProfile, 'first_name')}
        </Descriptions.Item>
        <Descriptions.Item label="นามสกุล">
          {get(userProfile, 'last_name')}
        </Descriptions.Item>
        {/* <Descriptions.Item label="ระดับ">
          {get(userProfile, 'role') === 'super_admin'
            ? 'ผู้ดูแลระบบระดับสูง'
            : 'ผู้ดูแลระบบ'}
        </Descriptions.Item> */}
        <Descriptions.Item label="กลุ่มผู้ใช้งาน">
          {get(userProfile, 'groupName')}
        </Descriptions.Item>
        <Descriptions.Item label="หน่วยงาน">
          {get(userProfile, 'offLocDesc')}
        </Descriptions.Item>
        <Descriptions.Item label="ตำแหน่ง">
          {get(userProfile, 'positionDesc')}
        </Descriptions.Item>
        <Descriptions.Item label="username">
          {get(userProfile, 'username')}
        </Descriptions.Item>
        <Descriptions.Item label="created">
          {get(userProfile, 'created') &&
            convertToDatetimeBE(get(userProfile, 'created'))}
        </Descriptions.Item>
        <Descriptions.Item label="updated">
          {get(userProfile, 'updated') &&
            convertToDatetimeBE(get(userProfile, 'updated'))}
        </Descriptions.Item>
        {/* {get(userProfile, 'role') === 'admin' &&
          <Descriptions.Item label="การเข้าถึงข้อมูล Mobile">
            {get(userProfile, 'isAllowViewMobileTransactions') ? 'อนุญาต' : 'ไม่อนุญาต'}
          </Descriptions.Item>
        }
        {get(userProfile, 'role') === 'admin' &&
          <Descriptions.Item label="การเข้าถึงข้อมูล Kiosk">
            {
            userProfile.kiosksIds.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {index == 0 ? item.detail : `, ${item.detail}`}
                </React.Fragment>
              )
            })
            }
          </Descriptions.Item>
        } */}
      </Descriptions>
      {/* <h2>แก้ไขข้อมูลส่วนตัว</h2>
      <LongLine />
      <Form {...formItemLayout}>
        <Form.Item label="ชื่อ" hasFeedback>
          {getFieldDecorator('first_name', {})(<Input />)}
        </Form.Item>
        <Form.Item label="นามสกุล" hasFeedback>
          {getFieldDecorator('last_name', {})(<Input />)}
        </Form.Item>
        <Form.Item>
          <Button
            // style={{ marginLeft: '20px' }}
            type="primary"
            ghost
            onClick={handleUpdateProfile}
          >
            แก้ไขข้อมูลส่วนตัว
          </Button>
        </Form.Item>
      </Form>
      <LongLine /> */}
      {/* <h2>เปลี่ยนรหัสผ่าน</h2>
      <LongLine />
      <Form {...formItemLayout}>
        <Form.Item label="รหัสผ่านเดิม" hasFeedback>
          {getFieldDecorator('old_password', {
            // validateTrigger: null,
            rules: [
              {
                required: true,
                message: 'กรุณากรอกรหัสผ่านเดิม',
              },
            ],
          })(<Input.Password />)}
        </Form.Item>
        <Form.Item
          help="รหัสผ่าน ต้องไม่น้อยกว่า 8 ตัวอักษร ประกอบด้วยตัวใหญ่, ตัวเล็ก และตัวเลข"
          label="รหัสผ่านใหม่"
          hasFeedback
        >
          {getFieldDecorator('password', {
            // validateTrigger: null,
            rules: [
              {
                required: true,
                message: 'กรุณากรอกรหัสผ่านใหม่',
              },
              {
                validator: validateToNextPassword,
              },
            ],
          })(<Input.Password />)}
        </Form.Item>
        <Form.Item label="ยืนยัน รหัสผ่านใหม่" hasFeedback>
          {getFieldDecorator('confirm', {
            // validateTrigger: null,
            rules: [
              {
                required: true,
                message: 'กรุณากรอกรหัสผ่านใหม่อีกครั้ง',
              },
              {
                validator: compareToFirstPassword,
              },
            ],
          })(<Input.Password onBlur={handleConfirmBlur} />)}
        </Form.Item>
        <Form.Item>
          <Button
            // style={{ marginLeft: '20px' }}
            type="primary"
            ghost
            onClick={handleChangePassword}
          >
            เปลี่ยนรหัสผ่าน
          </Button>
        </Form.Item>
      </Form> */}
      <LongLine />
      <Button type="danger" ghost onClick={back}>
        ย้อนกลับ
      </Button>
    </Spin>
  );
});

const TextH3 = styled.span`
  font-size: 1.17em;
`;
const TextH5 = styled.span`
  font-size: 0.83em;
`;
