import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { convertToDatetimeBE } from '../../utils'
import { Table, Tag, Button, Icon, Divider, Popconfirm, Popover, Spin } from 'antd';
import { TagContainer, ButtonTag } from './style';
import _ from 'lodash';
import { MainContext } from '../../context/main';

import {
  getFilter,
  getSort,
  getPage,
  getColumnSearchProps,
  perPage,
  defaultPageSize,
  pageSizeOptions,
  showSizeChanger,
} from '../../utils/table';
import { formatPhoneNumber } from '../../utils';
import { HeaderBlock } from '../../style/global';
import FilterModal from '../../component/FilterModal';
import FormModal from './form';
import Header from 'antd/lib/calendar/Header';
import officeCode from '../../utils/staticData/officeCode';


const isAllow = (menuPermissions, targetmenuPermissions) => {
  const thisMenuId = "9"
  const permission = _.find(menuPermissions, { menuId: thisMenuId });
  return permission && permission.canManage;
};

function getColor(status) {
  switch (status) {
    case "active":
      return 'green';
    case "lock":
      return 'orange';
    default:
      return '';
  }
}

function getWord(status) {
  switch (status) {
    case "active":
      return 'เปิดใช้งาน';
    case "lock":
      return 'ปิดใช้งาน';
    default:
      return 'ไม่พร้อมใช้งาน';
  }
}

const PopOverTag = ({ admin, onStatusChange }) => {
  const currentStatus = _.get(admin, ['status']);
  const color = getColor(currentStatus);
  const [visible, setVisible] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const { request } = useContext(MainContext);

  const onVisibleChange = (e) => {
    setVisible((prevState) => !prevState);
  };

  const handleSelect = (newStatus) => async (e) => {
    if (newStatus === currentStatus) return;

    const body = {
      status: newStatus,
    };
    setIsLoading(true);

    const res = await request.put(`/admins/${admin._id}/status`, body);

    setIsLoading(false);

    if (_.get(res, ['success'])) {
      onStatusChange({
        id: admin._id,
        newStatus,
      });
    }

    onVisibleChange();
  };

  return (
    <Popover
      content={(
        <Spin spinning={isLoading}>
          <TagContainer>
            <ButtonTag underline onClick={handleSelect("active")}>
              <Tag color={currentStatus === "active" ? 'grey' : 'green'}>
                {getWord("active")}
              </Tag>
            </ButtonTag>
            <ButtonTag underline onClick={handleSelect("lock")}>
              <Tag color={currentStatus === "lock" ? 'grey' : 'orange'}>
                {getWord("lock")}
              </Tag>
            </ButtonTag>
          </TagContainer>
        </Spin>
      )}
      title="สถานะการเปิดใช้งาน"
      trigger="click"
      visible={visible}
      onVisibleChange={onVisibleChange}
    >
      <ButtonTag>
        <Tag color={color}>
          {' '}
          {getWord(currentStatus)}
        </Tag>
      </ButtonTag>
    </Popover>
  );
};

export default () => {
  const { request, userProfile } = useContext(MainContext);
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState({});
  const [sorter, setSorter] = useState({
    sort: { key: 'created', desc: false },
  });
  const [pagination, setPagination] = useState({
    // pageSize: perPage,
    // defaultPageSize: 10,
    defaultPageSize,
    pageSizeOptions,
    showSizeChanger,
  });
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [targetUser, setTargetUser] = useState(null);
  const [kioskList, setKioskList] = useState([])

  const fetchData = async (params = {}) => {
    try {
      setLoading(true);
      const res = await request.get('/admins/onlyassigned', {
        ...params,
        ...searchData,
      });

      const totalData = _.get(res, ['total_data'], 0);

      setData(_.get(res, ['results'], []));
      setPagination({ ...pagination, total: totalData, current: params.page, pageSize: params.per_page });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchKiosk = async () => {
    const res = await request.get('/kiosk', {
      per_page: 100
    })
    setKioskList(res.results)
  }

  const handleDelete = (id) => async () => {
    try {
      setLoading(true);
      const res = await request.delete(`/admins/onlyassigned/${id}`);

      if (_.get(res, ['success'])) {
        const pager = { ...pagination };
        const current = pager.current;
        const totalData = pager.total - 1;

        if (data.length === 1 && current > 1) {
          pager.current = current - 1; // Move to the previous page if the current page becomes empty
        }

        setData(data.filter((item) => item._id !== id));
        setPagination({ ...pager, total: totalData });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (paginationChange, filters, sorterChange) => {
    const pager = { ...pagination };
    pager.current = paginationChange.current;

    setPagination(pager);
    setSorter(getSort(sorterChange));

    fetchData({
      ...getFilter(filters),
      ...getSort(sorterChange),
      ...getPage(paginationChange),
    });
  };

  useEffect(() => {
    fetchData({
      ...getPage(pagination),
      ...sorter,
    });
    fetchKiosk()
  }, [searchData]);

  const [adminPermissionIdList, setAdminPermissionIdList] = useState([]);
  const fetchAdminPermissionIds = async () => {
    try {
      const response = await request.get('/adminpermission?per_page=10000');
      const data = response.results;
      console.log(data);

      // Update the options array with the fetched data
      const updatedOptions = [
        { title: 'ทั้งหมด', value: '' },
        { title: 'ยังไม่กำหนด', value: 'NULL' },
        ...data.map(item => ({ title: item.groupName, value: item._id })),
      ];

      // Update the state with the fetched data
      setAdminPermissionIdList(data);

      // Update the searchKey object with the new options array
      setSearchKey(prevSearchKey => ({
        ...prevSearchKey,
        3: {
          ...prevSearchKey[3],
          options: updatedOptions,
        },
      }));
    } catch (error) {
      console.error('Error fetching adminPermissionIds:', error);
      // Handle the error case
    }
  };

  useEffect(() => {
    fetchAdminPermissionIds();
  }, [visible]);

  const [searchKey, setSearchKey] = useState([
    { label: 'ชื่อบัญชีผู้ใช้งาน', key: 'username' },
    { label: 'ชื่อ', key: 'name' },
    {
      label: 'หน่วยงาน', key: 'offLocCode', type: 'select2', isFilterByValue: true,
      options: [
        { title: "ทั้งหมด", value: "" },
        ...officeCode.map((o) => ({ title: o.OFF_LOC_DESC, value: o.OFF_LOC_CODE }))
      ]
    },
    {
      label: 'กลุ่มผู้ใช้งาน',
      key: 'adminPermissionId',
      type: 'select2',
      options: [], // Initially empty
    },

  ]);



  const handleSearch = (value) => {
    pagination.current = 1;
    setSearchData({ ..._.omitBy(value, (o) => _.isNil(o) || _.isEmpty(o)) });

    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOpenModal = () => {
    setVisible(true);
  };

  const handleOpenModalForm = (user) => (e) => {
    setTargetUser(user);
    setFormVisible(true);
  };

  const handleCloseModalForm = () => {
    setTargetUser(null);
    setFormVisible(false);
  };

  const onStatusChange = ({ newStatus, id }) => {
    setData(
      _.map(data, (o) => {
        if (o._id === id) o.status = newStatus;

        return o;
      }),
    );
  };

  const handleDownload = async (fileName = 'รายงานจัดการผู้ใช้งานระบบ.xlsx') => {
    if (loading) return;
    try {
      fileName = 'รายงานจัดการผู้ใช้งานระบบ.xlsx';
      await request.download('/admins/report', fileName, {
        ...getPage(pagination),
        ...sorter,
        ...searchData
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: 'ลำดับ',
      key: 'index',
      dataIndex: 'index',
    },
    {
      title: 'ชื่อบัญชีผู้ใช้งาน',
      key: 'username',
      dataIndex: 'username',
    },
    {
      title: 'ชื่อ-นามสกุลผู้ใช้งาน',
      key: 'first_name',
      render: (record) => `${record.first_name} ${record.last_name}`,
    },
    {
      title: 'หน่วยงาน',
      key: 'offLocDesc',
      render: (record) => `${record.offLocDesc ? record.offLocDesc : '-'}`,
    },
    {
      title: 'กลุ่มผู้ใช้งาน',
      key: 'adminPermissionGroupName',
      dataIndex: 'adminPermissionGroupName',
    },
    {
      title: 'สถานะ',
      key: 'status',
      dataIndex: 'status',
      render: (status, o) => (
        isAllow(userProfile.menuPermissions) ? (
          <PopOverTag onStatusChange={onStatusChange} admin={o} />
        ) : (
          <Tag color={status == "active" ? 'green' : 'orange'}>{status == "active" ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}</Tag>
        )
      ),
    },
    {
      title: 'กำหนดสิทธิ์เมื่อ',
      dataIndex: 'updated',
      key: 'updated',
      sorter: () => { },
      render: (v) => convertToDatetimeBE(v),
    },
    {
      title: isAllow(userProfile.menuPermissions) ? 'จัดการ' : '',
      width: isAllow(userProfile.menuPermissions) ? '10%' : '0px',
      key: 'action',
      render: (o) =>
        isAllow(userProfile.menuPermissions) ? (
          <span>
            <a
              onClick={
                isAllow(userProfile.menuPermissions, o.menuPermissions)
                  ? handleOpenModalForm(o)
                  : () => { }
              }
            >
              กำหนดกลุ่มผู้ใช้งาน
            </a>
            {/* <Divider type="vertical" />
            <Popconfirm
              key="delete"
              title="กรุณายืนยันการลบผู้ดูแลระบบ"
              onConfirm={handleDelete(_.get(o, ['_id']))}
              okText="ตกลง"
              cancelText="ยกเลิก"
            >
              <a>ลบ</a>
            </Popconfirm> */}
          </span>
        ) : null,
    },
  ];

  const onSubmit = async ({
    _id,
    adminPermissionId,
  }) => {
    setFormLoading(true);
    let body = {};
    let res;
    try {
      if (targetUser) {
        body._id = _.get(targetUser, ['_id']);
        body.adminPermissionId = adminPermissionId;
        res = await request.put('/admins/onlyassigned', body);
      } else {
        body = {
          _id,
          adminPermissionId,
        };
        res = await request.post('/admins/onlyassigned', body);
      }
      if (_.get(res, ['success'])) {
        handleCloseModalForm();
        fetchData({
          ...getPage(pagination),
          ...sorter,
        });
      }

    } catch (e) {
      console.error(e);
    } finally {
      setFormLoading(false);
    }
  };

  return (
    <>
      <HeaderBlock style={{ fontSize: '20px', fontWeight: 'bold' }}>
        บันทึกจัดการผู้ใช้งานเข้ากลุ่มผู้ใช้งาน
      </HeaderBlock>
      <HeaderBlock>
        <Button type="primary" onClick={handleOpenModal}>
          ค้นหา
        </Button>
        {isAllow(userProfile.menuPermissions) && (
          <div>
            <Button
              style={{ margin: '0 0 0 16px' }}
              icon="export"
              onClick={handleDownload}
            >
              Export
            </Button>
            {/* <Button type="primary"
              style={{ margin: '0 0 0 16px' }}

              onClick={null}
            >
              ประมวลผลยกเลิกผู้ใช้งาน
            </Button> */}
          </div>
        )}
        {/* {isAllow(userProfile.menuPermissions) && (
          <Button type="primary" onClick={handleOpenModalForm(null)}>
            เพิ่ม
          </Button>
        )} */}
      </HeaderBlock>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 'max-content' }}
      />
      <FilterModal
        visible={visible}
        handleSearch={handleSearch}
        handleCancel={handleCancel}
        renderInputFields={searchKey}
      />
      <FormModal
        onSubmit={onSubmit}
        formLoading={formLoading}
        data={targetUser}
        visible={formVisible}
        onCancel={handleCloseModalForm}
        kioskList={kioskList}
      ></FormModal>
    </>
  );
};
