import React from 'react';
import { Button, Modal, Form, DatePicker } from 'antd';

import moment from 'moment';
import { convertToDatetimeBE, convertToDateBE } from '../../utils';
export default Form.create({ name: 'time_related_controls' })(
  ({
    onClose,
    visible,
    form: { validateFields, getFieldDecorator },
    handleExcelSummaryDailyDownload,
    confirmLoading,
    title = 'กรุณาเลือก',
    filename = 'report',
  }) => {
    const handleSubmit = (e) => {
      e.preventDefault();

      validateFields((err, fieldsValue) => {
        if (err) {
          return;
        }

        // Should format date value before submit.
        const dateValue = fieldsValue['date-picker'];
        const values = {
          'date': dateValue.format('YYYY-MM-DD'),
        };
        console.log('Received values of form: ', values);
        handleExcelSummaryDailyDownload(
          // `${filename}-${values['start-date']}-${values['stop-date']}.xlsx`,
          `Export ข้อมูลรายวัน ${convertToDateBE(moment())}.xlsx`,
          values,
        );
      });
    };

    const dateConfig = {
      rules: [{ type: 'object', required: true, message: 'กรุณาเลือกวันที่' }],
    };

    return (
      <Modal
        title={title}
        visible={visible}
        onOk={handleSubmit}
        onCancel={onClose}
        confirmLoading={confirmLoading}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item label="เลือกวันที่">
            {getFieldDecorator('date-picker', dateConfig)(<DatePicker />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  },
);
