import React, { useEffect, useState, useContext } from 'react';
import {
  Modal,
  Form,
  Select,
  DatePicker,
  Input,
  Button,
  Spin,
  TimePicker,
  Icon,
  Switch,
  message
} from 'antd';
import _, { now } from 'lodash';
import get from 'lodash/get';
import { MainContext } from '../../context/main';
import styled from 'styled-components';
import moment from 'moment';

const { Option } = Select;

export default Form.create()(
  ({
    visible,
    onSubmit = () => {},
    onCancel = () => {},
    form: { getFieldDecorator, validateFields, resetFields, getFieldValue },
    data,
    formLoading,
    
  }) => {
    const handleSubmit =  (e) => {
      e.preventDefault();

      validateFields(async (err, fieldsValue) => {
        if (err) {
          return;
        }
        onSubmit(_.omit(fieldsValue));
      });
    };
    
    useEffect(() => {
      if (!visible) {
        resetFields();
      }
    }, [visible]);


    return (
      
      <CustomModal
        title={data ? 'แก้ไข' : 'เพิ่ม'}
        visible={visible}
        style={{ minWidth: '800px' }}
        onCancel={onCancel}
        footer={[
          <Button key="back" onClick={onCancel}>
            ยกเลิก
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit}>
            บันทึก
          </Button>,
        ]}
      >
        
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>
          <Spin spinning={formLoading}>
            <Form.Item label="ชื่อรายการ">
              {getFieldDecorator('title', {
                initialValue: get(data, ['title']),
                rules: [
                  {
                    required: !data,
                    message: 'กรุณากรอกชื่อสื่อออนไลน์',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="ข้อความแจ้งเตือน">
              {getFieldDecorator('AlertMessage', {
                initialValue: get(data, ['AlertMessage']),
                rules: [
                  {
                    required: !data,
                    message: 'กรุณากรอกคำอธิบาย',
                  },
                ],
              })(<Input.TextArea style={{ resize: 'none',height: '120px' }}  />)}
            </Form.Item>
            <Form.Item label="สถานะ">
              {getFieldDecorator('isActive', {
                valuePropName: 'checked',
                initialValue: get(data, ['isActive'], true),
              })(
                <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" />
              )}
            </Form.Item>
            <Form.Item label="วันที่เริ่ม">
              {getFieldDecorator('periodDateStart', {
                initialValue: moment(get(data, ['periodStart'])) || moment(),
                rules: [
                  {
                    required: !data,
                    message: 'กรุณากรอกวันที่เริ่ม',
                  }
                ],
              })(
                <DatePicker defaultPicker={moment()} />
              )}
              {getFieldDecorator('periodTimeStart', {
                initialValue: moment(get(data, ['periodStart'])) || moment(),
                rules: [
                  {
                    required: !data,
                    message: 'กรุณากรอกเวลาเริ่ม',
                  }
                ],
              })(
                <TimePicker defaultPicker={moment()} format={'HH:mm'} />
              )}
            </Form.Item>
            <Form.Item label="วันสิ้นสุด">
              {getFieldDecorator('periodDateEnd', {
                initialValue: moment(get(data, ['periodEnd'])) || moment(),
                rules: [
                  {
                    required: !data,
                    message: 'กรุณากรอกวันสิ้นสุด',
                  }
                ],
              })(
                <DatePicker/>
              )}
              {getFieldDecorator('periodTimeEnd', {
                initialValue: moment(get(data, ['periodEnd'])) || moment(),
                rules: [
                  {
                    required: !data,
                    message: 'กรุณากรอกเวลาสิ้นสุด',
                  }
                ],
              })(
                <TimePicker defaultPicker={moment()} format={'HH:mm'} />
              )}
            </Form.Item>
          </Spin>
        </Form>
      </CustomModal>
    );
  },
);

export const CustomModal = styled(Modal)`
  #kiosksIds {
    display: inline-block;
  }
  .ant-checkbox-group-item {
    margin: 0;
    width: 50%;
  }
`;