import get from 'lodash/get';
import split from 'lodash/split';
import toSafeInteger from 'lodash/toSafeInteger';
import toNumber from 'lodash/toNumber';

export const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }

  const fileList = get(e, ['fileList']);

  if (toSafeInteger(get(fileList, ['length'])) > 1) {
    fileList.shift();
  }

  return e && e.fileList;
};

export const beforeUpload = (newFile) => false;

export const dummyRequest = (e) =>
  setTimeout(() => {
    const { onSuccess } = e;

    onSuccess('OK');
  });

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const isPassword = /^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d\W])|(?=.*\W)(?=.*\d))|(?=.*\W)(?=.*[A-Z])(?=.*\d)).{8,}/;

export function makeDecimalNumber(text) {
  const last = get(text, ['length'], 0);

  if (!last) return text;

  const num = toSafeInteger(text.substr(0, last - 2));
  const decimal = text.substr(last - 2, 2);

  return numberWithCommas({ num: `${num}.${decimal}` }, 'num');
}

export function numberWithCommas(obj, key) {
  const n = get(obj, key);
  if (!n) return n;

  const splitN = split(n, '.');

  const num = get(splitN, 0);
  const decimal = get(splitN, 1, '00');

  return num.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.' + decimal;
}
