import React, { useEffect, useState, useContext } from 'react';
import {
  Modal,
  Form,
  Select,
  DatePicker,
  Input,
  Button,
  Spin,
  Switch,
  TimePicker,
  Upload,
  Icon,
  message,
} from 'antd';
import _, { now } from 'lodash';
import get from 'lodash/get';
import { MainContext } from '../../context/main';
import styled from 'styled-components';
import moment from 'moment';
import QuillEditor from '../../component/QuillRichTextEditor';
const { Option } = Select;

export default Form.create()(
  ({
    visible,
    onSubmit = () => { },
    onCancel = () => { },
    form: { getFieldDecorator, validateFields, resetFields, getFieldValue },
    data,
    formLoading,

  }) => {
    const [isSelectedPdf, setIsSelectedPdf] = useState(false);
    const [Base64PdfUpload, setBase64PdfUpload] = useState(null);

    const handleSubmit = (e) => {
      e.preventDefault();

      validateFields(async (err, fieldsValue) => {
        if (err) {
          return;
        }
        if (isSelectedPdf) {
          fieldsValue.attachment = Base64PdfUpload;
        }
        setPdfRef(null);
        setIsSelectedPdf(false);
        setBase64PdfUpload(null);
        // console.log(fieldsValue);
        onSubmit(_.omit(fieldsValue));
      });
    };

    useEffect(() => {
      if (!visible) {
        resetFields();
      }
      if (get(data, "attachment")) {
        setPdfRef(_.get(data, ['attachment']));
      }
    }, [visible]);

    const getBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    const beforeUpload = (file) => {
      const isPdf = file.type === 'application/pdf';
      if (!isPdf) {
        message.error('รองรับเฉพาะไฟล์ PDF เท่านั้น!');
      }
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        message.error('ขนาดไฟล์ต้องไม่เกิน 20MB!');
      }
      return isPdf && isLt20M;
    };

    const [PdfRef, setPdfRef] = useState(get(data, '[attachment]', null));
    const handleFileRead = async (e) => {
      const file = e.file;
      if (beforeUpload(file)) {
        const fileName = file.name; // Get the file name
        setBase64PdfUpload(await getBase64(file.originFileObj)); // Set the base64 string
        setPdfRef(fileName); // Update the state with the file name
        setIsSelectedPdf(true);
      }
    };

    const setOldPdfRef = (e) => {
      setPdfRef(get(data, '[attachment]', null));
    }
    const handleDownloadPDF = () => {
      const id = get(data, ['id']);
      window.open(`${process.env.REACT_APP_API}/toss/${id}`, '_blank');
    }

    const handleQuillChange = (content) => {
      // Update the form field value with the Quill content
      console.log(content)
      getFieldDecorator('description', { initialValue: content });
    };

    return (

      <CustomModal
        title={data ? 'แก้ไขข้อความของประกาศนโยบายความเป็นส่วนตัว (Privacy Notice)' : 'เพิ่มข้อความของประกาศนโยบายความเป็นส่วนตัว (Privacy Notice)'}
        visible={visible}
        style={{ minWidth: '1100px',top: '20px' }}
        onCancel={onCancel}
        footer={[
          <Button key="back" onClick={onCancel}>
            ยกเลิก
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit}>
            บันทึก
          </Button>,
        ]}
      >

        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>
          <Spin spinning={formLoading}>
            <Form.Item label="ชื่อหัวข้อนโยบาย">
              {getFieldDecorator('title', {
                initialValue: get(data, ['title']),
                rules: [
                  {
                    required: !data,
                    message: 'กรุณากรอกชื่อสื่อออนไลน์',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="ใช้รูปแบบประกาศเป็นไฟล์ PDF">
              {getFieldDecorator('isUseAttachment', {
                valuePropName: 'checked',
                initialValue: get(data, ['isUseAttachment'], false),
              })(
                <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" />
              )}
            </Form.Item>
            {(!getFieldValue('isUseAttachment')) ? (
              // <Form.Item label="กำหนดนโยบาย (เพิ่มข้อมูล)">
              //   {getFieldDecorator('description', {
              //     initialValue: get(data, ['description']),
              //     rules: [
              //       {
              //         required: !data,
              //         message: 'กรุณากรอกคำอธิบาย',
              //       },
              //     ],
              //   })(<Input.TextArea style={{ resize: 'none', height: '120px' }} />)}
              // </Form.Item>
              <Form.Item label="กำหนดนโยบาย (เพิ่มข้อมูล)">
                {getFieldDecorator('description', {
                  initialValue: get(data, ['description']),
                  rules: [
                    {
                      required: true,
                      validator: (rule, value, callback) => {
                        if (!value || value.trim() === '') {
                          callback('กรุณากรอกคำอธิบาย');
                        } else {
                          callback();
                        }
                      },
                    },
                  ],
                })(<QuillEditor style={{ height: '300px', width: '750px', marginBottom: '80px'}} initialValue={get(data, ['description'])} onChange={handleQuillChange} />)}
              </Form.Item>

            ) : (
              <>
                <Form.Item label="ไฟล์ประกาศ PDF">
                  {getFieldDecorator('attachment', {
                    initialValue: get(data, ['attachment']),
                    valuePropName: 'file',
                    rules: [
                      {
                        required: !Base64PdfUpload,
                        message: 'กรุณาเลือกไฟล์',
                      },
                    ],
                  })(
                    <>
                      <Upload
                        action="#"
                        accept=".pdf"
                        listType="picture"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        onChange={e => handleFileRead(e)}
                      >
                        {PdfRef ? (

                          <div>
                            <Button>
                              <Icon type="upload" /> เลือกไฟล์
                            </Button>
                            <br />
                            {PdfRef}

                          </div>
                        ) : (
                          <Button>
                            <Icon type="upload" /> เลือกไฟล์
                          </Button>
                        )}
                      </Upload>
                      <br />
                      {data ? (<Button onClick={handleDownloadPDF} target="_blank"><Icon type="file-pdf" />เปิดดูไฟล์เดิม</Button>) : null}
                    </>
                  )}
                </Form.Item>
                <Form.Item label="ลิงค์ PDF(ถ้ามี)">
                  {getFieldDecorator('pdfLink', {
                    initialValue: get(data, ['pdfLink']),
                  })(<Input />)}
                </Form.Item>
              </>
            )}
            <Form.Item label="สถานะ">
              {getFieldDecorator('isActive', {
                valuePropName: 'checked',
                initialValue: get(data, ['isActive'], true),
              })(
                <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" />
              )}
            </Form.Item>
            <Form.Item label="วันที่เริ่มต้นประกาศ">
              {getFieldDecorator('periodDateStart', {
                initialValue: moment(get(data, ['periodStart'])) || moment(),
                rules: [
                  {
                    required: !data,
                    message: 'กรุณากรอกวันที่เริ่มต้นประกาศ',
                  }
                ],
              })(
                <DatePicker defaultPicker={moment()} />
              )}
              {getFieldDecorator('periodTimeStart', {
                initialValue: moment(get(data, ['periodStart'])) || moment(),
                rules: [
                  {
                    required: !data,
                    message: 'กรุณากรอกเวลาเริ่มต้นประกาศ',
                  }
                ],
              })(
                <TimePicker defaultPicker={moment()} format={'HH:mm'} />
              )}
            </Form.Item>
            <Form.Item label="วันที่สิ้นสุดประกาศ">
              {getFieldDecorator('periodDateEnd', {
                initialValue: moment(get(data, ['periodEnd'])) || moment(),
                rules: [
                  {
                    required: !data,
                    message: 'กรุณากรอกวันที่สิ้นสุดประกาศ',
                  }
                ],
              })(
                <DatePicker />
              )}
              {getFieldDecorator('periodTimeEnd', {
                initialValue: moment(get(data, ['periodEnd'])) || moment(),
                rules: [
                  {
                    required: !data,
                    message: 'กรุณากรอกเวลาสิ้นสุดประกาศ',
                  }
                ],
              })(
                <TimePicker defaultPicker={moment()} format={'HH:mm'} />
              )}
            </Form.Item>
          </Spin>
        </Form>
      </CustomModal >
    );
  },
);

export const CustomModal = styled(Modal)`
  #kiosksIds {
    display: inline-block;
  }
  .ant-checkbox-group-item {
    margin: 0;
    width: 50%;
  }
`;