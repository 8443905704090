import React, { useEffect, useContext, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import get from 'lodash/get';
import map from 'lodash/map';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import toSafeInteger from 'lodash/toSafeInteger';
import moment from 'moment';
import { convertToDatetimeFullBE } from '../../utils';
import styled from 'styled-components';
import {
  Spin,
  Descriptions,
  Button,
  Card,
  Row,
  Col,
  Modal,
  List,
  Popconfirm,
  Drawer,
} from 'antd';
import { numberWithCommas } from '../../utils/form';
import { chan } from '../../utils';

import { MainContext } from '../../context/main';
import { LongLine } from '../../style/global';

import { convertToDatetimeBE } from '../../utils'

const { Meta } = Card;

export default ({ transactionId, setTransactionId }) => {
  const history = useHistory();

  // const { transactionId } = useParams();

  const { request } = useContext(MainContext);

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState([]);

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const handleCancel = () => {
    setPreviewVisible(false);
    // setPreviewImage('');
  };

  const handleOpenPreview = (src) => () => {
    setPreviewImage(src);
    setPreviewVisible(true);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await request.get(`/transactions/${transactionId}`, {
        includes: ['confirmed', 'snap_shots', 'kiosk', 'printlog'],
      });

      const result = get(res, ['result'], {});

      setData(result);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const unlockPrint = (id) => async () => {
    try {
      setLoadingList([...new Set([...loadingList, id])]);
      const res = await request.put('/print_log', {
        id,
      });

      if (get(res, ['success'])) {
        fetchData();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingList(filter(loadingList, (i) => i !== id));
    }
  };

  const renew = () => {
    const res = request.post(`/transactions/repair_transaction/${transactionId}`, {})
      .then((res) => {
        if (get(res, ['success'])) {
          fetchData();
        }
      });
  }
  const recallAddReceiptData = () => {
    const res = request.post(`/transactions/repair_addReceipt/${transactionId}`, {})
      .then((res) => {
        if (get(res, ['success'])) {
          fetchData();
        }
      });
    console.log("Use recallAddReceiptData ")
  }

  const recallAddVehicleTaxCirlcePrint = () => {
    const res = request.post(`/transactions/repair_updatePrintlog/${transactionId}`, {})
      .then((res) => {
        if (get(res, ['success'])) {
          fetchData();
        }
      });
    console.log("Use recallAddVehicleTaxCirlcePrint ")


  }

  const back = () => {
    setTransactionId('');
    setData({});
    // history.push('/transactions');
  };

  useEffect(() => {
    if (transactionId) {
      fetchData();
    }
  }, [transactionId]);

  const remarkColor = (text, o) => {
    if (!text) {
      if (
        moment(moment(get(o, ['created'], null)).format('YYYY-MM-DD')).isBefore(
          moment().format('YYYY-MM-DD'),
        )
      ) {
        return (
          <span style={{ color: 'red' }}>
            ไม่ทำการชำระภายในเวลาที่กำหนด (ภายในวันที่ทำรายการ)
          </span>
        );
      }

      return text;
    }

    if (text.indexOf('Error') !== -1 || text.indexOf('[error]') !== -1) {
      return <span style={{ color: 'red' }}>{text}</span>;
    }

    return <span style={{ color: 'green' }}>{text}</span>;
  };

  return (
    <Drawer
      visible={!!transactionId}
      width="100%"
      getContainer={() => document.getElementById('main-content-layout')}
      // getContainer={false}
      style={{ position: 'absolute' }}
      onClose={back}
    >
      <Spin spinning={loading}>
        <h2>
          ทำรายการวันที่ {convertToDatetimeFullBE(get(data, 'created'))}
        </h2>
        <LongLine />
        <Descriptions
          title="รายละเอียดรถ"
          // layout="vertical"
          layout="horizontal"
        >
          <Descriptions.Item label="ประเภทรถ">
            {get(data, 'vehTypeCodeDesc')}
          </Descriptions.Item>
          <Descriptions.Item label="ทะเบียน" span={1}>
            {`${get(data, 'plate1', '')} ${toSafeInteger(
              get(data, 'plate2', ''),
            )}`}
          </Descriptions.Item>
          <Descriptions.Item label="จังหวัด">
            {get(data, 'offRegDesc')}
          </Descriptions.Item>
          <Descriptions.Item label="ยี่ห้อ">
            {get(data, 'dlt_brnDesc')}
          </Descriptions.Item>
          <Descriptions.Item label="สี">
            {get(data, 'dlt_color')}
          </Descriptions.Item>
          <Descriptions.Item label="เลขประจําตัวประชาชนของเจ้าของรถ">
            {get(data, 'ownIdNo')}
          </Descriptions.Item>
        </Descriptions>
        <LongLine />
        <Descriptions title="ผู้ทำรายการ" layout="horizontal">
          <Descriptions.Item label="ชื่อ">
            {get(data, ['transaction_channel']) !== 'kiosk'
              ? get(data, 'fname')
              : 'ไม่ระบุ'}
          </Descriptions.Item>
          <Descriptions.Item label="นามสกุล">
            {get(data, ['transaction_channel']) !== 'kiosk'
              ? get(data, 'lname')
              : 'ไม่ระบุ'}
          </Descriptions.Item>
          <Descriptions.Item label="เลขประจำตัว">
            {get(data, 'docNo')}
          </Descriptions.Item>
          <Descriptions.Item label="เบอร์โทรศัพท์">
            {get(data, ['transaction_channel']) !== 'kiosk'
              ? get(data, 'phoneNo')
              : 'ไม่ระบุ'}
          </Descriptions.Item>
        </Descriptions>
        <LongLine />
        <Descriptions title="ข้อมูลพรบ." layout="horizontal">
          <Descriptions.Item label="บริษัท">
            {get(data, 'dlt_policyComCode1_string')}
          </Descriptions.Item>
          <Descriptions.Item label="เลขกรมธรรม์">
            {get(data, 'policyNo1')}
          </Descriptions.Item>
          <Descriptions.Item label="วันสิ้นอายุ">
            {get(data, 'newTaxExpireDateThai')}
            {/* {moment(get(data, 'policyExpDate1')).add(543, 'year').format('D MMM YYYY HH:mm:ss')} */}
          </Descriptions.Item>
        </Descriptions>
        <LongLine />
        <Descriptions title="รายละเอียด" layout="horizontal">
          <Descriptions.Item label="หมายเลขอ้างอิง 1">
            {get(data, 'reference_1')}
          </Descriptions.Item>
          <Descriptions.Item label="หมายเลขอ้างอิง 2">
            {get(data, 'reference_2')}
          </Descriptions.Item>
          <Descriptions.Item label="ช่องทางการรับป้ายภาษี">
            {get(data, 'printFlag') ? 'ตู้ชำระภาษี' : 'ส่งไปรษณีย์'}
          </Descriptions.Item>
          <Descriptions.Item label="ค่าชำระภาษี">
            {numberWithCommas(data, 'dlt_taxAmt')} บาท
          </Descriptions.Item>
          <Descriptions.Item label="ค่าไปรษณีย์">
            {numberWithCommas(data, 'dlt_feeAmt')} บาท
            {(get(data, 'printFlag') == false && parseInt(get(data, 'dlt_feeAmt')) === 0) && <PromotionText>&nbsp;(โปรโมชั่น)</PromotionText>}
          </Descriptions.Item>
          <Descriptions.Item label="เงินเพิ่ม">
            {numberWithCommas(data, 'dlt_addAmt')} บาท
          </Descriptions.Item>
          <Descriptions.Item label="รวมราคา">
            {numberWithCommas(data, 'dlt_totalAmt')} บาท
          </Descriptions.Item>
          <Descriptions.Item label="ราคาที่เรียกเก็บ">
            {numberWithCommas(data, 'amount')} บาท
          </Descriptions.Item>
          <Descriptions.Item label="สถานะการชำระเงิน">
            {get(data, 'confirmation_id', '') !== ''
              ? 'ชำระแล้ว'
              : 'ยังไม่ชำระ'}
          </Descriptions.Item>
          <Descriptions.Item label="เวลาทำรายการ">
            {get(data, 'created') &&
              convertToDatetimeBE(get(data, 'created'))}
          </Descriptions.Item>
          <Descriptions.Item label="หมายเหตุ">
            {remarkColor(get(data, 'remark', ''), data)}
          </Descriptions.Item>
          <Descriptions.Item label="เลขคุมเครื่องหมาย">
            {get(data, 'printlog[0].kioskPaper_reference')}
          </Descriptions.Item>
        </Descriptions>
        {
          get(data, 'qr_transction') && <List>
            <List.Item>
              <span>QR Code: <img width="132px" height="132px" src={get(data, 'qr_transction')} /></span>
            </List.Item>
          </List>
        }
        {get(data, 'confirmation_id') && (
          <>
            <LongLine />
            <Descriptions title="รายละเอียดการชำระ" layout="horizontal">
              <Descriptions.Item label="tranID">
                {get(data, ['confirmation_id', 'tranID'])}
              </Descriptions.Item>
              <Descriptions.Item label="วันที่ชำระ">
                {get(data, ['confirmation_id', 'created']) &&
                  convertToDatetimeBE(get(data, ['confirmation_id', 'created']))
                }
              </Descriptions.Item>
              <Descriptions.Item label="ช่องทางการชำระ">
                {get(chan, [
                  get(data, ['confirmation_id', 'channel'], 'ไม่ระบุ'),
                ])}
              </Descriptions.Item>
              <Descriptions.Item label="reference1">
                {get(data, ['confirmation_id', 'reference1'])}
              </Descriptions.Item>
              <Descriptions.Item label="reference2">
                {get(data, ['confirmation_id', 'reference2'])}
              </Descriptions.Item>
              <Descriptions.Item label="ยอดชำระ">
                {get(data, ['confirmation_id', 'amount'])}
              </Descriptions.Item>
              <Descriptions.Item label="หมายเหตุ">
                {remarkColor(
                  get(data, ['confirmation_id', 'remark'], ''),
                  data,
                )}
              </Descriptions.Item>
            </Descriptions>
          </>
        )}
        {!get(data, 'printFlag') && (
          <>
            <LongLine />
            <Descriptions title="ที่อยู่จัดส่ง" layout="horizontal">
              <Descriptions.Item label="ชื่อ นามสกุล">
                {get(data, 'sendName')}
              </Descriptions.Item>
              <Descriptions.Item label="บ้านเลขที่">
                {get(data, 'sendAddrNo')}
              </Descriptions.Item>
              <Descriptions.Item label="ชื่ออาคาร/ตึก">
                {get(data, 'sendBldName')}
              </Descriptions.Item>
              <Descriptions.Item label="หมู่ที่">
                {get(data, 'sendVillageNo')}
              </Descriptions.Item>
              <Descriptions.Item label="หมู่บ้าน">
                {get(data, 'sendVillageName')}
              </Descriptions.Item>
              <Descriptions.Item label="ซอย">
                {get(data, 'sendSoi')}
              </Descriptions.Item>
              <Descriptions.Item label="ถนน">
                {get(data, 'sendStreet')}
              </Descriptions.Item>
              <Descriptions.Item label="ตำบล">
                {get(data, 'sendDistrict')}
              </Descriptions.Item>
              <Descriptions.Item label="อำเภอ">
                {get(data, 'sendAmphur')}
              </Descriptions.Item>
              <Descriptions.Item label="จังหวัด">
                {get(data, 'sendProvince')}
              </Descriptions.Item>
              <Descriptions.Item label="รหัสไปรษณีย์">
                {get(data, 'sendZipCode')}
              </Descriptions.Item>
              <Descriptions.Item label="เบอร์โทรศัพท์">
                {get(data, 'sendPhoneNo')}
              </Descriptions.Item>
              <Descriptions.Item label="สถานะการจัดส่งไปรษณีย์">
                {get(data, 'dlt_post_process_flag')}
              </Descriptions.Item>
              <Descriptions.Item label="หมายเลขพัสดุ">
                {get(data, 'dlt_post_tracking_no')}
              </Descriptions.Item>
            </Descriptions>
          </>
        )}
        {get(data, 'kiosk_id') && (
          <>
            <LongLine />
            <Descriptions title="รายละเอียดตู้ที่ทำรายการ" layout="horizontal">
              <Descriptions.Item label="หมายเลขตู้">
                {get(data, ['kiosk_id', 'serial_number'])}
              </Descriptions.Item>
              <Descriptions.Item label="รายละเอียดตู้">
                {get(data, ['kiosk_id', 'detail'])}
              </Descriptions.Item>
              <Descriptions.Item label="ประเภทการทำรายการ">
                {` ${get(data, ['transaction_channel']) === 'kiosk'
                  ? 'ชำระค่าภาษี'
                  : 'พิมพ์เครื่องหมาย'
                  }`}
              </Descriptions.Item>
            </Descriptions>
          </>
        )}
        {get(data, ['printlog', 'length']) ? (
          <>
            <LongLine />
            <Descriptions title="ข้อมูลการพิมพ์" layout="horizontal" />
            <List
              itemLayout="horizontal"
              dataSource={get(data, ['printlog'], [])}
              renderItem={(item, i) => {
                let status = 'ไม่สำเร็จ';
                let color = 'red';

                if (item.status === 'success') {
                  status = 'สำเร็จ';
                  color = 'green';
                }
                if (item.status === 'on_process') {
                  status = 'กำลังพิมพ์';
                  color = 'orange';
                }

                let actionBtn = [];

                if (item.status === 'on_process') {
                  actionBtn = [
                    <Popconfirm
                      key="list-more"
                      placement="leftBottom"
                      title="การยกเลิกสถานะการพิมพ์จะทำให้สามารถทำการพิมเครื่องหมายได้อีกครั้ง"
                      onConfirm={unlockPrint(get(item, ['_id']))}
                      okText="ตกลง"
                      cancelText="ยกเลิก"
                    >
                      <Button
                        loading={includes(loadingList, get(item, ['_id']))}
                        type="danger"
                      >
                        ยกเลิกสถานะการพิมพ์
                      </Button>
                    </Popconfirm>,
                  ];
                }

                return (
                  <List.Item actions={actionBtn} key={i}>
                    <List.Item.Meta
                      title={
                        <span>
                          {`ตู้หมายเลข : ${get(item, [
                            'kiosk_id',
                            'serial_number',
                          ])} | สถานะ : `}
                          <span style={{ color }}>{status}</span>{' '}
                        </span>
                      }
                      description={item.remark}
                    />
                  </List.Item>
                );
              }}
            />
          </>
        ) : null}
        {get(data, ['snap_shots', 'length']) ? (
          <>
            <LongLine />
            <Descriptions title="ภาพถ่ายขณะทำรายการ" layout="horizontal" />
            <Row gutter={[16, 16]}>
              {map(get(data, ['snap_shots']), (o, i) => (
                <Col key={i} span={6}>
                  <Card
                    onClick={handleOpenPreview(
                      `${process.env.REACT_APP_SOCKET_URL}${get(o, ['path'])}`,
                    )}
                    hoverable
                    style={{ width: 240 }}
                    cover={
                      <img
                        alt={get(o, ['description'])}
                        src={`${process.env.REACT_APP_SOCKET_URL}${get(o, [
                          'path',
                        ])}`}
                      />
                    }
                  >
                    <Meta description={get(o, ['description'])} />
                  </Card>
                </Col>
              ))}
            </Row>
          </>
        ) : null}
        <LongLine />
        <Row justify="space-between">
          <Col sm={12}>
            <Button type="danger" ghost onClick={back}>
              ย้อนกลับ
            </Button>
          </Col>
          <Col sm={12} style={{ textAlign: "right" }}>
            <Button style={{ margin: "0px 2px" }} type="primary" onClick={renew}>
              ทำรายการซ้ำ
            </Button>
            <Button style={{ margin: "0px 2px" }} type="primary" onClick={recallAddReceiptData}>
              เพิ่มข้อมูลใบเสร็จ
            </Button>
            <Button style={{ margin: "0px 2px" }} type="primary" onClick={recallAddVehicleTaxCirlcePrint}>
              เพิ่มข้อมูลการพิมพ์ป้าย
            </Button>
          </Col>
        </Row>
      </Spin>
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </Drawer>
  );
};

const TextH3 = styled.span`
  font-size: 1.17em;
`;
const TextH5 = styled.span`
  font-size: 0.83em;
`;

const PromotionText = styled.span`
  color: red;
`;