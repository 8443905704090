import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { convertToDatetimeBE } from '../../utils'
import { Table, Tag, Button, Icon, Divider, Popconfirm, Popover, Spin } from 'antd';
import { TagContainer, ButtonTag } from './style';
import _, { set } from 'lodash';
import { MainContext } from '../../context/main';
import ExportExcel from '../../component/ExportExcel';
import DrawerExport from '../../component/DrawerExport';
import {
  getFilter,
  getSort,
  getPage,
  getColumnSearchProps,
  perPage,
  defaultPageSize,
  pageSizeOptions,
  showSizeChanger,

} from '../../utils/table';
import { formatPhoneNumber } from '../../utils';
import { HeaderBlock } from '../../style/global';
import FilterModal from '../../component/FilterModal';
import FormModal from './form';
import vehicleTypeData from '../../utils/staticData/vehicleTypeData';
import provinceData from '../../utils/staticData/provinceData';
const searchKey = [
  // { label: 'ชื่อรายการ', key: 'title' },
  // {
  //   label: 'สถานะ',
  //   key: 'isActive',
  //   type: 'select',
  //   options: [
  //     { title: 'เปิดใช้งาน', value: 'true' },
  //     { title: 'ปิดใช้งาน', value: 'false' },
  //   ],
  // },
  { label: 'ชื่อ-นามสกุลผู้เข้าถึงคู่มือ', key: 'userDetail' },
  {
    label: 'สถานะผู้เข้าถึง', key: 'accessed_type', type: 'select',
    options: [
      { title: "ทั้งหมด", value: "" },
      { title: "เจ้าของรถ", value: "owner" },
      { title: "ผู้ที่ได้รับสิทธิ์", value: "delegated" },
      { title: "เจ้าหน้าที่", value: "officer" },
    ]
  },
  { label: 'เลขทะเบียน', key: 'plate', placeholder: 'ตัวอย่าง เช่น กก 1111, 80 1234' },
  {
    label: 'จังหวัดจดทะเบียน', key: 'offlocdesc', type: 'select2',
    options: [
      { title: "ทั้งหมด", value: "" },
      ...provinceData.map((o) => ({ title: o.province, value: o.province }))
    ]
  },
  {
    label: 'ประเภทพรบ.', key: 'plateType', type: 'select2',
    options: [
      { title: "ทั้งหมด", value: "" },
      { title: "รถยนต์", value: "1" },
      { title: "รถขนส่ง", value: "2" },
    ]
  },
  {
    label: 'ประเภทรถ', key: 'vehTypeCode', type: 'select2',
    options: [
      { title: "ทั้งหมด", value: "" },
      ...vehicleTypeData.map((o) => ({ title: o.name, value: o.id }))
    ]
  },
  {
    label: 'ช่วงวันที่ดำเนินการ',
    key: 'date-range',
    type: 'rangepicker',
  },
];

const isAllow = (menuPermissions, targetmenuPermissions) => {
  const thisMenuId = "21"
  const permission = _.find(menuPermissions, { menuId: thisMenuId });
  return permission && permission.canManage;
};

function getColor(status) {
  switch (status) {
    case true:
      return 'green';
    case false:
      return 'orange';
    default:
      return '';
  }
}

function getWord(status) {
  switch (status) {
    case true:
      return 'เปิดใช้งาน';
    case false:
      return 'ปิดใช้งาน';
    default:
      return 'ไม่พร้อมใช้งาน';
  }
}
const PopOverTag = ({ carmanual, onStatusChange }) => {

  const currentStatus = _.get(carmanual, ['carDelegated', 'isActived']);
  const color = getColor(currentStatus);
  const [visible, setVisible] = useState();
  const [isLoading, setIsLoading] = useState(false);
  console.log(currentStatus);
  const { request } = useContext(MainContext);

  const onVisibleChange = (e) => {
    setVisible((prevState) => !prevState);
  };

  const handleSelect = (newStatus) => async (e) => {
    if (newStatus === currentStatus) return;

    const body = {
      id2: carmanual.carDelegated._id,
      isActive: newStatus,
    };
    setIsLoading(true);

    const res = await request.put(`/carmanual/${carmanual._id}/isActive`, body);

    setIsLoading(false);

    if (_.get(res, ['success'])) {
      console.log("res", res);
      onStatusChange({
        id: carmanual._id,
        id2: carmanual.carDelegated._id,
        newStatus,
      });
    }

    onVisibleChange();
  };

  return (
    <Popover
      content={(
        <Spin spinning={isLoading}>
          <TagContainer>
            <ButtonTag underline onClick={handleSelect(true)}>
              <Tag color={currentStatus === true ? 'grey' : 'green'}>
                {getWord(true)}
              </Tag>
            </ButtonTag>
            <ButtonTag underline onClick={handleSelect(false)}>
              <Tag color={currentStatus === false ? 'grey' : 'orange'}>
                {getWord(false)}
              </Tag>
            </ButtonTag>
          </TagContainer>
        </Spin>
      )}
      title="สถานะการเปิดใช้งาน"
      trigger="click"
      visible={visible}
      onVisibleChange={onVisibleChange}
    >
      <ButtonTag>
        <Tag color={color}>
          {' '}
          {getWord(currentStatus)}
        </Tag>
      </ButtonTag>
    </Popover>
  );
};
export default () => {
  const { request, userProfile } = useContext(MainContext);
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState({});
  const [sorter, setSorter] = useState({
    sort: { key: 'created', desc: true },
  });
  const [pagination, setPagination] = useState({
    // pageSize: perPage,
    defaultPageSize: defaultPageSize,
    pageSizeOptions: pageSizeOptions,
    showSizeChanger: showSizeChanger,
  });
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [targetcarmanual, setTargetcarmanual] = useState(null);

  //export EXCEL
  const [visibleExportExcelModal, setVisibleExportExcelModal] = useState(false);
  const toggleExportExcelModal = () => {
    setVisibleExportExcelModal(!visibleExportExcelModal);
  };



  const fetchData = async (params = {}) => {
    try {
      setLoading(true);
      const res = await request.get('/carmanuallogs', {
        ...params,
        ...searchData,
      });

      const totalData = _.get(res, ['total_data'], 0);

      setData(_.get(res, ['results'], []));
      setPagination({ ...pagination, total: totalData, current: params.page, pageSize: params.per_page });
      console.log('res', res);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (carmanualId, carDelegatedId) => async () => {
    try {
      setLoading(true);
      const res = await request.delete(`/carmanual/${carmanualId}/${carDelegatedId}`);

      if (_.get(res, ['success'])) {
        fetchData();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (paginationChange, filters, sorterChange) => {
    const pager = { ...pagination };
    pager.current = paginationChange.current;

    setPagination(pager);
    setSorter(getSort(sorterChange));

    fetchData({
      ...getFilter(filters),
      ...getSort(sorterChange),
      ...getPage(paginationChange),
    });
  };

  useEffect(() => {
    fetchData({
      ...getPage(pagination),
      ...sorter,
    });
  }, [searchData]);

  const handleSearch = (value) => {
    pagination.current = 1;
    setSearchData({ ..._.omitBy(value, (o) => _.isNil(o) || _.isEmpty(o)) });

    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOpenModal = () => {
    setVisible(true);
  };

  const handleOpenModalForm = (carmanual) => (e) => {
    setTargetcarmanual(carmanual);
    setFormVisible(true);
    // console.log(carmanual);
  };

  const handleCloseModalForm = () => {
    setTargetcarmanual(null);
    setFormVisible(false);
  };
  const onStatusChange = ({ newStatus, id, id2 }) => {
    setData(
      _.map(data, (o) => {
        if (o._id === id && o.carDelegated._id === id2) o.carDelegated.isActived = newStatus;
        return o;
      }),
    );
  };

  const [isExportExcelModalOpen, setExportExcelModalOpen] = useState(false);
  const toggleExportSubMenu = () => {
    setExportExcelModalOpen(!isExportExcelModalOpen);
  };

  const [drawerExportVisible, setDrawerExportVisible] = useState(false);
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [drawerExportTitle, setDrawerExportTitle] = useState('');
  const [exportType, setExportType] = useState('');
  const handleOpenDrawerExport = (type) => {
    if (type === 'access') {
      setExportType('access')
      setDrawerExportTitle("รายงานสรุปการเข้าถึงใบคู่มือฯ")
    } else {
      setExportType('count')
      setDrawerExportTitle("รายงานสรุปจำนวนครั้งที่มีผู้เข้าถึงใบคู่มือฯ")
    }
    setDrawerExportVisible(true);
  };
  const handleCloseDrawerExport = () => {
    setDrawerExportVisible(false);
  };

  const handleExcelSummaryDownload = async (
    filename = 'report.xlsx',
    params,
  ) => {
    setSummaryLoading(true);
    if (exportType === 'access') {
      filename = "รายงานสรุปการเข้าถึงใบคู่มือฯ.xlsx"
    } else {
      filename = "รายงานสรุปจำนวนครั้งที่มีผู้เข้าถึงใบคู่มือฯ.xlsx"
    }
    await request.download('/transactions/report', filename, { ...params, exportType });
    setSummaryLoading(false);
  };

  const columns = [
    {
      title: 'ลำดับ',
      key: 'index',
      dataIndex: 'index',
    },
    {
      title: 'ชื่อ-นามสกุล ผู้เข้าถึงใบคู่มือฯ',
      key: 'accessed_name',
      render: (record) => `${record.accessed_name}`,
    },
    {
      title: 'ทะเบียนรถ',
      key: '',
      render: (record) => `${record.plate1}${record.typecar !== '' ? ' ' : '-'}${record.plate2} ${record.offlocdesc} ${record.typecar !== '' ? ('รย.' + parseInt(record.typecar, 10)) : ''}`,
    },

    {
      title: 'สถานะผู้เข้าถึง',
      key: '',
      dataIndex: 'accessed_type',
      // render: (v, o) => v || o.carDelegated.docNo,
      render: (v, o) => {
        if (v === "owner") {
          return "เจ้าของรถ"
        } else if (v === "delegated") {
          return "ผู้ที่ได้รับสิทธิ์"
        } else if (v === "officer") {
          return "เจ้าหน้าที่"
        } else {
          return "ไม่พบข้อมูล"
        }
      },
    },
    {
      title: 'วันเวลาเข้าถึง',
      key: 'created',
      dataIndex: 'created',
      sorter: () => { },
      render: (v) => convertToDatetimeBE(v),
    },
  ];

  const onSubmit = async ({
    title,
    AlertMessage,
    isActive,
    periodDateStart,
    periodTimeStart,
    periodDateEnd,
    periodTimeEnd,
  }) => {
    setFormLoading(true);
    let body = {};
    let res;
    try {
      if (targetcarmanual) {
        body.carmanualId = _.get(targetcarmanual, ['id']);
        if (targetcarmanual.title !== title)
          body.title = title;
        if (targetcarmanual.AlertMessage !== AlertMessage)
          body.AlertMessage = AlertMessage;
        if (targetcarmanual.isActive !== isActive)
          body.isActive = isActive;
        body.periodDateStart = periodDateStart;
        body.periodTimeStart = periodTimeStart;
        body.periodDateEnd = periodDateEnd;
        body.periodTimeEnd = periodTimeEnd;
        res = await request.put('/carmanual', body);
      } else {
        body = {
          title,
          AlertMessage,
          isActive,
          periodDateStart,
          periodTimeStart,
          periodDateEnd,
          periodTimeEnd,
        };
        res = await request.post('/carmanual', body);
      }

      if (_.get(res, ['success'])) {
        handleCloseModalForm();
        fetchData({
          ...getPage(pagination),
          ...sorter,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setFormLoading(false);
    }
  };

  const handleDownload = async (fileName = 'รายงานการเข้าถึงใบคู่มือฯ.xlsx') => {
    if (loading) return;
    try {
      setLoading(true);
      fileName = 'รายงานการเข้าถึงใบคู่มือฯ.xlsx';
      await request.download('/carmanuallogs/report', fileName, {
        ...getPage(pagination),
        ...sorter,
        ...searchData
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadSummary = async (fileName = 'รายงานสรุปการเข้าถึงใบคู่มือฯ.xlsx') => {
    if (loading) return;
    try {
      setLoading(true);
      fileName = 'รายงานสรุปการเข้าถึงใบคู่มือฯ.xlsx';
      await request.download('/carmanuallogs/reportSummary', fileName, {
        ...getPage(pagination),
        ...sorter,
        ...searchData
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <>
      <HeaderBlock style={{ fontSize: '20px', fontWeight: 'bold' }}>
        สอบถามประวัติ(Log) เข้าถึงใบคู่มือจดทะเบียนรถรูปแบบอิเล็กทรอนิกส์
      </HeaderBlock>
      <HeaderBlock>
        <Button type="primary" onClick={handleOpenModal}>
          ค้นหา
        </Button>
        {isAllow(userProfile.menuPermissions) && (
          <div style={{ position: 'relative', display: 'inline-block' }}>
            <Button
              style={{ margin: '0 0 0 16px', width: '270px' }}
              icon="export"
              onClick={handleDownload}
            >
              Export
            </Button>
            <Button
              style={{ margin: '0 0 0 16px', width: '270px' }}
              icon="export"
              onClick={handleDownloadSummary}
            >
              รายงานสรุป
            </Button>
          </div>
          // <div style={{ position: 'relative', display: 'inline-block' }}>
          //   <Button
          //     style={{ margin: '0 0 0 16px', width: '270px' }}
          //     icon="export"
          //     onClick={toggleExportSubMenu}
          //   >
          //     รายงานสรุป
          //   </Button>

          //   {isExportExcelModalOpen && (
          //     <div style={{ position: 'absolute', zIndex: 1, top: '100%', left: 0 }}>
          //       <Button
          //         style={{ margin: '0 0 0 16px', width: '270px' }}
          //         onClick={handleDownload}
          //       >
          //         รายงานการเข้าถึงใบคู่มือฯ
          //       </Button>
          //       <Button
          //         style={{ margin: '0 0 0 16px', width: '270px' }}
          //         onClick={() => handleOpenDrawerExport("count")}
          //       >
          //         รายงานสรุปจำนวนครั้งที่มีผู้เข้าถึงใบคู่มือฯ
          //       </Button>
          //     </div>
          //   )}
          // </div>
        )}
      </HeaderBlock>

      <DrawerExport
        visible={drawerExportVisible}
        onClose={handleCloseDrawerExport}
        handleExcelSummaryDownload={handleExcelSummaryDownload}
        confirmLoading={summaryLoading}
        title={drawerExportTitle}
      />
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 'max-content' }}
      />
      <FilterModal
        visible={visible}
        handleSearch={handleSearch}
        handleCancel={handleCancel}
        renderInputFields={searchKey}
      />
      <FormModal
        onSubmit={onSubmit}
        formLoading={formLoading}
        data={targetcarmanual}
        visible={formVisible}
        onCancel={handleCloseModalForm}
      ></FormModal>
    </>
  );
};
