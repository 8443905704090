import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill's CSS
import { Button, Icon } from 'antd';


const fontSizeArr = ['8px', '9px', '10px', '12px', '14px', '16px', '20px', '24px', '32px', '42px', '54px', '68px', '84px'];
const Size = ReactQuill.Quill.import('attributors/style/size');
Size.whitelist = fontSizeArr;
ReactQuill.Quill.register(Size, true);

class QuillEditor extends Component {
    constructor(props) {
        super(props);
        this.state = { text: this.props.initialValue || '' }; // Initialize with the provided initialValue or an empty string
    }

    componentDidUpdate(prevProps) {
        if (this.props.initialValue !== prevProps.initialValue) {
            // Update the component's state when initialValue changes
            this.setState({ text: this.props.initialValue || '' });
        }
    }

    handleChange = (value) => {
        this.setState({ text: value });

        // Add a callback to propagate the content back to the parent component
        this.props.onChange(value);
    };

    handleSave = () => {
        // Save the value of the editor to Local Storage.
        // localStorage.setItem('content', this.state.text);
    }

    handlePreview = () => {
        // Open a new window or tab with the preview page
        const previewWindow = window.open('', '_blank');

        // Render the preview content in the new window or tab
        if (previewWindow) {
            previewWindow.document.open();
            previewWindow.document.write(`
                <html>
                <head>
                <title>Preview</title>
                <style>
                @font-face {
                    font-family: 'DBHeaventRoundedR';
                    src: url("${process.env.REACT_APP_SOCKET_URL}/static/assets/font/DBHeaventRounded.ttf") format('truetype');
                    /* You can also include additional font formats (e.g., woff, woff2) for better browser compatibility */
                  }
                    body {
                        font-family: 'DBHeaventRoundedR', sans-serif;
                    }
                </style>
                
                </head>
                <body>
                <h1>Preview</h1>
                <div>${this.state.text}</div>
                </body>
                </html>
            `);
            previewWindow.document.close();
        }
    };


    render() {
        const customtoolbar = [
            [{ 'size': fontSizeArr }],
            [{ 'header': '1' }, { 'header': '2' }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
            ['link', 'image'], // Include the 'image' button
        ];


        const formats = [
            'size',
            'header',
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            'list',
            'align',
            'bullet',
            'link',
            'image',
        ];

        const { style } = this.props;
        return (
            <>
                <ReactQuill
                    theme="snow"
                    value={this.state.text}
                    onChange={this.handleChange}
                    style={style ? style : { height: '300px', marginBottom: '80px' }}
                    modules={{ toolbar: customtoolbar }}

                />
                <Button onClick={this.handlePreview}><Icon type="search" />Preview</Button>
            </>
        );
    }
}
// override default css 

export default QuillEditor;
