import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { convertToDatetimeBE, convertToDateBE } from '../../utils';
import { Table, Tag, Button } from 'antd';

import _ from 'lodash';
import { MainContext } from '../../context/main';

import {
  getFilter,
  getSort,
  getPage,
  getColumnSearchProps,
  perPage,
  defaultPageSize,
  pageSizeOptions,
  showSizeChanger,
} from '../../utils/table';
import { formatPhoneNumber } from '../../utils';
import { HeaderBlock } from '../../style/global';
import FilterModal from '../../component/FilterModal';
import vehicleTypeData from '../../utils/staticData/vehicleTypeData';
import provinceData from '../../utils/staticData/provinceData';

const searchKey = [
  { label: 'ชื่อ-นามสกุลผู้ขอ', key: 'userDetail' },
  { label: 'เลขทะเบียน', key: 'plate', placeholder: 'ตัวอย่าง เช่น กก 1111, 80 1234' },
  {
    label: 'จังหวัดจดทะเบียน', key: 'offlocdesc', type: 'select2',
    options: [
      { title: "ทั้งหมด", value: "" },
      ...provinceData.map((o) => ({ title: o.province, value: o.province }))
    ]
  },
  {
    label: 'ประเภทพรบ.', key: 'plateType', type: 'select2',
    options: [
      { title: "ทั้งหมด", value: "" },
      { title: "รถยนต์", value: "1" },
      { title: "รถขนส่ง", value: "2" },
    ]
  },
  {
    label: 'ประเภทรถ', key: 'vehTypeCode', type: 'select2',
    options: [
      { title: "ทั้งหมด", value: "" },
      ...vehicleTypeData.map((o) => ({ title: o.name, value: o.id }))
    ]
  },
  {
    label: 'ดำเนินการ', key: 'transaction_status', type: 'select',
    options: [
      { title: "ทั้งหมด", value: "" },
      { title: "ยื่นคำขอ", value: "request" },
      { title: "ชำระเงิน", value: "payment" },
      { title: "อนุมัติโดยเจ้าหน้าที่", value: "approval" },
      { title: "อนุมัติโดยระบบ", value: "autoapprove" },
      { title: "ดาวน์โหลดเอกสาร", value: "download" },
    ]
  },
  {
    label: 'ช่วงวันที่ดำเนินการ',
    key: 'date-range',
    type: 'rangepicker',
  },
];

const columns = [
  {
    title: 'ลำดับ',
    key: 'index',
    dataIndex: 'index',
  },
  {
    title: 'ชื่อ-นามสกุลผู้ขอ',
    key: 'userDetail',
    dataIndex: 'userDetail',
    render: (userDetail) => `${userDetail.first_name} ${userDetail.last_name}`,
  },
  {
    title: 'ทะเบียนรถ',
    key: 'plate1',
    dataIndex: 'plate1',
    render: (plate1, record) => {
      let typecarText = "";
      if (record.typecar !== undefined && record.typecar !== null && record.typecar !== "") {
        typecarText = ` รย.${parseInt(record.typecar)}`;
      }
      return `${plate1}${typecarText == "" ? "-" : " "}${record.plate2} ${record.offlocdesc}${typecarText}`;
    },
  },
  {
    title: 'ดำเนินการ',
    key: 'transaction_status',
    dataIndex: 'transaction_status',
    render: (transaction_status) => {
      let txt = '';
      switch (transaction_status) {
        case 'request':
          txt = 'ยื่นคำขอ';
          break;
        case 'approval':
          txt = 'อนุมัติโดยเจ้าหน้าที่';
          break;
        case 'payment':
          txt = 'ชำระเงิน';
          break;
        case 'download':
          txt = 'ดาวน์โหลดเอกสาร';
          break;
        case 'autoapprove':
          txt = 'อนุมัติโดยระบบ';
          break;
        default:
          txt = 'ยื่นคำขอ';
          break;
      }
      return txt;
    }
  },
  {
    title: 'รายละเอียดข้อผิดพลาด/บกพร่อง(ถ้ามี)',
    key: 'errormessage',
    dataIndex: 'errormessage',
  },
  {
    title: 'วันเวลา',
    key: 'created',
    dataIndex: 'created',
    sorter: () => { },
    defaultSortOrder: 'descend',
    render: (v) => convertToDatetimeBE(v),
  },
];

const isAllow = (menuPermissions, targetmenuPermissions) => {
  const thisMenuId = "19"
  const permission = _.find(menuPermissions, { menuId: thisMenuId });
  return permission && permission.canManage;
};


export default () => {
  const { request, userProfile } = useContext(MainContext);
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState({});
  const [sorter, setSorter] = useState({
    sort: { key: 'created', desc: true },
  });
  const [pagination, setPagination] = useState({
    // pageSize: perPage,
    defaultPageSize,
    pageSizeOptions,
    showSizeChanger,
  });
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const fetchData = async (params = {}) => {
    try {
      setLoading(true);
      const res = await request.get('/transactionlogs/ownercert', {
        ...params,
        ...searchData,
      });

      const totalData = _.get(res, ['total_data'], 0);
      // classify data Only have plate1 remain 
      const calleddata = _.filter(_.get(res, ['results'], []), (o) => o.plate1);
      // const totalData = calleddata.length;
      const dataWithIndex = _.map(calleddata, (o, index) => ({ ...o, index: index + 1 }));
      setData(dataWithIndex);
      setPagination({ ...pagination, total: totalData, current: params.page, pageSize: params.per_page });
      // setData(_.get(res, ['results'], []));
      // setPagination({ ...pagination, total: totalData, current: params.page, pageSize: params.per_page });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (paginationChange, filters, sorterChange) => {
    const pager = { ...pagination };
    pager.current = paginationChange.current;

    setPagination(pager);
    setSorter(getSort(sorterChange));

    fetchData({
      ...getFilter(filters),
      ...getSort(sorterChange),
      ...getPage(paginationChange),
    });
  };

  useEffect(() => {
    fetchData({
      ...getPage(pagination),
      ...sorter,
    });
  }, [searchData]);

  const handleSearch = (value) => {
    pagination.current = 1;
    setSearchData({ ..._.omitBy(value, (o) => _.isNil(o) || _.isEmpty(o)) });

    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOpenModal = () => {
    setVisible(true);
  };

  const handleDownload = async (fileName = 'รายงานประวัติการขอใบรับรอง.xlsx') => {
    if (loading) return;
    try {
      setLoading(true);
      fileName = 'รายงานประวัติการขอใบรับรอง.xlsx';
      await request.download('/transactionlogs/reportownercert', fileName, {
        ...getPage(pagination),
        ...sorter,
        ...searchData
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <HeaderBlock style={{ fontSize: '20px', fontWeight: 'bold' }}>
        สอบถามประวัติ (Log) การขอใบรับรองการจดทะเบียนรูปแบบอิเล็กทรอนิกส์
      </HeaderBlock>
      <HeaderBlock>
        <Button type="primary" onClick={handleOpenModal}>
          ค้นหา
        </Button>
        {isAllow(userProfile.menuPermissions) && (
          <div>
            <Button
              style={{ margin: '0 0 0 16px' }}
              icon="export"
              onClick={handleDownload}
            >
              Export
            </Button>
          </div>
        )}
      </HeaderBlock>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 'max-content' }}
      />
      <FilterModal
        visible={visible}
        handleSearch={handleSearch}
        handleCancel={handleCancel}
        renderInputFields={searchKey}
      />
    </>
  );
};
