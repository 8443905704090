export default [
  {
    id: '01',
    name: 'รถยนต์นั่งส่วนบุคคลไม่เกิน 7 คน (รย.1)',
  },
  {
    id: '02',
    name: 'รถยนต์นั่ง ส่วนบุคคลเกิน 7 คน (รย.2)',
  },
  {
    id: '03',
    name: 'รถยนต์บรรทุกส่วนบุคคล (รย.3)',
  },
  {
    id: '04',
    name: 'รถยนต์สามล้อส่วนบุคคล (รย.4)',
  },
  {
    id: '05',
    name: 'รถยนต์รับจ้างระหว่างจังหวัด (รย.5)',
  },
  {
    id: '06',
    name: 'รถยนต์รับจ้างบรรทุกคนโดยสารไม่เกิน 7 คน (รย.6)',
  },
  {
    id: '07',
    name: 'รถยนต์สี่ล้อเล็กรับจ้าง (รย.7)',
  },
  {
    id: '08',
    name: 'รถยนต์รับจ้างสามล้อ (รย.8)',
  },
  {
    id: '09',
    name: 'รถยนต์บริการธุรกิจ (รย.9)',
  },
  {
    id: '10',
    name: 'รถยนต์บริการทัศนาจร (รย.10)',
  },
  {
    id: '11',
    name: 'รถยนต์บริการให้เช่า (รย.11)',
  },
  {
    id: '12',
    name: 'รถจักรยานยนต์ส่วนบุคคล (รย.12)',
  },
  {
    id: '13',
    name: 'รถแทรกเตอร์ (รย.13)',
  },
  {
    id: '14',
    name: 'รถบดถนน (รย.14)',
  },
  {
    id: '15',
    name: 'รถใช้งานเกษตรกรรม (รย.15)',
  },
  {
    id: '16',
    name: 'รถพ่วง (รย.16)',
  },
  {
    id: '17',
    name: 'รถจักรยานยนต์สาธารณะ (รย.17)',
  },
];
