// Import the necessary dependencies
import React, { useEffect, useState, useContext } from 'react';
import {
  Modal,
  Form,
  Select,
  DatePicker,
  Input,
  Button,
  Spin,
  Switch,
  TimePicker,
  Table,
  Typography,
} from 'antd';
import _, { now } from 'lodash';
import get from 'lodash/get';
import { MainContext } from '../../context/main';
import styled from 'styled-components';
import moment from 'moment';
import Grid from 'antd/lib/card/Grid';
import customSequence from '../../utils/staticData/menuSequence';

const { Text } = Typography;
const { Option } = Select;
export default Form.create()(
  ({
    visible,
    onSubmit = () => { },
    onCancel = () => { },
    form: { getFieldDecorator, validateFields, resetFields, getFieldValue, setFieldsValue },
    data,
    formLoading,
  }) => {
    const handleSubmit = (e) => {
      e.preventDefault();

      validateFields(async (err, fieldsValue) => {
        if (err) {
          return;
        }
        // console.log('fieldsValue', fieldsValue);
        onSubmit(_.omit(fieldsValue));
      });
    };

    useEffect(() => {
      if (!visible) {
        resetFields();
      }
      let aa = [];
      for (let i = 0; i < 21; i++) {
        aa.push({
          menuId: customSequence[i],
          canView: false,
          canManage: false,
        })
      }
      setCreatingmenuPermission(aa);
    }, [visible]);

    // Define the columns for the permission table
    const [creatingmenuPermission, setCreatingmenuPermission] = useState([]);

    const permissionColumns = [
      {
        title: 'รายการ',
        dataIndex: 'menuId',
        key: 'menuId',
        render: (menuId, record) => {
          if (record.menuId === '2') {
            return null;
          }
          let text = '';
          let menuDesc = '';
          switch (menuId) {
            case '1':
              text = 'รายการชำระภาษี';
              menuDesc = 'สอบถามประวัติชำระภาษีรถประจำปีผ่าน DLT Vehicle Tax Plus และตู้ชำระภาษีประจำปี';
              break;
            case '2':
              text = 'การบริหารจัดการใบคู่มือ';
              menuDesc = 'สอบถามประวัติเข้าถึงใบคู่มือจดทะเบียนรถรูปแบบอิเล็กทรอนิกส์';
              break;
            case '3':
              text = 'การบริหารจัดการใบรับรอง';
              menuDesc = 'บันทึกพิจารณารายการคำขอใบรับรองจดทะเบียนรถรูปแบบอิเล็กทรอนิกส์';
              break;
            case '4':
              text = 'ตู้ชำระภาษีรถ';
              menuDesc = 'บันทึกจัดการตู้ชำระภาษีรถ (Kiosk)';
              break;
            case '5':
              text = 'จัดการแจ้งปัญหา';
              menuDesc = 'บันทึกตอบปัญหาการใช้งานระบบ DLT Vehicle Tax Plus';
              break;
            case '6':
              text = 'จัดการแอพ DLT Vehicle Tax Plus';
              menuDesc = 'บันทึกจัดการแจ้งเตือนเปิด-ปิดระบบ DLT Vehicle Tax Plus';
              break;
            case '7':
              text = 'รายการบัญชีผู้ลงทะเบียน';
              menuDesc = 'สอบถามข้อมูลผู้ลงทะเบียนใช้งานระบบ DLT Vehicle Tax Plus';
              break;
            case '8':
              text = 'จัดการคู่มือใช้งานระบบ';
              menuDesc = 'บันทึกจัดการจัดการคู่มือใช้งานระบบ';
              break;
            case '9':
              text = 'ผู้ใช้งานและกลุ่ม';
              menuDesc = 'บันทึกจัดการผู้ใช้งานเข้ากลุ่มผู้ใช้งาน';
              break;
            case '10':
              text = 'กลุ่มผู้ใช้งานและสิทธิ์';
              menuDesc = 'บันทึก/แก้ไข กลุ่มผู้ใช้งานและสิทธิ์ใช้งานระบบ';
              break;
            case '11':
              text = 'สื่อออนไลน์';
              menuDesc = 'บันทึกจัดการสื่อออนไลน์แนะนำกระบวนงานด้านทะเบียนรถ';
              break;
            case '12':
              text = 'นโยบายความเป็นส่วนตัว';
              menuDesc = 'บันทึก/แก้ไขข้อความของประกาศนโยบายความเป็นส่วนตัว (Privacy Notice)';
              break;
            case '13':
              text = 'ข้อมูลการชำระเงินจากธนาคาร';
              menuDesc = '';
              break;
            case '14':
              text = 'ประวัติการทำงานของระบบต่างๆ';
              menuDesc = 'สอบถามประวัติ (Log) การทำงานของระบบต่าง ๆ';
              break;
            case '15':
              text = 'ประวัติการใช้งาน Mobile App และ Kiosk';
              menuDesc = 'สอบถามประวัติ (Log) การใช้งานของโมบายแอพพลิเคชัน (Application)';
              break;
            case '16':
              text = 'ข้อมูลการสำรวจความพึงพอใจ';
              menuDesc = 'จัดการแบบสำรวจความพึงพอใจในการใช้งานระบบ';
              break;
            case '17':
              text = 'ข้อมูลการแจ้งเตือนต่างๆ';
              menuDesc = 'สอบถามรายการรถที่ได้แจ้งเตือนก่อนวันครบกำหนดชำระภาษี 90 วัน/แจ้งเตือนในวันครบกำหนด/แจ้งเตือนตรวจสภาพรถก่อนชำระภาษี';
              break;
            case '18':
              text = 'ประวัติการใช้งานระบบบริหารจัดการฯ';
              menuDesc = 'สอบถามประวัติ (Log) การใช้งานระบบบริหารจัดการ DLT Vehicle Tax Plus (Back Office)';
              break;
            case '19':
              text = 'ประวัติการขอใบรับรองการจดทะเบียนรถฯ';
              menuDesc = 'สอบถามประวัติ (Log) การขอใบรับรองการจดทะเบียนรูปแบบอิเล็กทรอนิกส์';
              break;
            case '20':
              text = 'ประวัติการชำระภาษีประจำปี';
              menuDesc = 'สอบถามประวัติ (Log) ของการชำระภาษีประจำปี';
              break;
            case '21':
              text = 'ประวัติการเข้าถึงใบคู่มือ';
              menuDesc = 'สอบถามประวัติ(Log) เข้าถึงใบคู่มือจดทะเบียนรถรูปแบบอิเล็กทรอนิกส์';
              break;
            default:
              text = 'ไม่พบรายการ';
              menuDesc = '';
              break;
          }
          if (menuId === '10' || menuId === '14') {
            return (
              <div>
                <Text strong>{menuId === '10' ? 'บันทึกจัดการผู้ใช้งานเข้ากลุ่มผู้ใช้งาน' : 'LOG'}</Text>
                <br />
                <div style={{ fontSize: 'small' }}>
                  <Text strong style={{ paddingLeft: '25px' }}>{text}</Text>
                  <br />
                  <Text type="secondary" style={{ paddingLeft: '25px' }}>{menuDesc}</Text>
                  <br />
                </div>
              </div>
            );
          } else if (menuId === '9' || menuId === '15' || menuId === '18' || menuId === '19' || menuId === '20' || menuId === '21') {
            return (
              <div>
                <div style={{ fontSize: 'small' }}>
                  <Text strong style={{ paddingLeft: '25px' }}>{text}</Text>
                  <br />
                  <Text type="secondary" style={{ paddingLeft: '25px' }}>{menuDesc}</Text>
                  <br />
                </div>
              </div>
            );
          } else {
            return (
              <div>
                <Text strong>{text}</Text>
                <br />
                <Text type="secondary" style={{ fontSize: 'small' }}>
                  {menuDesc}
                </Text>
                <br />
              </div>
            );
          }
        },
      },
      {
        title: 'เข้าถึง',
        dataIndex: 'canView',
        key: 'canView',
        render: (text, record) => {
          if (record.menuId === '2') {
            return null;
          }
          return <Switch checked={text} onChange={(value) => handlePermissionChange(record, 'canView', value)} />
        },
      },
      {
        title: 'จัดการข้อมูล',
        dataIndex: 'canManage',
        key: 'canManage',
        render: (text, record) => {
          if (record.menuId === '2') {
            return null;
          }
          return <Switch checked={text} onChange={(value) => handlePermissionChange(record, 'canManage', value)} />
        },
      },
    ];

    const handlePermissionChange = (record, key, value) => {
      const menuPermissions = data ? getFieldValue('menuPermissions') : creatingmenuPermission;

      const updatedPermissions = menuPermissions.map((permission) => {
        if (permission.menuId === record.menuId) {
          return {
            ...permission,
            [key]: value,
          };
        }
        return permission;
      });

      setFieldsValue({ menuPermissions: updatedPermissions });
      data ?? setCreatingmenuPermission(updatedPermissions);
    };


    return (
      <CustomModal
        title={data ? 'แก้ไขกลุ่มผู้ใช้งานและสิทธิ์ใช้งานระบบ' : 'เพิ่มกลุ่มผู้ใช้งานและสิทธิ์ใช้งานระบบ'}
        visible={visible}
        style={{ minWidth: '1000px' }}
        onCancel={onCancel}
        footer={[
          <Button key="back" onClick={onCancel}>
            ยกเลิก
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit}>
            บันทึก
          </Button>,
        ]}
      >
        <Form labelCol={{ span: 4 }} wrapperCol={{ span: 18 }}>
          <Spin spinning={formLoading}>
            <Form.Item label="ชื่อกลุ่มผู้ใช้งาน">
              {getFieldDecorator('groupName', {
                initialValue: get(data, ['groupName']),
                rules: [
                  {
                    required: !data,
                    message: 'กรุณากรอกชื่อกลุ่มผู้ใช้งาน',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="สถานะ">
              {getFieldDecorator('isActive', {
                valuePropName: 'checked',
                initialValue: get(data, ['isActive'], true),
              })(<Switch checkedChildren="เปิด" unCheckedChildren="ปิด" />)}
            </Form.Item>
            {get(data, ['menuPermissions']) ?
              <Form.Item label="สิทธิ์ในการใช้ระบบ">
                {getFieldDecorator('menuPermissions', {
                  initialValue: get(data, ['menuPermissions']),
                })(
                  <Table
                    dataSource={getFieldValue('menuPermissions')}
                    columns={permissionColumns}
                    pagination={false}
                  />
                )}
              </Form.Item>
              : null}
            {data ? null :
              <Form.Item label="สิทธิ์ในการใช้ระบบ">
                {getFieldDecorator('menuPermissions', {
                  initialValue: get(creatingmenuPermission),
                })(
                  <Table
                    dataSource={creatingmenuPermission}
                    columns={permissionColumns}
                    pagination={false}
                  />
                )}
              </Form.Item>
            }
          </Spin>
        </Form>
      </CustomModal>
    );
  }
);

export const CustomModal = styled(Modal)`
  #kiosksIds {
    display: inline-block;
  }
  .ant-checkbox-group-item {
    margin: 0;
    width: 50%;
  }
`;
