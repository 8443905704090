import React from 'react';
import {
  Row, Col, Card, Icon,
} from 'antd';
import { CardBlockTitle } from './style';
import { SpanColor } from '../../style/global';

export default () => (
  <>
    <Row gutter={16}>
      <Col span={8}>
        <Card
          title={(
            <CardBlockTitle>
              <span>
                <Icon type="printer" />
                เครื่องพิมพ์ 001
              </span>
              <Icon type="close-circle" theme="twoTone" twoToneColor="red" />
            </CardBlockTitle>
          )}
        >
          <p>
            สถานะหมึกเตรื่องพิมพ์:
            {' '}
            <SpanColor color="red">ไม่พร้อมใช้งาน</SpanColor>
          </p>
          <p>
            สถานะกระดาษ:
            {' '}
            <SpanColor color="green">พร้อมใช้งาน</SpanColor>
          </p>
        </Card>
      </Col>
      <Col span={8}>
        <Card
          title={(
            <CardBlockTitle>
              <span>
                <Icon type="printer" />
                เครื่องพิมพ์ 002
              </span>
              <Icon
                type="check-circle"
                theme="twoTone"
                twoToneColor="#52c41a"
              />
            </CardBlockTitle>
          )}
        >
          <p>
            สถานะหมึกเตรื่องพิมพ์:
            {' '}
            <SpanColor color="green">พร้อมใช้งาน</SpanColor>
          </p>
          <p>
            สถานะกระดาษ:
            {' '}
            <SpanColor color="green">พร้อมใช้งาน</SpanColor>
          </p>
        </Card>
      </Col>
    </Row>
  </>
);
