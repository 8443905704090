import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'moment/locale/th';

import './App.css';
import LoginScreens from './screens/LoginScreens';
import Routes from './Routes';
import { MainContextProvider } from './context/main';
import { SocketProvider } from './context/socket';
import { GlobalStyle } from './style/global';

function App() {
  return (
    <div className="App">
      <GlobalStyle />
      <Router>
        <MainContextProvider>
          <Switch>
            <Route exact path="/login">
              <LoginScreens />
            </Route>
            <Route path="/:page?/:subPage?">
              <SocketProvider>
                <Routes />
              </SocketProvider>
            </Route>
          </Switch>
        </MainContextProvider>
      </Router>
    </div>
  );
}

export default App;
