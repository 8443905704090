export default [
    {
        "OFF_LOC_CODE": "10501",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดพระนครศรีอยุธยา สาขาอ.มหาราช"
    },
    {
        "OFF_LOC_CODE": "20000",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดนครนายก"
    },
    {
        "OFF_LOC_CODE": "30700",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดอำนาจเจริญ"
    },
    {
        "OFF_LOC_CODE": "40301",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดนครพนม สาขาอ.ธาตุพนม"
    },
    {
        "OFF_LOC_CODE": "40300",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดนครพนม"
    },
    {
        "OFF_LOC_CODE": "50401",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดน่าน สาขาอ.ปัว"
    },
    {
        "OFF_LOC_CODE": "00103",
        "OFF_LOC_DESC": "สำนักงานขนส่งกรุงเทพมหานครพื้นที่ 3"
    },
    {
        "OFF_LOC_CODE": "50400",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดน่าน"
    },
    {
        "OFF_LOC_CODE": "00101",
        "OFF_LOC_DESC": "สำนักงานขนส่งกรุงเทพมหานครพื้นที่ 1"
    },
    {
        "OFF_LOC_CODE": "50402",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดน่าน สาขาอ.เวียงสา"
    },
    {
        "OFF_LOC_CODE": "00104",
        "OFF_LOC_DESC": "สำนักงานขนส่งกรุงเทพมหานครพื้นที่ 4"
    },
    {
        "OFF_LOC_CODE": "60500",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดพิจิตร"
    },
    {
        "OFF_LOC_CODE": "00102",
        "OFF_LOC_DESC": "สำนักงานขนส่งกรุงเทพมหานครพื้นที่ 2"
    },
    {
        "OFF_LOC_CODE": "60501",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดพิจิตร สาขาอ.โพทะเล"
    },
    {
        "OFF_LOC_CODE": "00100",
        "OFF_LOC_DESC": "สำนักงานขนส่งกรุงเทพมหานครพื้นที่ 5"
    },
    {
        "OFF_LOC_CODE": "70200",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดนครปฐม"
    },
    {
        "OFF_LOC_CODE": "10600",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดปทุมธานี"
    },
    {
        "OFF_LOC_CODE": "70202",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดนครปฐม สาขาอ.สามพราน"
    },
    {
        "OFF_LOC_CODE": "10601",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดปทุมธานี สาขาอ.คลองหลวง"
    },
    {
        "OFF_LOC_CODE": "20700",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดสระแก้ว"
    },
    {
        "OFF_LOC_CODE": "30001",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดชัยภูมิ สาขาอ.ภูเขียว"
    },
    {
        "OFF_LOC_CODE": "80401",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดนครศรีธรรมราช สาขาอ.ทุ่งสง"
    },
    {
        "OFF_LOC_CODE": "30002",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดชัยภูมิ สาขาอ.บำเหน็จณรงค์"
    },
    {
        "OFF_LOC_CODE": "90300",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดสตูล"
    },
    {
        "OFF_LOC_CODE": "30000",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดชัยภูมิ"
    },
    {
        "OFF_LOC_CODE": "90301",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดสตูล สาขาอ.ละงู"
    },
    {
        "OFF_LOC_CODE": "30800",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดหนองบัวลำภู"
    },
    {
        "OFF_LOC_CODE": "40402",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดสกลนคร สาขาอ.พังโคน"
    },
    {
        "OFF_LOC_CODE": "40400",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดสกลนคร"
    },
    {
        "OFF_LOC_CODE": "40401",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดสกลนคร สาขาอ.สว่างแดนดิน"
    },
    {
        "OFF_LOC_CODE": "50301",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดพะเยา สาขาอ.ภูซาง"
    },
    {
        "OFF_LOC_CODE": "50302",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดพะเยา สาขาอ.ปง"
    },
    {
        "OFF_LOC_CODE": "50300",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดพะเยา"
    },
    {
        "OFF_LOC_CODE": "60200",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดตาก"
    },
    {
        "OFF_LOC_CODE": "80300",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดพังงา"
    },
    {
        "OFF_LOC_CODE": "10500",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดพระนครศรีอยุธยา"
    },
    {
        "OFF_LOC_CODE": "20400",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดระยอง"
    },
    {
        "OFF_LOC_CODE": "10700",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดนนทบุรี"
    },
    {
        "OFF_LOC_CODE": "20401",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดระยอง สาขาอ.แกลง"
    },
    {
        "OFF_LOC_CODE": "30300",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดศรีสะเกษ"
    },
    {
        "OFF_LOC_CODE": "20600",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดตราด"
    },
    {
        "OFF_LOC_CODE": "30301",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดศรีสะเกษ สาขาอ.กันทรลักษ์"
    },
    {
        "OFF_LOC_CODE": "30101",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดยโสธร สาขาอ.เลิงนกทา"
    },
    {
        "OFF_LOC_CODE": "40700",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดมหาสารคาม"
    },
    {
        "OFF_LOC_CODE": "30102",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดยโสธร สาขาอ.มหาชนะชัย"
    },
    {
        "OFF_LOC_CODE": "40701",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดมหาสารคาม สาขาอ.พยัคฆภูมิพิสัย"
    },
    {
        "OFF_LOC_CODE": "30100",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดยโสธร"
    },
    {
        "OFF_LOC_CODE": "50001",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดเชียงราย สาขาอ.เทิง"
    },
    {
        "OFF_LOC_CODE": "30900",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดบึงกาฬ"
    },
    {
        "OFF_LOC_CODE": "50002",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดเชียงราย สาขาอ.เวียงป่าเป้า"
    },
    {
        "OFF_LOC_CODE": "90401",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดปัตตานี สาขาอ.สายบุรี"
    },
    {
        "OFF_LOC_CODE": "40500",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดขอนแก่น"
    },
    {
        "OFF_LOC_CODE": "90400",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดปัตตานี"
    },
    {
        "OFF_LOC_CODE": "40502",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดขอนแก่น สาขาอ.ชุมแพ"
    },
    {
        "OFF_LOC_CODE": "40504",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดขอนแก่น สาขาอ.น้ำพอง"
    },
    {
        "OFF_LOC_CODE": "40501",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดขอนแก่น สาขาอ.พล"
    },
    {
        "OFF_LOC_CODE": "40503",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดขอนแก่น สาขาอ.บ้านไผ่"
    },
    {
        "OFF_LOC_CODE": "20200",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดฉะเชิงเทรา"
    },
    {
        "OFF_LOC_CODE": "50201",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดเชียงใหม่ สาขาอ.ฝาง"
    },
    {
        "OFF_LOC_CODE": "20201",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดฉะเชิงเทรา สาขาอ.พนมสารคาม"
    },
    {
        "OFF_LOC_CODE": "50202",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดเชียงใหม่ สาขาอ.จอมทอง"
    },
    {
        "OFF_LOC_CODE": "30501",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดนครราชสีมา สาขาอ.ปากช่อง"
    },
    {
        "OFF_LOC_CODE": "50200",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดเชียงใหม่"
    },
    {
        "OFF_LOC_CODE": "30502",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดนครราชสีมา สาขาอ.บัวใหญ่"
    },
    {
        "OFF_LOC_CODE": "50203",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดเชียงใหม่ สาขาอ.แม่แตง"
    },
    {
        "OFF_LOC_CODE": "30505",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดนครราชสีมา สาขาอ.พิมาย"
    },
    {
        "OFF_LOC_CODE": "60302",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดพิษณุโลก สาขาอ.พรหมพิราม"
    },
    {
        "OFF_LOC_CODE": "60301",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดพิษณุโลก สาขาอ.นครไทย"
    },
    {
        "OFF_LOC_CODE": "30500",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดนครราชสีมา"
    },
    {
        "OFF_LOC_CODE": "70400",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดสมุทรสาคร"
    },
    {
        "OFF_LOC_CODE": "30503",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดนครราชสีมา สาขาอ.สีคิ้ว"
    },
    {
        "OFF_LOC_CODE": "80201",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดสุราษฎร์ธานี สาขาอ.เวียงสระ"
    },
    {
        "OFF_LOC_CODE": "30506",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดนครราชสีมา สาขาอ.ปักธงชัย"
    },
    {
        "OFF_LOC_CODE": "80204",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดสุราษฎร์ธานี สาขาอ.บ้านนาสาร"
    },
    {
        "OFF_LOC_CODE": "40101",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดเลย สาขาอ.ด่านซ้าย"
    },
    {
        "OFF_LOC_CODE": "80203",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดสุราษฎร์ธานี สาขาอ.ไชยา"
    },
    {
        "OFF_LOC_CODE": "40100",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดเลย"
    },
    {
        "OFF_LOC_CODE": "80200",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดสุราษฎร์ธานี"
    },
    {
        "OFF_LOC_CODE": "40900",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดมุกดาหาร"
    },
    {
        "OFF_LOC_CODE": "80202",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดสุราษฎร์ธานี สาขาอ.เกาะสมุย"
    },
    {
        "OFF_LOC_CODE": "50602",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดลำปาง สาขาอ.แจ้ห่ม"
    },
    {
        "OFF_LOC_CODE": "90500",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดยะลา"
    },
    {
        "OFF_LOC_CODE": "50603",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดลำปาง สาขาอ.งาว"
    },
    {
        "OFF_LOC_CODE": "90501",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดยะลา สาขาอ.เบตง"
    },
    {
        "OFF_LOC_CODE": "50600",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดลำปาง"
    },
    {
        "OFF_LOC_CODE": "60701",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดนครสวรรค์ สาขาอ.ตาคลี"
    },
    {
        "OFF_LOC_CODE": "60702",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดนครสวรรค์ สาขาอ.หนองบัว"
    },
    {
        "OFF_LOC_CODE": "60700",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดนครสวรรค์"
    },
    {
        "OFF_LOC_CODE": "70000",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดสุพรรณบุรี"
    },
    {
        "OFF_LOC_CODE": "70001",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดสุพรรณบุรี สาขาอ.เดิมบางนางบวช"
    },
    {
        "OFF_LOC_CODE": "70002",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดสุพรรณบุรี สาขาอ.สองพี่น้อง"
    },
    {
        "OFF_LOC_CODE": "80600",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดภูเก็ต"
    },
    {
        "OFF_LOC_CODE": "10401",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดสระบุรี สาขาอ.บ้านหมอ"
    },
    {
        "OFF_LOC_CODE": "10400",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดสระบุรี"
    },
    {
        "OFF_LOC_CODE": "20500",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดจันทบุรี"
    },
    {
        "OFF_LOC_CODE": "90101",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดตรัง สาขาอ.ห้วยยอด"
    },
    {
        "OFF_LOC_CODE": "20501",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดจันทบุรี สาขาอ.สอยดาว"
    },
    {
        "OFF_LOC_CODE": "90100",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดตรัง"
    },
    {
        "OFF_LOC_CODE": "30203",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดอุบลราชธานี สาขาอ.เขมราฐ"
    },
    {
        "OFF_LOC_CODE": "30200",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดอุบลราชธานี"
    },
    {
        "OFF_LOC_CODE": "40600",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดกาฬสินธุ์"
    },
    {
        "OFF_LOC_CODE": "50000",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดเชียงราย"
    },
    {
        "OFF_LOC_CODE": "40601",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดกาฬสินธุ์ สาขาอ.กุฉินารายณ์"
    },
    {
        "OFF_LOC_CODE": "60100",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดสุโขทัย"
    },
    {
        "OFF_LOC_CODE": "70600",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดเพชรบุรี"
    },
    {
        "OFF_LOC_CODE": "80001",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดชุมพร สาขาอ.หลังสวน"
    },
    {
        "OFF_LOC_CODE": "60000",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดอุตรดิตถ์"
    },
    {
        "OFF_LOC_CODE": "80000",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดชุมพร"
    },
    {
        "OFF_LOC_CODE": "60002",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดอุตรดิตถ์ สาขาอ.ตรอน"
    },
    {
        "OFF_LOC_CODE": "60800",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดอุทัยธานี"
    },
    {
        "OFF_LOC_CODE": "10000",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดชัยนาท"
    },
    {
        "OFF_LOC_CODE": "10001",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดชัยนาท สาขาอ.หันคา"
    },
    {
        "OFF_LOC_CODE": "10800",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดสมุทรปราการ"
    },
    {
        "OFF_LOC_CODE": "10801",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดสมุทรปราการ สาขาอ.พระประแดง"
    },
    {
        "OFF_LOC_CODE": "20100",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดปราจีนบุรี"
    },
    {
        "OFF_LOC_CODE": "30600",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดสุรินทร์"
    },
    {
        "OFF_LOC_CODE": "30603",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดสุรินทร์ สาขาอ.สังขะ"
    },
    {
        "OFF_LOC_CODE": "30601",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดสุรินทร์ สาขาอ.รัตนบุรี"
    },
    {
        "OFF_LOC_CODE": "40204",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดอุดรธานี สาขาอ.บ้านผือ"
    },
    {
        "OFF_LOC_CODE": "40205",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดอุดรธานี สาขาอ.เพ็ญ"
    },
    {
        "OFF_LOC_CODE": "40201",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดอุดรธานี สาขาอ.กุมภวาปี"
    },
    {
        "OFF_LOC_CODE": "40200",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดอุดรธานี"
    },
    {
        "OFF_LOC_CODE": "40203",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดอุดรธานี สาขาอ.หนองหาน"
    },
    {
        "OFF_LOC_CODE": "50502",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดลำพูน สาขาอ.ลี้"
    },
    {
        "OFF_LOC_CODE": "50500",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดลำพูน"
    },
    {
        "OFF_LOC_CODE": "60801",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดอุทัยธานี สาขาอ.บ้านไร่"
    },
    {
        "OFF_LOC_CODE": "60400",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดกำแพงเพชร"
    },
    {
        "OFF_LOC_CODE": "70700",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดประจวบคีรีขันธ์"
    },
    {
        "OFF_LOC_CODE": "70701",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดประจวบคีรีขันธ์ สาขาอ.ปราณบุรี"
    },
    {
        "OFF_LOC_CODE": "10200",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดลพบุรี"
    },
    {
        "OFF_LOC_CODE": "60401",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดกำแพงเพชร สาขาอ.ขาณุวรลักษบุรี"
    },
    {
        "OFF_LOC_CODE": "70300",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดราชบุรี"
    },
    {
        "OFF_LOC_CODE": "10201",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดลพบุรี สาขาอ.ชัยบาดาล"
    },
    {
        "OFF_LOC_CODE": "70302",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดราชบุรี สาขาอ.จอมบึง"
    },
    {
        "OFF_LOC_CODE": "10202",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดลพบุรี สาขาอ.โคกสำโรง"
    },
    {
        "OFF_LOC_CODE": "70301",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดราชบุรี สาขาอ.บ้านโป่ง"
    },
    {
        "OFF_LOC_CODE": "20300",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดชลบุรี"
    },
    {
        "OFF_LOC_CODE": "20302",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดชลบุรี สาขาอ.หนองใหญ่"
    },
    {
        "OFF_LOC_CODE": "80500",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดกระบี่"
    },
    {
        "OFF_LOC_CODE": "20303",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดชลบุรี สาขาอ.ศรีราชา"
    },
    {
        "OFF_LOC_CODE": "20301",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดชลบุรี สาขาอ.บางละมุง"
    },
    {
        "OFF_LOC_CODE": "90202",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดสงขลา สาขาอ.นาทวี"
    },
    {
        "OFF_LOC_CODE": "30401",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดบุรีรัมย์ สาขาอ.นางรอง"
    },
    {
        "OFF_LOC_CODE": "90200",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดสงขลา"
    },
    {
        "OFF_LOC_CODE": "30402",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดบุรีรัมย์ สาขาอ.พุทไธสง"
    },
    {
        "OFF_LOC_CODE": "30400",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดบุรีรัมย์"
    },
    {
        "OFF_LOC_CODE": "30403",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดบุรีรัมย์ สาขาอ.ประโคนชัย"
    },
    {
        "OFF_LOC_CODE": "40000",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดหนองคาย"
    },
    {
        "OFF_LOC_CODE": "40001",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดหนองคาย สาขาอ.บึงกาฬ"
    },
    {
        "OFF_LOC_CODE": "40802",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดร้อยเอ็ด สาขาอ.สุวรรณภูมิ"
    },
    {
        "OFF_LOC_CODE": "40800",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดร้อยเอ็ด"
    },
    {
        "OFF_LOC_CODE": "40801",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดร้อยเอ็ด สาขาอ.โพนทอง"
    },
    {
        "OFF_LOC_CODE": "50702",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดแพร่ สาขาอ.เด่นชัย"
    },
    {
        "OFF_LOC_CODE": "50700",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดแพร่"
    },
    {
        "OFF_LOC_CODE": "50703",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดแพร่ สาขาอ.ลอง"
    },
    {
        "OFF_LOC_CODE": "50701",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดแพร่ สาขาอ.ร้องกวาง"
    },
    {
        "OFF_LOC_CODE": "60600",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดเพชรบูรณ์"
    },
    {
        "OFF_LOC_CODE": "60603",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดเพชรบูรณ์ สาขาอ.หล่มสัก"
    },
    {
        "OFF_LOC_CODE": "60602",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดเพชรบูรณ์ สาขาอ.ชนแดน"
    },
    {
        "OFF_LOC_CODE": "70100",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดกาญจนบุรี"
    },
    {
        "OFF_LOC_CODE": "70103",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดกาญจนบุรี สาขาอ.ทองผาภูมิ"
    },
    {
        "OFF_LOC_CODE": "70102",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดกาญจนบุรี สาขาอ.พนมทวน"
    },
    {
        "OFF_LOC_CODE": "70101",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดกาญจนบุรี สาขาอ.ท่ามะกา"
    },
    {
        "OFF_LOC_CODE": "50101",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดแม่ฮ่องสอน สาขาอ.แม่สะเรียง"
    },
    {
        "OFF_LOC_CODE": "70601",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดเพชรบุรี สาขาอ.ชะอำ"
    },
    {
        "OFF_LOC_CODE": "90000",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดพัทลุง"
    },
    {
        "OFF_LOC_CODE": "90001",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดพัทลุง สาขาอ.ตะโหมด"
    },
    {
        "OFF_LOC_CODE": "90002",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดพัทลุง สาขาอ.ควนขนุน"
    },
    {
        "OFF_LOC_CODE": "80100",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดระนอง"
    },
    {
        "OFF_LOC_CODE": "90601",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดนราธิวาส สาขาอ.สุไหงโก-ลก"
    },
    {
        "OFF_LOC_CODE": "90602",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดนราธิวาส สาขาอ.รือเสาะ"
    },
    {
        "OFF_LOC_CODE": "90600",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดนราธิวาส"
    },
    {
        "OFF_LOC_CODE": "10100",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดสิงห์บุรี"
    },
    {
        "OFF_LOC_CODE": "60300",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดพิษณุโลก"
    },
    {
        "OFF_LOC_CODE": "70500",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดสมุทรสงคราม"
    },
    {
        "OFF_LOC_CODE": "50003",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดเชียงราย สาขาอ.พาน"
    },
    {
        "OFF_LOC_CODE": "70702",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดประจวบคีรีขันธ์ สาขาอ.บางสะพาน"
    },
    {
        "OFF_LOC_CODE": "60001",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดอุตรดิตถ์ สาขาอ.น้ำปาด"
    },
    {
        "OFF_LOC_CODE": "60101",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดสุโขทัย สาขาอ.สวรรคโลก"
    },
    {
        "OFF_LOC_CODE": "60201",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดตาก สาขาอ.แม่สอด"
    },
    {
        "OFF_LOC_CODE": "30504",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดนครราชสีมา สาขาอ.โชคชัย"
    },
    {
        "OFF_LOC_CODE": "60601",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดเพชรบูรณ์ สาขาอ.บึงสามพัน"
    },
    {
        "OFF_LOC_CODE": "70201",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดนครปฐม สาขาอ.กำแพงแสน"
    },
    {
        "OFF_LOC_CODE": "80301",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดพังงา สาขาอ.ตะกั่วป่า"
    },
    {
        "OFF_LOC_CODE": "60703",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดนครสวรรค์ สาขาอ.ลาดยาว"
    },
    {
        "OFF_LOC_CODE": "30602",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดสุรินทร์ สาขาอ.ปราสาท"
    },
    {
        "OFF_LOC_CODE": "80402",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดนครศรีธรรมราช สาขาอ.สิชล"
    },
    {
        "OFF_LOC_CODE": "80400",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดนครศรีธรรมราช"
    },
    {
        "OFF_LOC_CODE": "20601",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดตราด สาขาอ.เขาสมิง"
    },
    {
        "OFF_LOC_CODE": "10300",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดอ่างทอง"
    },
    {
        "OFF_LOC_CODE": "30507",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดนครราชสีมา สาขาอ.ด่านขุนทด"
    },
    {
        "OFF_LOC_CODE": "50601",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดลำปาง สาขาอ.เถิน"
    },
    {
        "OFF_LOC_CODE": "30202",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดอุบลราชธานี สาขาอ.เดชอุดม"
    },
    {
        "OFF_LOC_CODE": "50100",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดแม่ฮ่องสอน"
    },
    {
        "OFF_LOC_CODE": "50501",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดลำพูน สาขาอ.บ้านโฮ่ง"
    },
    {
        "OFF_LOC_CODE": "80501",
        "OFF_LOC_DESC": "สำนักงานขนส่งจังหวัดกระบี่ สาขาอ.อ่าวลึก"
    }
]