import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { convertToDatetimeBE, convertToDatetimeFullBE } from '../../utils';
import { Table, Tag, Button } from 'antd';

import _ from 'lodash';
import { MainContext } from '../../context/main';

import {
  getFilter,
  getSort,
  getPage,
  getColumnSearchProps,
  perPage,
  defaultPageSize,
  pageSizeOptions,
  showSizeChanger,
} from '../../utils/table';
import { formatPhoneNumber } from '../../utils';
import { HeaderBlock } from '../../style/global';
import FilterModal from '../../component/FilterModal';
import vehicleTypeData from '../../utils/staticData/vehicleTypeData';
import provinceData from '../../utils/staticData/provinceData';
import Swal from 'sweetalert2';
const searchKey = [
  { label: 'ชื่อ-สกุลผู้ใช้งาน', key: 'userDetail' },
  { label: 'โปรแกรมที่เข้าใช้', key: 'system_name' },
  { label: 'โมดูลที่เข้าใช้', key: 'detail' },
  // { label: 'หมวดทะเบียน', key: 'plate1' },
  // { label: 'เลขทะเบียน', key: 'plate2' },
  // { label: 'จังหวัด', key: 'offlocdesc' },
  { label: 'เลขทะเบียน', key: 'plate', placeholder: 'ตัวอย่าง เช่น กก 1111, 80 1234' },
  {
    label: 'จังหวัดจดทะเบียน', key: 'offlocdesc', type: 'select2',
    options: [
      { title: "ทั้งหมด", value: "" },
      ...provinceData.map((o) => ({ title: o.province, value: o.province }))
    ]
  },
  {
    label: 'ประเภทพรบ.', key: 'plateType', type: 'select2',
    options: [
      { title: "ทั้งหมด", value: "" },
      { title: "รถยนต์", value: "1" },
      { title: "รถขนส่ง", value: "2" },
    ]
  },
  {
    label: 'ประเภทรถ', key: 'vehTypeCode', type: 'select2',
    options: [
      { title: "ทั้งหมด", value: "" },
      ...vehicleTypeData.map((o) => ({ title: o.name, value: o.id }))
    ]
  },
  {
    label: 'ช่วงวันที่เข้าใช้งาน',
    key: 'date-range',
    type: 'rangepicker',
  },
  {
    label: '*หากไม่กำหนดช่วงวันที่เข้าใช้งานระบบจะทำการเลือกเฉพาะวันที่ปัจจุบันเท่านั้น',
    key: '#',
    type: 'label',
  },
];

const columns = [
  {
    title: 'ลำดับ',
    key: 'index',
    dataIndex: 'index',
  },
  {
    title: 'ชื่อ-นามสกุลผู้ใช้งาน',
    key: 'userDetail',
    dataIndex: 'userDetail',
    render: (userDetail) => (
      userDetail && userDetail.first_name && userDetail.last_name
        ? `${userDetail.first_name} ${userDetail.last_name}`
        : 'ไม่พบข้อมูล'
    ),
  },
  {
    title: 'โปรแกรมที่เข้าใช้',
    key: 'system_name',
    dataIndex: 'system_name',
    render: (v) => (
      // <Tag color={'lightskyblue'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      `${v}`
      // </Tag>
    )
  },
  {
    title: 'โมดูลที่เข้าใช้',
    key: 'detail',
    dataIndex: 'detail',
    width: '40%',
  },
  {
    title: 'ทะเบียนรถ (ถ้ามี)',
    key: 'plate1',
    dataIndex: 'plate1',
    render: (plate1, record) => plate1 ? `${plate1}${isNaN(parseInt(record.typecar)) ? '-' : ' '}${record.plate2} ${record.offlocdesc} ${isNaN(parseInt(record.typecar)) ? '' : 'รย.' + parseInt(record.typecar)}` : '-',
  },
  {
    title: 'วันเวลาที่ใช้งาน',
    key: 'created',
    dataIndex: 'created',
    sorter: () => { },
    defaultSortOrder: 'descend',
    render: (v) => convertToDatetimeBE(v),
  },
];

const isAllow = (menuPermissions, targetmenuPermissions) => {
  const thisMenuId = "15"
  const permission = _.find(menuPermissions, { menuId: thisMenuId });
  return permission && permission.canManage;
};


export default () => {
  const { request, userProfile } = useContext(MainContext);
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState({});
  const [sorter, setSorter] = useState({
    sort: { key: 'created', desc: true },
  });
  const [pagination, setPagination] = useState({
    // pageSize: perPage,
    defaultPageSize,
    pageSizeOptions,
    showSizeChanger,
  });
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const fetchData = async (params = {}) => {
    try {
      setLoading(true);
      const res = await request.get('/userlogs', {
        ...params,
        ...searchData,
      });

      const totalData = _.get(res, ['total_data'], 0);

      setData(_.get(res, ['results'], []));
      setPagination({ ...pagination, total: totalData, current: params.page, pageSize: params.per_page });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (paginationChange, filters, sorterChange) => {
    const pager = { ...pagination };
    pager.current = paginationChange.current;

    setPagination(pager);
    setSorter(getSort(sorterChange));

    fetchData({
      ...getFilter(filters),
      ...getSort(sorterChange),
      ...getPage(paginationChange),
    });
  };

  useEffect(() => {
    fetchData({
      ...getPage(pagination),
      ...sorter,
    });
  }, [searchData]);

  const handleSearch = (value) => {
    pagination.current = 1;
    setSearchData({ ..._.omitBy(value, (o) => _.isNil(o) || _.isEmpty(o)) });

    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOpenModal = () => {
    setVisible(true);
  };
  const handleDownloadWithConfirmation = async () => {
    const confirmResult = await Swal.fire({
      text: 'ยืนยันการออกรายงาน',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'ตกลง',
      cancelButtonText: 'ยกเลิก',
    });
  
    if (confirmResult.isConfirmed) {
      await handleDownload();
    }
  };
  const handleDownload = async (fileName = 'รายงานประวัติการใช้งาน Mobile App และ Kiosk.xlsx') => {
    if (loading) return;
    try {
      setLoading(true);
      fileName = 'รายงานประวัติการใช้งาน Mobile App และ Kiosk.xlsx';
      await request.download('/userlogs/report', fileName, {
        ...getPage(pagination),
        ...sorter,
        ...searchData
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <HeaderBlock style={{ fontSize: '20px', fontWeight: 'bold' }}>
        สอบถามประวัติ (Log) การใช้งานของโมบายแอพพลิเคชัน (Application)
      </HeaderBlock>
      <HeaderBlock>
        <Button type="primary" onClick={handleOpenModal}>
          ค้นหา
        </Button>
        {isAllow(userProfile.menuPermissions) && (
          <div>
            <Button
              style={{ margin: '0 0 0 16px' }}
              icon="export"
              onClick={handleDownloadWithConfirmation}
            >
              Export
            </Button>
          </div>
        )}
      </HeaderBlock>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 'max-content' }}
      />
      <FilterModal
        visible={visible}
        handleSearch={handleSearch}
        handleCancel={handleCancel}
        renderInputFields={searchKey}
      />
    </>
  );
};
