import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Table, Tag, Button, Spin, Row, Col } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { convertToDatetimeBE, convertToDateBE } from '../../utils';
import { Link } from 'react-router-dom';

import { MainContext } from '../../context/main';
import {
  getPage, getFilter, getSort, perPage,
  defaultPageSize,
  pageSizeOptions,
  showSizeChanger,
} from '../../utils/table';
import { makeDecimalNumber } from '../../utils/form';
import { chan } from '../../utils';
import { HeaderBlock } from '../../style/global';
import FilterModal from '../../component/FilterModal';

const searchKey = [
  { label: 'ชื่อไฟล์', key: 'file_name' },
  { label: 'วันที่ธนาคารส่งข้อมูล', type: 'datepicker', key: 'date_picker' }
];

const columns = [
  {
    title: 'ลำดับ',
    key: 'index',
    dataIndex: 'index',
  },
  {
    title: 'ชื่อไฟล์',
    key: 'file_name',
    dataIndex: 'file_name',
  },
  {
    title: 'จำนวนเงินรวม',
    key: 'total_credit_amount',
    dataIndex: 'total',
    render: (o) => makeDecimalNumber(_.get(o, ['total_credit_amount'])),
  },
  {
    title: 'จำนวนการทำรายการรวม',
    key: 'total_credit_transaction',
    dataIndex: 'total',
    render: (o) => _.toSafeInteger(_.get(o, ['total_credit_transaction'])),
  },
  {
    title: 'วันที่ธนาคารส่งข้อมูล',
    key: 'effective_date',
    dataIndex: 'header',
    render: (o) => convertToDateBE(_.get(o, ['effective_date'])),
  },
  {
    title: 'วันที่นำเข้าข้อมูล',
    key: 'created',
    dataIndex: 'created',
    sorter: true,
    defaultSortOrder: 'descend',
    render: (v) => convertToDatetimeBE(v),
  },
  {
    title: '',
    key: 'id',
    fixed: 'right',
    render: (o) => {
      const targetDate = moment(
        _.get(o, ['header', 'effective_date']),
        'DDMMYYYY',
      ).format('DD-MM-YYYY');

      return <Link to={`/historical/${targetDate}`}>รายละเอียด</Link>;
    },
  },
];

const isAllow = (menuPermissions, targetmenuPermissions) => {
  const thisMenuId = "13"
  const permission = _.find(menuPermissions, { menuId: thisMenuId });
  return permission && permission.canManage;
};

export default () => {
  const { request, userProfile } = useContext(MainContext);
  const { date } = useParams();

  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState({});
  const [sorter, setSorter] = useState({
    sort: { key: 'created', desc: true },
  });
  const [pagination, setPagination] = useState({
    // pageSize: perPage,
    defaultPageSize,
    pageSizeOptions,
    showSizeChanger,
  });
  const [loading, setLoading] = useState(false);
  const [jobLoading, setJobLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const fetchData = async (params = {}) => {
    setLoading(true);
    try {
      const res = await request.get('/scb/historical', {
        ...params,
        ...searchData,
      });

      const totalData = _.get(res, ['total_data'], 0);

      const result = _.get(res, ['results'], []);

      setData(result);
      setPagination({ ...pagination, total: totalData, current: params.page, pageSize: params.per_page });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (paginationChange, filters, sorterChange) => {
    const pager = { ...pagination };
    pager.current = paginationChange.current;

    setPagination(pager);
    setSorter(getSort(sorterChange));

    fetchData({
      ...getFilter(filters),
      ...getSort(sorterChange),
      ...getPage(paginationChange),
    });
  };


  useEffect(() => {
    fetchData({
      // ...getPage(pagination),
      ...sorter,
    });
  }, [searchData, date]);

  const handleSearch = (value) => {
    pagination.current = 1;
    setSearchData({ ..._.omitBy(value, (o) => _.isNil(o) || _.isEmpty(o)) });

    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOpenModal = () => {
    setVisible(true);
  };


  const runJob = async () => {
    setJobLoading(true);
    try {
      const res = await request.get('/scb/historical/job');

      if (_.get(res, ['success'])) {
        fetchData({
          ...sorter,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setJobLoading(false);
    }
  };

  useEffect(() => {
    fetchData({
      // ...getPage(pagination),
      ...sorter,
    });
  }, []);

  return (
    <Spin spinning={loading}>
      <HeaderBlock style={{ fontSize: '20px', fontWeight: 'bold' }}>
        ข้อมูลการชำระเงินจากธนาคาร
      </HeaderBlock>
      <HeaderBlock>
        {isAllow(userProfile.menuPermissions) && (
          <>
            <Button type="primary" onClick={handleOpenModal}>
              ค้นหา
            </Button>
            <div>
              <Button type="primary" loading={jobLoading} onClick={runJob}>
                ดึงข้อมูลจากไฟล์
              </Button>
            </div>
          </>
        )}
      </HeaderBlock>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={pagination}
        onChange={handleTableChange}
        scroll={{ x: 'max-content' }}
      />
      <FilterModal
        visible={visible}
        handleSearch={handleSearch}
        handleCancel={handleCancel}
        renderInputFields={searchKey}
      />
    </Spin>
  );
};
