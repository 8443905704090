import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import ls from 'local-storage';

export default ({ children, ...rest }) => {
  const isLogin = ls.get('isLogin');
  return (
    <Route
      {...rest}
      render={({ location }) => (isLogin ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      ))}
    />
  )
};
