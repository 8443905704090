import React from 'react';
import { Input, Icon, Button } from 'antd';
import get from 'lodash/get';
import toSafeInteger from 'lodash/toSafeInteger';
import split from 'lodash/split';
import map from 'lodash/map';
import times from 'lodash/times';
import ceil from 'lodash/ceil';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import toString from 'lodash/toString';
import uniqueId from 'lodash/uniqueId';

export const perPage = 20;
export const defaultPageSize = 20;
export const pageSizeOptions = ['6', '10', '15', '20', '50'];
export const showSizeChanger = true;
export const current = 1;
export const toBoolean = (v) => v === true || v === 'true';

export const getPage = (paginateObj) => (
  console.log("paginateObj",paginateObj),
  {
  page: get(paginateObj, 'current', 1),
  per_page: get(paginateObj, 'pageSize', 20),
});

export const getSort = (sortObj) => {
  const sortKey = get(sortObj, ['columnKey']);
  const sortDesc = get(sortObj, ['order']);

  const sortOpt = {};

  if (sortKey) {
    const newSortObj = {};
    newSortObj.key = sortKey;
    newSortObj.desc = sortDesc === 'descend';

    sortOpt.sort = newSortObj;
  }

  return sortOpt;
};

export const getFilter = (filterObj) => {
  const newFilter = Object.entries(filterObj).reduce((acc, cur, i) => {
    const key = get(cur, [0]);
    const value = get(cur, [1, 0], '');

    if (!key || !value) return acc;

    acc[key] = value;
    return acc;
  }, {});

  return newFilter;
};

export const getColumnSearchProps = (placeholder) => {
  let inputRef;

  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(n) => {
            inputRef = n;
          }}
          placeholder={placeholder}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => {
            confirm();
          }}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon="search"
            size="small"
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();
            }}
            size="small"
          >
            Reset
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => inputRef.select());
      }
    },
  };
};
