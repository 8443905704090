import React, { useEffect, useState, useContext } from 'react';
import {
  Modal,
  Form,
  Select,
  DatePicker,
  Input,
  Button,
  Spin,
  TimePicker,
  Icon,
  Switch,
  message,
  Typography,
} from 'antd';

import _, { now } from 'lodash';
import get from 'lodash/get';
import { LongLine } from '../../style/global';
import styled from 'styled-components';
import moment from 'moment';
const { Title } = Typography;
const { Option } = Select;

export default Form.create()(
  ({
    visible,
    onSubmit = () => { },
    onCancel = () => { },
    form: { getFieldDecorator, validateFields, resetFields, getFieldValue },
    data,
    formLoading,

  }) => {
    const handleSubmit = (e) => {
      e.preventDefault();

      validateFields(async (err, fieldsValue) => {
        if (err) {
          return;
        }
        onSubmit(_.omit(fieldsValue));
      });
    };

    useEffect(() => {
      if (!visible) {
        resetFields();
      }
    }, [visible]);


    return (

      <CustomModal
        title="ตั้งค่าแบบสำรวจความพึงพอใจ"
        visible={visible}
        style={{ minWidth: '800px' }}
        onCancel={onCancel}
        footer={[
          <Button key="back" onClick={onCancel}>
            ยกเลิก
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit}>
            บันทึก
          </Button>,
        ]}
      >

        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>
          <Spin spinning={formLoading}>

            <Title level={4}>ชำระภาษีประจำปี</Title>
            <Form.Item label="สถานะ">
              {getFieldDecorator('Tax_isActive', {
                valuePropName: 'checked',
                initialValue: get(data, ['Tax_isActive'], false),
              })(
                <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" />
              )}
            </Form.Item>
            <Form.Item label="กำหนดช่วงเวลาเผยแพร่">
              {getFieldDecorator('Tax_isLimitTime', {
                valuePropName: 'checked',
                initialValue: get(data, ['Tax_isLimitTime'], false),
              })(
                <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" />
              )}
            </Form.Item>
            {getFieldValue('Tax_isLimitTime') && (
              <>
                <Form.Item label="เวลาเริ่มเผยแพร่">
                  {getFieldDecorator('Tax_LimitTimeStart', {
                    initialValue: moment(get(data, ['Tax_LimitTimeStart'])) || moment(),
                    rules: [
                      {
                        required: !data,
                        message: 'กรุณากรอกวันที่เริ่ม',
                      }
                    ],
                  })(
                    <DatePicker defaultPicker={moment()} />
                  )}
                  {getFieldDecorator('Tax_LimitTimeStart', {
                    initialValue: moment(get(data, ['Tax_LimitTimeStart'])) || moment(),
                    rules: [
                      {
                        required: !data,
                        message: 'กรุณากรอกเวลาเริ่ม',
                      }
                    ],
                  })(
                    <TimePicker defaultPicker={moment()} format={'HH:mm'} />
                  )}
                </Form.Item>
                <Form.Item label="วันเวลาสิ้นสุด">
                  {getFieldDecorator('Tax_LimitTimeEnd', {
                    initialValue: moment(get(data, ['Tax_LimitTimeEnd'])) || moment(),
                    rules: [
                      {
                        required: !data,
                        message: 'กรุณากรอกวันสิ้นสุด',
                      }
                    ],
                  })(
                    <DatePicker />
                  )}
                  {getFieldDecorator('Tax_LimitTimeEnd', {
                    initialValue: moment(get(data, ['Tax_LimitTimeEnd'])) || moment(),
                    rules: [
                      {
                        required: !data,
                        message: 'กรุณากรอกเวลาสิ้นสุด',
                      }
                    ],
                  })(
                    <TimePicker defaultPicker={moment()} format={'HH:mm'} />
                  )}
                </Form.Item>
              </>
            )}
            <LongLine />

            <Title level={4}>ใบคู่มือจดทะเบียนรถอิเล็กทรอนิกส์</Title>
            <Form.Item label="สถานะ">
              {getFieldDecorator('CarManual_isActive', {
                valuePropName: 'checked',
                initialValue: get(data, ['CarManual_isActive'], false),
              })(
                <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" />
              )}
            </Form.Item>
            <Form.Item label="กำหนดช่วงเวลาเผยแพร่">
              {getFieldDecorator('CarManual_isLimitTime', {
                valuePropName: 'checked',
                initialValue: get(data, ['CarManual_isLimitTime'], false),
              })(
                <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" />
              )}
            </Form.Item>
            {getFieldValue('CarManual_isLimitTime') && (
              <>
                <Form.Item label="เวลาเริ่มเผยแพร่">
                  {getFieldDecorator('CarManual_LimitTimeStart', {
                    initialValue: moment(get(data, ['CarManual_LimitTimeStart'])) || moment(),
                    rules: [
                      {
                        required: !data,
                        message: 'กรุณากรอกวันที่เริ่ม',
                      }
                    ],
                  })(
                    <DatePicker defaultPicker={moment()} />
                  )}
                  {getFieldDecorator('CarManual_LimitTimeStart', {
                    initialValue: moment(get(data, ['CarManual_LimitTimeStart'])) || moment(),
                    rules: [
                      {
                        required: !data,
                        message: 'กรุณากรอกเวลาเริ่ม',
                      }
                    ],
                  })(
                    <TimePicker defaultPicker={moment()} format={'HH:mm'} />
                  )}
                </Form.Item>
                <Form.Item label="วันเวลาสิ้นสุด">
                  {getFieldDecorator('CarManual_LimitTimeEnd', {
                    initialValue: moment(get(data, ['CarManual_LimitTimeEnd'])) || moment(),
                    rules: [
                      {
                        required: !data,
                        message: 'กรุณากรอกวันสิ้นสุด',
                      }
                    ],
                  })(
                    <DatePicker />
                  )}
                  {getFieldDecorator('CarManual_LimitTimeEnd', {
                    initialValue: moment(get(data, ['CarManual_LimitTimeEnd'])) || moment(),
                    rules: [
                      {
                        required: !data,
                        message: 'กรุณากรอกเวลาสิ้นสุด',
                      }
                    ],
                  })(
                    <TimePicker defaultPicker={moment()} format={'HH:mm'} />
                  )}
                </Form.Item>
              </>
            )}
            <LongLine />

            <Title level={4}>ขอใบรับรองการจดทะเบียนรถ</Title>
            <Form.Item label="สถานะ">
              {getFieldDecorator('OwnerCert_isActive', {
                valuePropName: 'checked',
                initialValue: get(data, ['OwnerCert_isActive'], false),
              })(
                <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" />
              )}
            </Form.Item>
            <Form.Item label="กำหนดช่วงเวลาเผยแพร่">
              {getFieldDecorator('OwnerCert_isLimitTime', {
                valuePropName: 'checked',
                initialValue: get(data, ['OwnerCert_isLimitTime'], false),
              })(
                <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" />
              )}
            </Form.Item>
            {getFieldValue('OwnerCert_isLimitTime') && (
              <>
                <Form.Item label="เวลาเริ่มเผยแพร่">
                  {getFieldDecorator('OwnerCert_LimitTimeStart', {
                    initialValue: moment(get(data, ['OwnerCert_LimitTimeStart'])) || moment(),
                    rules: [
                      {
                        required: !data,
                        message: 'กรุณากรอกวันที่เริ่ม',
                      }
                    ],
                  })(
                    <DatePicker defaultPicker={moment()} />
                  )}
                  {getFieldDecorator('OwnerCert_LimitTimeStart', {
                    initialValue: moment(get(data, ['OwnerCert_LimitTimeStart'])) || moment(),
                    rules: [
                      {
                        required: !data,
                        message: 'กรุณากรอกเวลาเริ่ม',
                      }
                    ],
                  })(
                    <TimePicker defaultPicker={moment()} format={'HH:mm'} />
                  )}
                </Form.Item>
                <Form.Item label="วันเวลาสิ้นสุด">
                  {getFieldDecorator('OwnerCert_LimitTimeEnd', {
                    initialValue: moment(get(data, ['OwnerCert_LimitTimeEnd'])) || moment(),
                    rules: [
                      {
                        required: !data,
                        message: 'กรุณากรอกวันสิ้นสุด',
                      }
                    ],
                  })(
                    <DatePicker />
                  )}
                  {getFieldDecorator('OwnerCert_LimitTimeEnd', {
                    initialValue: moment(get(data, ['OwnerCert_LimitTimeEnd'])) || moment(),
                    rules: [
                      {
                        required: !data,
                        message: 'กรุณากรอกเวลาสิ้นสุด',
                      }
                    ],
                  })(
                    <TimePicker defaultPicker={moment()} format={'HH:mm'} />
                  )}
                </Form.Item>
              </>
            )}
            <LongLine />

            <Title level={4}>เครื่องหมายการเสียภาษีประจำปีอิเล็กทรอนิกส์</Title>
            <Form.Item label="สถานะ">
              {getFieldDecorator('CircleTax_isActive', {
                valuePropName: 'checked',
                initialValue: get(data, ['CircleTax_isActive'], false),
              })(
                <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" />
              )}
            </Form.Item>
            <Form.Item label="กำหนดช่วงเวลาเผยแพร่">
              {getFieldDecorator('CircleTax_isLimitTime', {
                valuePropName: 'checked',
                initialValue: get(data, ['CircleTax_isLimitTime'], false),
              })(
                <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" />
              )}
            </Form.Item>
            {getFieldValue('CircleTax_isLimitTime') && (
              <>
                <Form.Item label="เวลาเริ่มเผยแพร่">
                  {getFieldDecorator('CircleTax_LimitTimeStart', {
                    initialValue: moment(get(data, ['CircleTax_LimitTimeStart'])) || moment(),
                    rules: [
                      {
                        required: !data,
                        message: 'กรุณากรอกวันที่เริ่ม',
                      }
                    ],
                  })(
                    <DatePicker defaultPicker={moment()} />
                  )}
                  {getFieldDecorator('CircleTax_LimitTimeStart', {
                    initialValue: moment(get(data, ['CircleTax_LimitTimeStart'])) || moment(),
                    rules: [
                      {
                        required: !data,
                        message: 'กรุณากรอกเวลาเริ่ม',
                      }
                    ],
                  })(
                    <TimePicker defaultPicker={moment()} format={'HH:mm'} />
                  )}
                </Form.Item>
                <Form.Item label="วันเวลาสิ้นสุด">
                  {getFieldDecorator('CircleTax_LimitTimeEnd', {
                    initialValue: moment(get(data, ['CircleTax_LimitTimeEnd'])) || moment(),
                    rules: [
                      {
                        required: !data,
                        message: 'กรุณากรอกวันสิ้นสุด',
                      }
                    ],
                  })(
                    <DatePicker />
                  )}
                  {getFieldDecorator('CircleTax_LimitTimeEnd', {
                    initialValue: moment(get(data, ['CircleTax_LimitTimeEnd'])) || moment(),
                    rules: [
                      {
                        required: !data,
                        message: 'กรุณากรอกเวลาสิ้นสุด',
                      }
                    ],
                  })(
                    <TimePicker defaultPicker={moment()} format={'HH:mm'} />
                  )}
                </Form.Item>
              </>
            )}
            <LongLine />

            <Title level={4}>ภาพรวมระบบ</Title>
            <Form.Item label="สถานะ">
              {getFieldDecorator('OverAll_isActive', {
                valuePropName: 'checked',
                initialValue: get(data, ['OverAll_isActive'], false),
              })(
                <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" />
              )}
            </Form.Item>
            <Form.Item label="กำหนดช่วงเวลาเผยแพร่">
              {getFieldDecorator('OverAll_isLimitTime', {
                valuePropName: 'checked',
                initialValue: get(data, ['OverAll_isLimitTime'], false),
              })(
                <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" />
              )}
            </Form.Item>
            {getFieldValue('OverAll_isLimitTime') && (
              <>
                <Form.Item label="เวลาเริ่มเผยแพร่">
                  {getFieldDecorator('OverAll_LimitTimeStart', {
                    initialValue: moment(get(data, ['OverAll_LimitTimeStart'])) || moment(),
                    rules: [
                      {
                        required: !data,
                        message: 'กรุณากรอกวันที่เริ่ม',
                      }
                    ],
                  })(
                    <DatePicker defaultPicker={moment()} />
                  )}
                  {getFieldDecorator('OverAll_LimitTimeStart', {
                    initialValue: moment(get(data, ['OverAll_LimitTimeStart'])) || moment(),
                    rules: [
                      {
                        required: !data,
                        message: 'กรุณากรอกเวลาเริ่ม',
                      }
                    ],
                  })(
                    <TimePicker defaultPicker={moment()} format={'HH:mm'} />
                  )}
                </Form.Item>
                <Form.Item label="วันเวลาสิ้นสุด">
                  {getFieldDecorator('OverAll_LimitTimeEnd', {
                    initialValue: moment(get(data, ['OverAll_LimitTimeEnd'])) || moment(),
                    rules: [
                      {
                        required: !data,
                        message: 'กรุณากรอกวันสิ้นสุด',
                      }
                    ],
                  })(
                    <DatePicker />
                  )}
                  {getFieldDecorator('OverAll_LimitTimeEnd', {
                    initialValue: moment(get(data, ['OverAll_LimitTimeEnd'])) || moment(),
                    rules: [
                      {
                        required: !data,
                        message: 'กรุณากรอกเวลาสิ้นสุด',
                      }
                    ],
                  })(
                    <TimePicker defaultPicker={moment()} format={'HH:mm'} />
                  )}
                </Form.Item>
              </>
            )}
            <LongLine />

            <Title level={4}>แบบสำรวจความพึงพอใจจากหน่วยงานภายนอก</Title>
            <Form.Item label="สถานะ">
              {getFieldDecorator('ExtAgency_isActive', {
                valuePropName: 'checked',
                initialValue: get(data, ['ExtAgency_isActive'], false),
              })(
                <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" />
              )}
            </Form.Item>
            <Form.Item label="กำหนดช่วงเวลาเผยแพร่">
              {getFieldDecorator('ExtAgency_isLimitTime', {
                valuePropName: 'checked',
                initialValue: get(data, ['ExtAgency_isLimitTime'], false),
              })(
                <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" />
              )}
            </Form.Item>
            {getFieldValue('ExtAgency_isLimitTime') && (
              <>
                <Form.Item label="เวลาเริ่มเผยแพร่">
                  {getFieldDecorator('ExtAgency_LimitTimeStart', {
                    initialValue: moment(get(data, ['ExtAgency_LimitTimeStart'])) || moment(),
                    rules: [
                      {
                        required: !data,
                        message: 'กรุณากรอกวันที่เริ่ม',
                      }
                    ],
                  })(
                    <DatePicker defaultPicker={moment()} />
                  )}
                  {getFieldDecorator('ExtAgency_LimitTimeStart', {
                    initialValue: moment(get(data, ['ExtAgency_LimitTimeStart'])) || moment(),
                    rules: [
                      {
                        required: !data,
                        message: 'กรุณากรอกเวลาเริ่ม',
                      }
                    ],
                  })(
                    <TimePicker defaultPicker={moment()} format={'HH:mm'} />
                  )}
                </Form.Item>
                <Form.Item label="วันเวลาสิ้นสุด">
                  {getFieldDecorator('ExtAgency_LimitTimeEnd', {
                    initialValue: moment(get(data, ['ExtAgency_LimitTimeEnd'])) || moment(),
                    rules: [
                      {
                        required: !data,
                        message: 'กรุณากรอกวันสิ้นสุด',
                      }
                    ],
                  })(
                    <DatePicker />
                  )}

                  {getFieldDecorator('ExtAgency_LimitTimeEnd', {
                    initialValue: moment(get(data, ['ExtAgency_LimitTimeEnd'])) || moment(),
                    rules: [
                      {
                        required: !data,
                        message: 'กรุณากรอกเวลาสิ้นสุด',
                      }
                    ],
                  })(
                    <TimePicker defaultPicker={moment()} format={'HH:mm'} />
                  )}
                </Form.Item>
              </>
            )}
            <Form.Item label="หัวข้อแบบสำรวจ">
              {getFieldDecorator('ExtAgency_Title', {
                initialValue: get(data, ['ExtAgency_Title']),
                rules: [
                  {
                    required: true,
                    message: 'กรุณากรอกหัวข้อ แบบสำรวจ',
                  }
                ],
              })(
                <Input />
              )}
            </Form.Item>
            <Form.Item label="URL แบบสำรวจ">
              {getFieldDecorator('ExtAgency_URL', {
                initialValue: get(data, ['ExtAgency_URL']),
                rules: [
                  {
                    required: true,
                    message: 'กรุณากรอก URL แบบสำรวจ',
                  }
                ],
              })(
                <Input />
              )}
            </Form.Item>

            <LongLine />

          </Spin>
        </Form>
      </CustomModal >
    );
  },
);

export const CustomModal = styled(Modal)`
  #kiosksIds {
    display: inline-block;
  }
  .ant-checkbox-group-item {
    margin: 0;
    width: 50%;
  }
`;