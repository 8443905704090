import React from 'react';
import { Button, Modal, Form, DatePicker } from 'antd';

import moment from 'moment';
import { convertToDatetimeBE, convertToDateBE } from '../../utils';
const { RangePicker } = DatePicker;

export default Form.create({ name: 'time_related_controls' })(
  ({
    onClose,
    visible,
    form: { validateFields, getFieldDecorator },
    handleExcelSummaryDownload,
    confirmLoading,
    title = 'กรุณาเลือก',
    filename = 'report',
  }) => {
    const handleSubmit = (e) => {
      e.preventDefault();

      validateFields((err, fieldsValue) => {
        if (err) {
          return;
        }

        // Should format date value before submit.
        const rangeValue = fieldsValue['range-picker'];
        const values = {
          'start-date': rangeValue[0].format('YYYY-MM-DD'),
          'stop-date': rangeValue[1].format('YYYY-MM-DD'),
        };
        // console.log('Received values of form: ', values);
        handleExcelSummaryDownload(
          // `${filename}-${values['start-date']}-${values['stop-date']}.xlsx`,
          `Export ข้อมูลสรุปการทำรายการ ${convertToDateBE(moment())}.xlsx`,
          values,
        );
      });
    };

    const rangeConfig = {
      rules: [{ type: 'array', required: true, message: 'กรุณาเลือกช่วงเวลา' }],
    };

    return (
      <Modal
        title={title}
        visible={visible}
        onOk={handleSubmit}
        onCancel={onClose}
        confirmLoading={confirmLoading}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item label="เลือกวันที่">
            {getFieldDecorator('range-picker', rangeConfig)(<RangePicker />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  },
);
