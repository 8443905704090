import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { convertToDatetimeBE } from '../../utils'
import { Table, Tag, Button, Icon } from 'antd';

import _ from 'lodash';
import { MainContext } from '../../context/main';

import {
  getFilter,
  getSort,
  getPage,
  getColumnSearchProps,
  perPage,
  defaultPageSize,
  pageSizeOptions,
  showSizeChanger,
} from '../../utils/table';
import { formatPhoneNumber } from '../../utils';
import { HeaderBlock } from '../../style/global';
import FilterModal from '../../component/FilterModal';
import officeCode from '../../utils/staticData/officeCode';
import vehicleTypeData from '../../utils/staticData/vehicleTypeData';
import provinceData from '../../utils/staticData/provinceData';
import Swal from 'sweetalert2';
const searchKey = [
  { label: 'ชื่อ-นามสกุลผู้ใช้งาน', key: 'adminDetail' },
  {
    label: 'หน่วยงาน', key: 'offLocCode', type: 'select2', isFilterByValue: true,
    options: [
      { title: "ทั้งหมด", value: "" },
      ...officeCode.map((o) => ({ title: o.OFF_LOC_DESC, value: o.OFF_LOC_CODE }))
    ]
  },
  { label: 'โปรแกรมที่เข้าใช้', key: 'used_program' },
  {
    label: 'ช่วงวันที่เข้าใช้งาน',
    key: 'date-range',
    type: 'rangepicker',
  },
  { label: 'เลขทะเบียน', key: 'plate', placeholder: 'ตัวอย่าง เช่น กก 1111, 80 1234' },
  {
    label: 'จังหวัดจดทะเบียน', key: 'offlocdesc', type: 'select2',
    options: [
      { title: "ทั้งหมด", value: "" },
      ...provinceData.map((o) => ({ title: o.province, value: o.province }))
    ]
  },
  {
    label: 'ประเภทพรบ.', key: 'plateType', type: 'select2',
    options: [
      { title: "ทั้งหมด", value: "" },
      { title: "รถยนต์", value: "1" },
      { title: "รถขนส่ง", value: "2" },
    ]
  },
  {
    label: 'ประเภทรถ', key: 'vehTypeCode', type: 'select2',
    options: [
      { title: "ทั้งหมด", value: "" },
      ...vehicleTypeData.map((o) => ({ title: o.name, value: o.id }))
    ]
  },

];


const isAllow = (menuPermissions, targetmenuPermissions) => {
  const thisMenuId = "18"
  const permission = _.find(menuPermissions, { menuId: thisMenuId });
  return permission && permission.canManage;
};


export default () => {
  const { request, userProfile } = useContext(MainContext);
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState({});
  const [sorter, setSorter] = useState({
    sort: { key: 'created', desc: true },
  });
  const [pagination, setPagination] = useState({
    // pageSize: perPage,
    defaultPageSize,
    pageSizeOptions,
    showSizeChanger,
  });
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const [showMore, setShowMore] = useState({});
  const truncatedDataLength = 30;
  const columns = [
    {
      title: 'ลำดับ',
      key: 'index',
      dataIndex: 'index',
      width: "5%"
    },
    {
      title: 'ชื่อ-นามสกุลผู้ใช้งาน',
      key: 'adminDetail',
      dataIndex: 'adminDetail',
      render: (adminDetail) => `${adminDetail?.first_name} ${adminDetail?.last_name}`,
      width: "15%"
    },
    {
      title: 'หน่วยงาน',
      key: 'adminDetail2',
      dataIndex: 'adminDetail',
      render: (adminDetail) => `${adminDetail.offLocDesc ?? '-'}`,
      width: "10%"
    },
    {
      title: 'โปรแกรมที่เข้าใช้',
      key: 'used_program',
      dataIndex: 'used_program',
      render: (v) => `${v}`,
      width: "15%"
    },
    {
      title: 'ข้อมูลที่ทำการเพิ่ม/เปลี่ยนแปลง',
      key: 'changed_field',
      dataIndex: 'changed_field',
      render: (text, record) => (
        <div style={{ position: 'relative' }}>
          {showMore[record.id] || text.length <= truncatedDataLength
            ? text
            : `${text.substring(0, truncatedDataLength)}...`}
          {text.length > truncatedDataLength && (
            <Button
              onClick={() => toggleShowMore(record.id)}
              style={{
                position: 'absolute',
                bottom: -15,
                right: -20,
                height: 30,
                border: 'none',
                background: 'none',
                color: '#1890ff',
              }}
            >{showMore[record.id] ? <Icon type="zoom-out" /> : <Icon type="zoom-in" />}
            </Button>
          )}
        </div>
      ),
      width: "10%"
    },
    {
      title: 'การดำเนินการ',
      key: 'action',
      dataIndex: 'action',
      render: (v) => `${v}`,
      width: "10%"
    },
    {
      title: 'ทะเบียนรถ (ถ้ามี)',
      key: 'plate1',
      dataIndex: 'plate1',
      render: (plate1, record) => plate1 ? `${plate1}${isNaN(parseInt(record.typecar)) ? '-' : ' ' + parseInt(record.typecar)}${record.plate2} ${record.offlocdesc} ${isNaN(parseInt(record.typecar)) ? '' : 'รย.' + parseInt(record.typecar)}` : '-',
    },
    {
      title: 'ปัญหาการเข้าใช้งาน/error message(ถ้ามี)',
      key: 'errormessage',
      dataIndex: 'errormessage',
      render: (v) => `${v}`,
    },
    {
      title: 'วันเวลา',
      key: 'created',
      dataIndex: 'created',
      sorter: () => { },
      defaultSortOrder: 'descend',
      width: "12%",
      render: (v) => convertToDatetimeBE(v),
    },

  ];

  const toggleShowMore = (recordId) => {
    setShowMore((prevShowMore) => ({
      ...prevShowMore,
      [recordId]: !prevShowMore[recordId],
    }));
  };


  const fetchData = async (params = {}) => {
    try {
      setLoading(true);
      const res = await request.get('/adminlogs', {
        ...params,
        ...searchData,
      });

      const totalData = _.get(res, ['total_data'], 0);

      setData(_.get(res, ['results'], []));
      setPagination({ ...pagination, total: totalData, current: params.page, pageSize: params.per_page });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (paginationChange, filters, sorterChange) => {
    const pager = { ...pagination };
    pager.current = paginationChange.current;

    setPagination(pager);
    setSorter(getSort(sorterChange));

    fetchData({
      ...getFilter(filters),
      ...getSort(sorterChange),
      ...getPage(paginationChange),
    });
  };

  useEffect(() => {
    fetchData({
      ...getPage(pagination),
      ...sorter,
    });
  }, [searchData]);

  const handleSearch = (value) => {
    pagination.current = 1;
    setSearchData({ ..._.omitBy(value, (o) => _.isNil(o) || _.isEmpty(o)) });

    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOpenModal = () => {
    setVisible(true);
  };
  const handleDownloadWithConfirmation = async () => {
    const confirmResult = await Swal.fire({
      text: 'ยืนยันการออกรายงาน',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'ตกลง',
      cancelButtonText: 'ยกเลิก',
    });
  
    if (confirmResult.isConfirmed) {
      await handleDownload();
    }
  };
  const handleDownload = async (fileName = 'รายงานประวัติการใช้งานระบบบริหารจัดการฯ.xlsx') => {
    if (loading) return;
    try {
      setLoading(true);
      fileName = 'รายงานประวัติการใช้งานระบบบริหารจัดการฯ.xlsx';
      await request.download('/adminlogs/report', fileName, {
        ...getPage(pagination),
        ...sorter,
        ...searchData
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <HeaderBlock style={{ fontSize: '20px', fontWeight: 'bold' }}>
        สอบถามประวัติ (Log) การใช้งานระบบบริหารจัดการ DLT Vehicle Tax Plus (Back Office)
      </HeaderBlock>
      <HeaderBlock>
        <Button type="primary" onClick={handleOpenModal}>
          ค้นหา
        </Button>
        {isAllow(userProfile.menuPermissions) && (
          <div>
            <Button
              style={{ margin: '0 0 0 16px' }}
              icon="export"
              onClick={handleDownload}
            >
              Export
            </Button>
          </div>
        )}
      </HeaderBlock>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 'max-content' }}
      />
      <FilterModal
        visible={visible}
        handleSearch={handleSearch}
        handleCancel={handleCancel}
        renderInputFields={searchKey}
      />
    </>
  );
};


const dataEx = [
  {
    "id": 1,
    "index": 1,
    "userDetail": {
      "id": 1,
      "first_name": "สมชาย",
      "last_name": "ใจดี",
      "OffRegDesc": "ศูนย์เทคโนโลยีสารสนเทศ สำนักงานขนส่งกรุงเทพมหานครพื้นที่ 5",
      "adminGroup": "ผู้ดูแลระบบ",
    },
    "system_name": "รายการชำระภาษี",
    "changed_field": "รูปแบบการจัดส่ง",
    "action": "แก้ไข",
    "plate1": "1กก",
    "plate2": "5210",
    "offlocdesc": "กรุงเทพมหานคร",
    "typecar": "1",
    "errorMassage": "",
    "created": "2023-08-13T00:21:00.000Z",
  },
  {
    "id": 2,
    "index": 2,
    "userDetail": {
      "id": 1,
      "first_name": "สมชาย",
      "last_name": "ใจดี",
      "OffRegDesc": "ศูนย์เทคโนโลยีสารสนเทศ สำนักงานขนส่งกรุงเทพมหานครพื้นที่ 5",
      "adminGroup": "ผู้ดูแลระบบ",
    },
    "system_name": "สื่อออนไลน์",
    "changed_field": "",
    "action": "เพิ่ม",
    "errorMassage": "",
    "created": "2023-08-12T00:24:15.000Z",
  },
  {
    "id": 3,
    "index": 3,
    "userDetail": {
      "id": 2,
      "first_name": "สมรัก",
      "last_name": "สุดใจ",
      "OffRegDesc": "ศูนย์เทคโนโลยีสารสนเทศ สำนักงานขนส่งกรุงเทพมหานครพื้นที่ 3",
      "adminGroup": "ผู้ใช้งานทั่วไป",
    },
    "system_name": "จัดการแจ้งปัญหา",
    "changed_field": "สถานะ",
    "action": "แก้ไข",
    "plate1": "1กก",
    "plate2": "5640",
    "offlocdesc": "กรุงเทพมหานคร",
    "typecar": "1",
    "errorMassage": "",
    "created": "2023-08-11T08:45:30.000Z",
  },
  {
    "id": 4,
    "index": 4,
    "userDetail": {
      "id": 3,
      "first_name": "สมศรี",
      "last_name": "วิสุทธิ์",
      "OffRegDesc": "ศูนย์เทคโนโลยีสารสนเทศ สำนักงานขนส่งกรุงเทพมหานครพื้นที่ 2",
      "adminGroup": "ผู้ใช้งานทั่วไป",
    },
    "system_name": "จัดการแจ้งปัญหา",
    "changed_field": "สถานะ",
    "action": "แก้ไข",
    "plate1": "1กก",
    "plate2": "2140",
    "offlocdesc": "กรุงเทพมหานคร",
    "typecar": "1",
    "errorMassage": "",
    "created": "2023-08-10T15:32:45.000Z",
  },
  {
    "id": 5,
    "index": 5,
    "userDetail": {
      "id": 4,
      "first_name": "จิรพงษ์",
      "last_name": "สุขสวัสดิ์",
      "OffRegDesc": "ศูนย์เทคโนโลยีสารสนเทศ สำนักงานขนส่งกรุงเทพมหานครพื้นที่ 4",
      "adminGroup": "ผู้ดูแลระบบ",
    },
    "system_name": "จัดการคู่มือการใช้งานระบบ - หมวดหมู่",
    "changed_field": "",
    "action": "เพิ่ม",
    "errorMassage": "",
    "created": "2023-08-09T09:15:20.000Z",
  },
  {
    "id": 6,
    "index": 6,
    "userDetail": {
      "id": 4,
      "first_name": "จิรพงษ์",
      "last_name": "สุขสวัสดิ์",
      "OffRegDesc": "ศูนย์เทคโนโลยีสารสนเทศ สำนักงานขนส่งกรุงเทพมหานครพื้นที่ 4",
      "adminGroup": "ผู้ดูแลระบบ",
    },
    "system_name": "จัดการคู่มือการใช้งานระบบ - หมวดหมู่",
    "changed_field": "",
    "action": "เพิ่ม",
    "errorMassage": "",
    "created": "2023-08-09T08:25:20.000Z",
  },
]
