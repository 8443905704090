import { compose, withProps } from 'recompose';
import React, { useEffect, useState } from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';

export default compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_KEY}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '400px' }} />,
    mapElement: <div style={{ height: '100%' }} />,
  }),
  withScriptjs,
  withGoogleMap,
)(({ location: { lat, lng }, setLocation }) => {
  const onMapClick = (evt) => {
    const newLat = evt.latLng.lat();
    const newLng = evt.latLng.lng();
    setLocation({ lat: newLat, lng: newLng });
  };

  return (
    <GoogleMap
      defaultZoom={16}
      defaultCenter={{ lat, lng }}
      defaultOptions={{
        scrollwheel: false,
        disableDefaultUI: true,
        zoomControl: true,
      }}
      onClick={onMapClick}
    >
      {lat && lng && <Marker position={{ lat, lng }} />}
    </GoogleMap>
  );
});
