import React, { createContext, useState, useRef, useEffect } from 'react';
import io from 'socket.io-client';
import Swal from 'sweetalert2';

const SocketContext = createContext();
const socket = io(process.env.REACT_APP_SOCKET_URL, {
  path: process.env.REACT_APP_SOCKET_PATH,
  transportOptions: {
    polling: {
      extraHeaders: {
        Authorization: `Bearer ${process.env.REACT_APP_SOCKET_KEY}`,
      },
    },
  },
});

const showAlert = () => {
  Swal.fire({
    title: "ไม่สามารถเชื่อมต่อกับระบบ Socket Server ได้",
    text: "กรุณาตรวจสอบการเชื่อมต่อกับ Socket Server",
    icon: "warning",
    button: "ปิด",
    width: 800,
  });
};

const SocketProvider = ({ children }) => {
  const [connected, setConnected] = useState(false);
  const [kiosks, setKiosks] = useState([]);

  useEffect(() => {
    socket.on('connect', function () {
      console.log('onConnect');
      setConnected(true);
    });

    const onDisconnect = socket.on('disconnect', function () {
      console.log('disconnect');
      setConnected(false);
    });

    socket.on('kiosks', (data) => {
      console.log('k =>', { data });
      setKiosks(data);
    });

  }, []);

  // useEffect that will run every 1 second for checking socket connection
  let attemptfail = 0;
  useEffect(() => {
    const interval = setInterval(() => {
      if (connected === false) {
        attemptfail++;
        if (attemptfail === 10) {
          // showAlert();
        }

      } else {
        attemptfail = 0;
      }
      // console.log('attemptfail =>', attemptfail);
      // console.log('connected =>', connected);
    }, 1000);

    return () => clearInterval(interval);
  }, [connected]);

  return (
    <SocketContext.Provider value={{ socket, isConnect: connected, kiosks }}>
      {children}
    </SocketContext.Provider>
  );
};

export { SocketContext, SocketProvider };
