import React, { useState } from 'react';
import { Modal, Button, List, Result, Spin } from 'antd';
import _ from 'lodash';

import moment from 'moment';

const perPage = 1000;

export default ({
  visible,
  handleOk,
  handleCancel,
  handleDownload,
  total,
  title = 'ดาวน์โหลดไฟล์',
  isCarmanual = false,
}) => {
  const [downloadList, setDownLoadList] = useState([]);
  const page = _.ceil(total / perPage);
  const handleClickDownload = (data) => async (e) => {
    e.preventDefault();
    setDownLoadList([...new Set([...downloadList, data.filename])]);
    // console.log('object', { data });
    const result = await handleDownload(data.filename, data.page, perPage);

    setDownLoadList([
      ...new Set(_.filter(downloadList, (s) => s === data.filename)),
    ]);
  };
  return (
    <Modal
      visible={visible}
      title={title}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          ปิด
        </Button>,
      ]}
    >
      <List
        className="demo-loadmore-list"
        itemLayout="horizontal"
        dataSource={_.range(1, page + 1)}
        renderItem={(o, index) => {
          const start = index * perPage + 1;
          let stop = o * perPage;
          const countRow = total;

          if (stop > total) stop = total;

          // const fileName = `report${o}_(${start}-${stop}/${countRow}).xlsx`;
          // const fileName = `Export ${isCarmanual ? 'รายการคำขอใบรับรองจดทะเบียนรถรูปแบบอิเล็กทรอนิกส์' : 'ข้อมูลการทำรายการ'} ${moment().locale('en').add(543, 'year').format("D MMM YYYY")}.xlsx`;
          const fileName = `${isCarmanual ? 'รายการคำขอใบรับรองจดทะเบียนรถรูปแบบอิเล็กทรอนิกส์' : 'ข้อมูลการทำรายการ'} ${start}-${stop}.xlsx`;
          const isLoading = _.includes(downloadList, fileName);

          return (
            <List.Item
              actions={[
                <Button
                  loading={isLoading}
                  key="list-loadmore-more"
                  // href="e"
                  onClick={handleClickDownload({
                    filename: fileName,
                    page: o,
                  })}
                >
                  ดาวน์โหลด
                </Button>,
              ]}
            >
              {/* {`report${o}_(${start}-${stop}/${countRow}).xlsx`} */}
              {/* {`Export ${isCarmanual ? 'รายการคำขอใบรับรองจดทะเบียนรถรูปแบบอิเล็กทรอนิกส์' : 'ข้อมูลการทำรายการ'} ${moment().locale('en').add(543, 'year').format("D MMM YYYY")}.xlsx`} */}
              {`${index+1}. Export ${isCarmanual ? 'รายการคำขอใบรับรองจดทะเบียนรถรูปแบบอิเล็กทรอนิกส์' : 'ข้อมูลการทำรายการ'} ${start}-${stop}.xlsx`}
            </List.Item>
          );
        }}
      />
      {/* {_.map(_.range(1, page + 1), (o, index) => {
        const start = index * 1000 + 1;
        let stop = o * 1000;
        if (o === page) {
          stop = total;
        }
        return <p>{`${start} - ${stop}`}</p>;
      })} */}
    </Modal>
  );
};
