import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { convertToDatetimeBE, convertToDatetimeFullBE } from '../../utils';
import { Table, Tag, Button, Icon, Divider, Popconfirm, Popover, Spin } from 'antd';
import { TagContainer, ButtonTag } from './style';
import _ from 'lodash';
import { MainContext } from '../../context/main';

import {
  getFilter,
  getSort,
  getPage,
  getColumnSearchProps,
  perPage,
  defaultPageSize,
  pageSizeOptions,
  showSizeChanger,

} from '../../utils/table';
import { formatPhoneNumber } from '../../utils';
import { HeaderBlock } from '../../style/global';
import FilterModal from '../../component/FilterModal';
import FormModal from './form';
const searchKey = [
  { label: 'ชื่อหัวข้อนโยบาย', key: 'title' },
  {
    label: 'สถานะ',
    key: 'isActive',
    type: 'select',
    options: [
      { title: 'ไม่ระบุ', value: '' },
      { title: 'เปิดใช้งาน', value: 'true' },
      { title: 'ปิดใช้งาน', value: 'false' },
    ],
  },
];

const isAllow = (menuPermissions, targetmenuPermissions) => {
  const thisMenuId = "12"
  const permission = _.find(menuPermissions, { menuId: thisMenuId });
  return permission && permission.canManage;
};

function getColor(status) {
  switch (status) {
    case true:
      return 'green';
    case false:
      return 'orange';
    default:
      return '';
  }
}

function getWord(status) {
  switch (status) {
    case true:
      return 'เปิดใช้งาน';
    case false:
      return 'ปิดใช้งาน';
    default:
      return 'ไม่พร้อมใช้งาน';
  }
}
const PopOverTag = ({ ToS, onStatusChange }) => {
  const currentStatus = _.get(ToS, ['isActive']);
  const color = getColor(currentStatus);
  const [visible, setVisible] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const { request } = useContext(MainContext);

  const onVisibleChange = (e) => {
    setVisible((prevState) => !prevState);
  };

  const handleSelect = (newStatus) => async (e) => {
    if (newStatus === currentStatus) return;

    const body = {
      isActive: newStatus,
    };
    setIsLoading(true);

    const res = await request.put(`/toss/${ToS.id}/isActive`, body);

    setIsLoading(false);

    if (_.get(res, ['success'])) {
      onStatusChange({
        id: ToS.id,
        newStatus,
      });
    }

    onVisibleChange();
  };

  return (
    <Popover
      content={(
        <Spin spinning={isLoading}>
          <TagContainer>
            <ButtonTag underline onClick={handleSelect(true)}>
              <Tag color={currentStatus === true ? 'grey' : 'green'}>
                {getWord(true)}
              </Tag>
            </ButtonTag>
            <ButtonTag underline onClick={handleSelect(false)}>
              <Tag color={currentStatus === false ? 'grey' : 'orange'}>
                {getWord(false)}
              </Tag>
            </ButtonTag>
          </TagContainer>
        </Spin>
      )}
      title="สถานะการเปิดใช้งาน"
      trigger="click"
      visible={visible}
      onVisibleChange={onVisibleChange}
    >
      <ButtonTag>
        <Tag color={color}>
          {' '}
          {getWord(currentStatus)}
        </Tag>
      </ButtonTag>
    </Popover>
  );
};
export default () => {
  const { request, userProfile } = useContext(MainContext);
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState({});
  const [sorter, setSorter] = useState({
    sort: { key: 'created', desc: false },
  });
  const [pagination, setPagination] = useState({
    // pageSize: perPage,
    defaultPageSize,
    pageSizeOptions,
    showSizeChanger,
  });
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [targetToS, setTargetToS] = useState(null);


  const fetchData = async (params = {}) => {
    try {
      setLoading(true);
      const res = await request.get('/toss', {
        ...params,
        ...searchData,
      });

      const totalData = _.get(res, ['total_data'], 0);

      setData(_.get(res, ['results'], []));
      setPagination({ ...pagination, total: totalData, current: params.page, pageSize: params.per_page });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (ToSId) => async () => {
    try {
      setLoading(true);
      const res = await request.delete(`/toss/${ToSId}`);

      if (_.get(res, ['success'])) {
        fetchData();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (paginationChange, filters, sorterChange) => {
    const pager = { ...pagination };
    pager.current = paginationChange.current;

    setPagination(pager);
    setSorter(getSort(sorterChange));

    fetchData({
      ...getFilter(filters),
      ...getSort(sorterChange),
      ...getPage(paginationChange),
    });
  };

  useEffect(() => {
    fetchData({
      ...getPage(pagination),
      ...sorter,
    });
  }, [searchData]);

  const handleSearch = (value) => {
    pagination.current = 1;
    setSearchData({ ..._.omitBy(value, (o) => _.isNil(o) || _.isEmpty(o)) });

    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOpenModal = () => {
    setVisible(true);
  };

  const handleOpenModalForm = (ToS) => (e) => {
    setTargetToS(ToS);
    setFormVisible(true);
    // console.log(ToS);
  };

  const handleCloseModalForm = () => {
    setTargetToS(null);
    setFormVisible(false);
  };
  const onStatusChange = ({ newStatus, id }) => {
    setData((prevData) => {
      const updatedData = prevData.map((o) => {
        if (o.id === id) {
          o.isActive = newStatus;
        } else if (newStatus === true) {
          o.isActive = false; // Set isActive to false for all other items if newStatus is true
        }
        return o;
      });
  
      return updatedData;
    });
  };
  

  const columns = [
    {
      title: 'ลำดับ',
      key: 'index',
      dataIndex: 'index',
    },
    {
      title: 'ชื่อหัวข้อนโยบาย',
      key: 'title',
      render: (record) => `${record.title}`,
    },
    {
      title: 'สถานะ',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (status, o) => (
        isAllow(userProfile.menuPermissions) ? (
          <PopOverTag onStatusChange={onStatusChange} ToS={o} />
        ) : (
          <Tag color={status ? 'green' : 'orange'}>{status ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}</Tag>
        )
      ),
    },
    {
      title: 'วันเวลาที่เริ่มต้นประกาศ',
      key: 'period',
      render: (record) => convertToDatetimeBE(record.periodStart),
    },
    {
      title: 'วันเวลาที่สิ้นสุดประกาศ',
      key: 'period',
      render: (record) => convertToDatetimeBE(record.periodEnd),
    },
    {
      title: isAllow(userProfile.menuPermissions) ? 'จัดการ' : '',
      width: isAllow(userProfile.menuPermissions) ? '10%' : '0px',
      key: 'action',
      render: (o) =>
        isAllow(userProfile.menuPermissions) ? (
          <span>
            <a
              onClick={
                isAllow(userProfile.menuPermissions, o.menuPermissions)
                  ? handleOpenModalForm(o)
                  : () => { }
              }
            >
              แก้ไข
            </a>
            <Divider type="vertical" />
            <Popconfirm
              key="delete"
              title="กรุณายืนยันการลบ ประกาศนโยบายความเป็นส่วนตัว ดังกล่าว"
              onConfirm={handleDelete(_.get(o, ['id']))}
              okText="ตกลง"
              cancelText="ยกเลิก"
            >
              <a>ลบ</a>
            </Popconfirm>
          </span>
        ) : null,
    },
  ];

  const onSubmit = async ({
    title,
    description,
    isActive,
    periodDateStart,
    periodTimeStart,
    periodDateEnd,
    periodTimeEnd,
    attachment,
    isUseAttachment,
    pdfLink,
  }) => {
    setFormLoading(true);
    let body = {};
    let res;
    try {
      if (targetToS) {
        body.ToSId = _.get(targetToS, ['id']);
        if (targetToS.title !== title)
          body.title = title;
        if (targetToS.description !== description)
          body.description = description;
        if (targetToS.isActive !== isActive)
          body.isActive = isActive;
        // if (targetToS.periodDateStart !== periodDateStart)
        body.periodDateStart = periodDateStart;
        // if (targetToS.periodTimeStart !== periodTimeStart)
        body.periodTimeStart = periodTimeStart;
        // if (targetToS.periodDateEnd !== periodDateEnd)
        body.periodDateEnd = periodDateEnd;
        // if (targetToS.periodTimeEnd !== periodTimeEnd)
        body.periodTimeEnd = periodTimeEnd;
        // console.log(targetToS);
        // console.log(attachment);
        if (typeof attachment !== 'boolean') {
          body.attachment = attachment;
        }
        body.isUseAttachment = isUseAttachment;
        body.pdfLink = pdfLink;
        res = await request.put('/toss', body);
      } else {
        body = {
          title,
          description,
          isActive,
          periodDateStart,
          periodTimeStart,
          periodDateEnd,
          periodTimeEnd,
          attachment,
          isUseAttachment,
          pdfLink,
        };
        res = await request.post('/toss', body);
      }

      if (_.get(res, ['success'])) {
        handleCloseModalForm();
        fetchData({
          ...getPage(pagination),
          ...sorter,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setFormLoading(false);
    }
  };

  return (
    <>
      <HeaderBlock style={{ fontSize: '20px', fontWeight: 'bold' }}>
        บันทึก/แก้ไขข้อความของประกาศนโยบายความเป็นส่วนตัว (Privacy Notice)
      </HeaderBlock>
      <HeaderBlock>
        <Button type="primary" onClick={handleOpenModal}>
          ค้นหา
        </Button>
        {isAllow(userProfile.menuPermissions) && (
          <Button type="primary" onClick={handleOpenModalForm(null)}>
            เพิ่ม
          </Button>
        )}
      </HeaderBlock>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 'max-content' }}
      />
      <FilterModal
        visible={visible}
        handleSearch={handleSearch}
        handleCancel={handleCancel}
        renderInputFields={searchKey}
      />
      <FormModal
        onSubmit={onSubmit}
        formLoading={formLoading}
        data={targetToS}
        visible={formVisible}
        onCancel={handleCloseModalForm}
      ></FormModal>
    </>
  );
};
