import styled from 'styled-components';

export const TagContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ButtonTag = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => (props.underline ? 'center' : 'flex-start')};
  background-color: #0000;
  width: ${(props) => (props.underline ? '100%' : 'auto')};
  padding: 5px;
  border: solid 1px #0000;
  border-bottom: solid 1px ${(props) => (props.underline ? '#0001' : '#0000')};
  cursor: pointer;

  span {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
`;
