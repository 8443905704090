import React, { useEffect, useState, useContext } from 'react';
import {
  Modal,
  Form,
  Select,
  DatePicker,
  Input,
  Button,
  Spin,
  Card,
  Checkbox
} from 'antd';
import _ from 'lodash';
import get from 'lodash/get';
import { isPassword } from '../../utils/form';
import { MainContext } from '../../context/main';
import styled from 'styled-components';

const { Option } = Select;

export default Form.create()(
  ({
    visible,
    onSubmit = () => {},
    onCancel = () => {},
    form: { getFieldDecorator, validateFields, resetFields, getFieldValue },
    data,
    formLoading,
    kioskList
  }) => {
    const [confirmDirty, setConfirmDirty] = useState(false);
    const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const { request, userProfile } = useContext(MainContext);

    const handleResetPassword = async () => {
      const id = get(data, ['id']);
      if (!id) return;
      setResetPasswordLoading(true);
      const res = await request.get(`/auth/reset_password/${id}`);
      const newUserPassword = get(res, ['result', 'new_password']);

      setNewPassword(newUserPassword);
      setResetPasswordLoading(false);
    };

    const handleSubmit = (e) => {
      e.preventDefault();

      validateFields((err, fieldsValue) => {
        if (err) {
          return;
        }
        fieldsValue.isAllowViewMobileTransactions = fieldsValue.isAllowViewMobileTransactions === "true" ? true : false
        console.log({ fieldsValue });
        onSubmit(_.omit(fieldsValue));
      });
    };

    useEffect(() => {
      if (!visible) {
        resetFields();
      }
    }, [visible]);

    const handleConfirmBlur = (e) => {
      const { value } = e.target;
      setConfirmDirty(confirmDirty || !!value);
    };

    const compareToFirstPassword = (rule, value, callback) => {
      if (value && value !== getFieldValue('password')) {
        return callback('รหัสผ่านใหม่ไม่ตรงกัน');
      }

      if (!isPassword.test(value))
        return callback(
          'รหัสผ่าน ต้องไม่น้อยกว่า 8 ตัวอักษร ประกอบด้วยตัวใหญ่, ตัวเล็ก และตัวเลข',
        );

      callback();
    };

    const validateToNextPassword = (rule, value, callback) => {
      if (value && confirmDirty) {
        validateFields(['confirm'], { force: true });
        return callback();
      }

      if (!isPassword.test(value))
        return callback(
          'รหัสผ่าน ต้องไม่น้อยกว่า 8 ตัวอักษร ประกอบด้วยตัวใหญ่, ตัวเล็ก และตัวเลข',
        );

      callback();
    };

    return (
      <CustomModal
        title={data ? 'แก้ไข' : 'เพิ่ม'}
        visible={visible}
        style={{ minWidth: '800px' }}
        onCancel={onCancel}
        footer={[
          <Button key="back" onClick={onCancel}>
            ยกเลิก
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit}>
            บันทึก
          </Button>,
        ]}
      >
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>
          <Spin spinning={formLoading}>
            <Form.Item label="ชื่อบัญชีผู้ใช้งาน">
              {getFieldDecorator('username', {
                initialValue: get(data, ['username']),
                rules: [
                  {
                    required: !data,
                    message: 'กรุณากรอกชื่อบัญชีผู้ใช้งาน',
                  },
                ],
              })(<Input disabled={!!get(data, ['username'])}></Input>)}
            </Form.Item>
            <Form.Item label="ชื่อ">
              {getFieldDecorator('first_name', {
                initialValue: get(data, ['first_name']),
                rules: [
                  {
                    required: !data,
                    message: 'กรุณากรอกชื่อ',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="นามสกุล">
              {getFieldDecorator('last_name', {
                initialValue: get(data, ['last_name']),
                rules: [
                  {
                    required: !data,
                    message: 'กรุณากรอกนามสกุล',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="ระดับสิทธิ์การใช้งาน" hasFeedback>
              {getFieldDecorator('role', {
                initialValue: get(data, ['role'], 'admin'),
              })(
                <Select
                  disabled={
                    !!data || _.get(userProfile, ['role']) !== 'super_admin'
                  }
                  placeholder="กรุณากำหนดระดับสิทธิ์การใช้งาน"
                >
                  <Option value="admin">ผู้ดูแลระบบ</Option>
                  <Option value="super_admin">ผู้ดูแลระบบระดับสูง</Option>
                </Select>,
              )}
            </Form.Item>
            {
            get(data, ['role']) == "admin" && <Form.Item label="การเข้าถึงข้อมูล Mobile">
              {getFieldDecorator('isAllowViewMobileTransactions'.toString(), {
                initialValue: get(data, ['isAllowViewMobileTransactions']).toString(),
              })(
                <Select
                  placeholder="กรุณาเลือก"
                >
                  <Option value="true">อนุญาต</Option>
                  <Option value="false">ไม่อนุญาต</Option>
                </Select>,
              )}
            </Form.Item>
            }
            {
              get(data, ['role']) == "admin" &&
              <Form.Item label="การเข้าถึงข้อมูล Kiosk">
                {getFieldDecorator('kiosksIds', {
                  initialValue: data.kiosksIds.map((item) => {
                    return (
                      item._id
                    )
                  }),
                  valuePropName: 'value',
                  rules: [{
                    type: 'array'
                  }]
                })(
                  <Checkbox.Group options={
                    kioskList.map((item, index) => {
                      return ({
                        label: item.detail,
                        value: item.id
                      })
                    })
                  }
                  />
                )}
              </Form.Item>
            }
            {!data ? (
              <>
                <Form.Item
                  help="รหัสผ่าน ต้องไม่น้อยกว่า 8 ตัวอักษร ประกอบด้วยตัวใหญ่, ตัวเล็ก และตัวเลข"
                  label="รหัสผ่าน"
                  hasFeedback
                >
                  {getFieldDecorator('password', {
                    // validateTrigger: null,
                    rules: [
                      {
                        required: true,
                        message: 'กรุณากรอกรหัสผ่าน',
                      },
                      {
                        validator: validateToNextPassword,
                      },
                    ],
                  })(<Input.Password />)}
                </Form.Item>
                <Form.Item
                  // help="รหัสผ่าน ต้องไม่น้อยกว่า 8 ตัวอักษร ประกอบด้วยตัวใหญ่, ตัวเล็ก และตัวเลข"
                  label="ยืนยัน รหัสผ่าน"
                  hasFeedback
                >
                  {getFieldDecorator('confirm', {
                    // validateTrigger: null,
                    rules: [
                      {
                        required: true,
                        message: 'กรุณากรอกรหัสผ่านอีกครั้ง',
                      },
                      {
                        validator: compareToFirstPassword,
                      },
                    ],
                  })(<Input.Password onBlur={handleConfirmBlur} />)}
                </Form.Item>
              </>
            ) : (
              <>
                <Spin spinning={resetPasswordLoading}>
                  <Card title="รหัสผ่านใหม่">
                    <h1>{newPassword}</h1>
                  </Card>
                </Spin>
                <br />
                <Button type="danger" onClick={handleResetPassword}>
                  รีเซ็ตรหัสผ่าน
                </Button>
              </>
            )}
          </Spin>
        </Form>
      </CustomModal>
    );
  },
);

export const CustomModal = styled(Modal)`
  #kiosksIds {
    display: inline-block;
  }
  .ant-checkbox-group-item {
    margin: 0;
    width: 50%;
  }
`;