import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { convertToDatetimeBE } from '../../utils'
import { Table, Tag, Button, Icon, Divider, Popconfirm, Popover, Spin } from 'antd';
import { TagContainer, ButtonTag } from './style';
import _ from 'lodash';
import { MainContext } from '../../context/main';

import {
  getFilter,
  getSort,
  getPage,
  getColumnSearchProps,
  perPage,
  defaultPageSize,
  pageSizeOptions,
  showSizeChanger,

} from '../../utils/table';
import { HeaderBlock } from '../../style/global';
import FilterModal from '../../component/FilterModal';
import FormModal from './form';
import SettingFromModal from './settingform';
import vehicleTypeData from '../../utils/staticData/vehicleTypeData';
import provinceData from '../../utils/staticData/provinceData';

const isAllow = (menuPermissions, targetmenuPermissions) => {
  const thisMenuId = "5"
  const permission = _.find(menuPermissions, { menuId: thisMenuId });
  return permission && permission.canManage;
};


export default () => {
  const { request, userProfile } = useContext(MainContext);
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState({});
  const [sorter, setSorter] = useState({
    sort: { key: 'created', desc: true },
  });
  const [pagination, setPagination] = useState({
    pageSize: 6,
    defaultPageSize: 6,
    pageSizeOptions,
    showSizeChanger,
  });
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [targetUsersreport, setTargetUsersreport] = useState(null);

  const fetchData = async (params = {}) => {
    try {
      setLoading(true);
      const res = await request.get('/usersreport', {
        ...params,
        ...searchData,
      });

      const totalData = _.get(res, ['total_data'], 0);

      setData(_.get(res, ['results'], []));
      setPagination({ ...pagination, total: totalData, current: params.page, pageSize: params.per_page });
      console.log(res);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };



  const fetchCategory = async () => {
    try {
      const response = await request.get('/usersreport/setting');
      const data = response.results;

      // Update the options array with the fetched data
      const updatedOptions = [
        { title: 'ทั้งหมด', value: '' },
        ...data.map(item => ({ title: item.title, value: item.title })),
      ];

      // Update the searchKey object with the new options array
      setSearchKey(prevSearchKey => ({
        ...prevSearchKey,
        0: {
          ...prevSearchKey[0],
          options: updatedOptions,
        },
      }));
    } catch (error) {
      console.error('Error fetching Category:', error);
      // Handle the error case
    }
  };

  const [searchKey, setSearchKey] = useState([
    {
      label: 'รายการปัญหา', key: 'TitleReport', type: 'select',
      options: [], // Initially empty
    },
    {
      label: 'วันเวลาได้รับปัญหา',
      key: 'date-range',
      type: 'rangepicker',
    },
    {
      label: 'ชื่อ-นามสกุลผู้แจ้งปัญหา', key: 'ReporterName'
    },
    { label: 'เลขทะเบียน', key: 'plate', placeholder: 'ตัวอย่าง เช่น กก 1111, 80 1234' },
    {
      label: 'จังหวัดจดทะเบียน', key: 'offRegDesc', type: 'select2',
      options: [
        { title: "ทั้งหมด", value: "" },
        ...provinceData.map((o) => ({ title: o.province, value: o.province }))
      ]
    },

    {
      label: 'สถานะ', key: 'StatusReport', type: 'select2',
      options: [
        { title: "ทั้งหมด", value: "" },
        { title: "รอดำเนินการ", value: "waiting" },
        { title: "ดำเนินการเรียบร้อย", value: "done" },
      ]
    },
    {
      label: 'ประเภทพรบ.', key: 'plateType', type: 'select2',
      options: [
        { title: "ทั้งหมด", value: "" },
        { title: "รถยนต์", value: "1" },
        { title: "รถขนส่ง", value: "2" },
      ]
    },
    {
      label: 'ประเภทรถ', key: 'vehTypeCode', type: 'select2',
      options: [
        { title: "ทั้งหมด", value: "" },
        ...vehicleTypeData.map((o) => ({ title: o.name, value: o.id }))
      ]
    },
  ]);

  useEffect(() => {
    if (visible) {
      fetchCategory();
    }
  }, [visible]);

  const handleDelete = (usersreportId) => async () => {
    try {
      setLoading(true);
      const res = await request.delete(`/usersreport/${usersreportId}`);

      if (_.get(res, ['success'])) {
        fetchData();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (paginationChange, filters, sorterChange) => {
    const pager = { ...pagination };
    pager.current = paginationChange.current;

    setPagination(pager);
    setSorter(getSort(sorterChange));

    fetchData({
      ...getFilter(filters),
      ...getSort(sorterChange),
      ...getPage(paginationChange),
    });
  };

  useEffect(() => {
    fetchData({
      ...getPage(pagination),
      ...sorter,
    });
  }, [searchData]);

  const handleSearch = (value) => {
    pagination.current = 1;
    setSearchData({ ..._.omitBy(value, (o) => _.isNil(o) || _.isEmpty(o)) });

    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOpenModal = () => {
    setVisible(true);
  };

  const handleOpenModalForm = (usersreport) => (e) => {
    setTargetUsersreport(usersreport);
    setFormVisible(true);
    // console.log(usersreport);
  };

  const handleCloseModalForm = () => {
    setTargetUsersreport(null);
    setFormVisible(false);
  };

  const columns = [
    {
      title: 'ลำดับ',
      key: 'index',
      dataIndex: 'index',
      width: "70px"
    },
    // {
    //   title: 'รหัสอ้างอิง',
    //   key: 'RefCode',
    //   render: (record) => `${record.RefCode}`,
    // },
    {
      title: 'ชื่อ-นามสกุลผู้ใช้งาน',
      width: '12%',
      key: 'Reporter',
      render: (record) => `${record.ReporterName}`,

    },
    {
      title: 'รายการปัญหา',
      width: '12%',
      key: 'TitleReport',
      render: (record) => `${record.TitleReport}`,
    },
    {
      title: 'ทะเบียนรถ',
      width: '10%',
      key: 'Plate1',
      render: (record) => `${record.Plate1 ? record.Plate1 : ""}${record.TypeCar ? ' ' : '-'}${record.Plate2 ? record.Plate2 : ""} ${record.OffLocDesc ? record.OffLocDesc : ""} ${record.TypeCar ? "รย." + record.TypeCar : ''}`,
    },
    {
      title: 'รายละเอียด',
      width: '15%',
      key: 'DetailReport',
      render: (record) => {
        const maxLength = 50; // You can change this value to your desired maximum length
        const text = record.DetailReport;

        if (text.length > maxLength) {
          return `${text.substring(0, maxLength)}...`;
        }

        return text;
      },
    },
    {
      title: 'วันเวลาได้รับปัญหา',
      width: '10%',
      key: 'created',
      dataIndex: 'created',
      sorter: () => { },
      render: (v) => convertToDatetimeBE(v),
    },
    {
      title: 'วันเวลาตอบปัญหา',
      width: '10%',
      key: 'updated',
      dataIndex: 'updated',
      sorter: () => { },
      render: (v, o) => {
        if (v <= o.created) {
          // center a text in a column
          return <div style={{ textAlign: 'center' }}>-</div>;
        }
        return convertToDatetimeBE(v);
      }
    },
    {
      title: 'สถานะ',
      width: '7%',
      dataIndex: 'StatusReport',
      key: 'StatusReport',
      render: (status, o) => {
        let statusText = "รอดำเนินการ";
        let statusColor = "orange";
        if (status === "done") {
          statusText = "ดำเนินการเรียบร้อย";
          statusColor = "green";
        }
        return <Tag color={statusColor}>{statusText}</Tag>;
      },
    },
    {
      title: 'ชื่อ-นามสกุลผู้ตอบปัญหา',
      dataIndex: 'ReplybyName',
      key: 'ReplybyName',
      width: '15%',
    },
    {
      title: isAllow(userProfile.menuPermissions) ? 'จัดการ' : '',
      width: isAllow(userProfile.menuPermissions) ? '10%' : '0px',
      key: 'action',
      render: (o) =>
        isAllow(userProfile.menuPermissions) ? (
          <span style={{ fontSize: '12px' }}>
            <a
              onClick={
                isAllow(userProfile.menuPermissions, o.menuPermissions)
                  ? handleOpenModalForm(o)
                  : () => { }
              }
            >
              รายละเอียด/ตอบปัญหา
            </a>
            {/* <Divider type="vertical" />
            <Popconfirm
              key="delete"
              title="กรุณายืนยันการลบ Usersreport ดังกล่าว"
              onConfirm={handleDelete(_.get(o, ['id']))}
              okText="ตกลง"
              cancelText="ยกเลิก"
            >
              <a>ลบ</a>
            </Popconfirm> */}
          </span>
        ) : null,
    }

  ];

  const onSubmit = async ({
    RefCode,
    Reporter,
    TitleReport,
    DetailReport,
    StatusReport,
    DetailReply,
  }) => {
    setFormLoading(true);
    let body = {};
    let res;
    try {
      if (targetUsersreport) {
        body.usersreportId = _.get(targetUsersreport, ['id']);
        if (targetUsersreport.StatusReport !== StatusReport)
          body.StatusReport = StatusReport;
        if (targetUsersreport.DetailReply !== DetailReply)
          body.DetailReply = DetailReply;
        // console.log(targetUsersreport)
        res = await request.put('/usersreport', body);
      } else {
        body = {
          RefCode,
          Reporter,
          TitleReport,
          DetailReport,
          StatusReport,
          DetailReply,
        };
        res = await request.post('/usersreport', body);
      }

      if (_.get(res, ['success'])) {
        handleCloseModalForm();
        fetchData({
          ...getPage(pagination),
          ...sorter,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setFormLoading(false);
    }
  };

  // Modal Setting
  const [visibleSettingModal, setVisibleSettingModal] = useState(false);
  const [settingModalData, setSettingModalData] = useState(null);
  const [settingModalLoading, setSettingModalLoading] = useState(false);

  const fetchSettingModalData = async () => {
    try {
      setSettingModalLoading(true);
      const res = await request.get('/usersreport/setting');
      const data = res.results;
      setSettingModalData(data);
    } catch (error) {
      console.error(error);
    } finally {
      setSettingModalLoading(false);
    }
  };

  const handleOpenModalSetting = () => {
    setVisibleSettingModal(true);
  }

  const handleCloseModalSetting = () => {
    setVisibleSettingModal(false);
  }

  useEffect(() => {
    if (visibleSettingModal) {
      fetchSettingModalData();
    }
  }, [visibleSettingModal]);

  const handleDownload = async (fileName = 'รายงานการจัดการแจ้งปัญหา.xlsx') => {
    if (loading) return;
    setLoading(true);
    try {
      fileName = 'รายงานการจัดการแจ้งปัญหา.xlsx';
      await request.download('/usersreport/report', fileName, {
        ...getPage(pagination),
        ...sorter,
        ...searchData
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadSummary = async (fileName = 'รายงานสรุปการจัดการแจ้งปัญหา.xlsx') => {
    if (loading) return;
    setLoading(true);
    try {
      fileName = 'รายงานสรุปการจัดการแจ้งปัญหา.xlsx';
      await request.download('/usersreport/reportSummary', fileName, {
        ...getPage(pagination),
        ...sorter,
        ...searchData
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };



  return (
    <>
      <HeaderBlock style={{ fontSize: '20px', fontWeight: 'bold' }}>
        บันทึกตอบปัญหาการใช้งานระบบ DLT Vehicle Tax Plus
      </HeaderBlock>
      <HeaderBlock>
        <Button type="primary" onClick={handleOpenModal}>
          ค้นหา
        </Button>
        {isAllow(userProfile.menuPermissions) && (
          <div>
            <Button type="primary"
              style={{ margin: '0 0 0 16px' }}
              icon="setting"
              onClick={handleOpenModalSetting}
            >
              ตั้งค่าการแจ้งปัญหา
            </Button>
            {/* <Button type="primary"
              style={{ margin: '0 0 0 16px' }}
              icon="printer"
              onClick={null}
            >
              พิมพ์รายงาน
            </Button> */}
            <Button
              style={{ margin: '0 0 0 16px' }}
              icon="export"
              onClick={handleDownload}
            >
              Export
            </Button>
            <Button
              style={{ margin: '0 0 0 16px' }}
              icon="export"
              onClick={handleDownloadSummary}
            >
              รายงานสรุป
            </Button>
          </div>
        )}
      </HeaderBlock>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        // scroll={{ x: 'max-content', y: window.innerHeight - 470 }}
      />
      <FilterModal
        visible={visible}
        handleSearch={handleSearch}
        handleCancel={handleCancel}
        renderInputFields={searchKey}
      />
      <FormModal
        onSubmit={onSubmit}
        formLoading={formLoading}
        data={targetUsersreport}
        visible={formVisible}
        onCancel={handleCloseModalForm}
      />
      <SettingFromModal
        formLoading={settingModalLoading}
        data={settingModalData}
        visible={visibleSettingModal}
        onCancel={handleCloseModalSetting}
      />


    </>
  );
};
