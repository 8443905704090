import React, { useEffect, useState, useContext } from 'react';
import {
  Modal,
  Form,
  Button,
  Spin,
  Table,
  Divider,
  Popconfirm,
  Input, // Add Input from antd
  Typography,
} from 'antd';
import { HeaderBlock } from '../../style/global';
import _ from 'lodash';
import styled from 'styled-components';
import { MainContext } from '../../context/main';

const { Title } = Typography;
export default Form.create()(
  ({
    visible,
    // onSubmitCreate = () => {},
    // onSubmitEdit = () => {},
    onCancel = () => { },
    form: { getFieldDecorator, validateFields, resetFields },
    data,
    formLoading,
  }) => {
    const { request, userProfile } = useContext(MainContext);
    const [isEditVisible, setIsEditVisible] = useState(false);
    const [isCreateVisible, setIsCreateVisible] = useState(false);
    const [editingItem, setEditingItem] = useState(null);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
      if (data) {
        setTableData(data.map((record, index) => ({
          ...record,
          key: record.id,
        })));
      }
    }, [data]);

    const onSubmitCreate = async (newTitle) => {
      let body = {};
      let res;
      body.title = newTitle;
      res = await request.post("/systemmanual/setting", body);
      if (res && res.success === true) {
        const resUpdated = await request.get('/systemmanual/setting');
        const dataUpdated = resUpdated.results.map((record, index) => ({
          ...record,
          key: record.id
        }));
        setTableData(dataUpdated);
      }
    };

    const onSubmitEdit = async (id, title) => {
      let body = {};
      let res;
      body = { id, title };
      res = await request.put("/systemmanual/setting", body);
      if (res && res.success === true) {
        const resUpdated = await request.get('/systemmanual/setting');
        const dataUpdated = resUpdated.results.map((record, index) => ({
          ...record,
          key: record.id
        }));
        setTableData(dataUpdated);
      }
    };

    const handleCreate = () => {
      setIsCreateVisible(true);
    };

    const handleEdit = (record) => {
      setEditingItem(record);
      setIsEditVisible(true);
    };

    const handleDelete = async (id) => {
      let res;
      res = await request.delete(`/systemmanual/setting/${id}`);
      if (res && res.success === true) {
        const resUpdated = await request.get('/systemmanual/setting');
        const dataUpdated = resUpdated.results.map((record, index) => ({
          ...record,
          key: record.id
        }));
        setTableData(dataUpdated);
      }
    };

    const handleSubmitCreate = () => {
      validateFields(['newTitle'], async (err, fieldsValue) => {
        if (err) {
          return;
        }
        onSubmitCreate(fieldsValue.newTitle); // Pass the new title to the create handler
        setIsCreateVisible(false); // Close the create modal
      });
    };

    const handleSubmitEdit = () => {
      validateFields(['title', 'id'], async (err, fieldsValue) => {
        if (err) {
          return;
        }
        onSubmitEdit(editingItem.id, fieldsValue.title); // Pass the item id and edited title to the edit handler
        setIsEditVisible(false); // Close the edit modal
      });
    };

    useEffect(() => {
      if (!visible) {
        resetFields();
        setIsEditVisible(false);
        setIsCreateVisible(false);
        setEditingItem(null);
      }
    }, [visible]);

    const columns = [
      {
        title: 'ลำดับ',
        dataIndex: 'index',
        key: 'index',
        render: (text, record, index) => index + 1,
      },
      {
        title: 'ชื่อหมวดหมู่',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: 'จัดการ',
        dataIndex: 'id',
        key: 'id',
        render: (id, record) => (
          <div>
            <span>
              <a onClick={() => handleEdit(record)}>แก้ไข</a>
              <Divider type="vertical" />
              <Popconfirm
                key="delete"
                title="กรุณายืนยันการลบ หมวดหมู่ ดังกล่าว"
                onConfirm={() => handleDelete(id)}
                okText="ตกลง"
                cancelText="ยกเลิก"
              >
                <a>ลบ</a>
              </Popconfirm>
            </span>
          </div>
        ),
      },
    ];

    return (
      <CustomModal
        title="ตั้งค่าคู่มือการใช้งานระบบ"
        visible={visible}
        style={{ minWidth: '800px' }}
        onCancel={onCancel}
        footer={[
          <Button key="back" onClick={onCancel}>
            ปิด
          </Button>,
        ]}
      >
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>
          <Spin spinning={formLoading}>
            <HeaderBlock>
              <Title level={4}>ตั้งค่าหมวดหมู่</Title>
              <Button
                type="primary"
                style={{ marginBottom: '10px' }}
                onClick={handleCreate} // Show create form
              >
                เพิ่ม
              </Button>
            </HeaderBlock>
            <Table dataSource={tableData} columns={columns} pagination={false} />

            {/* Edit Modal */}
            <Modal
              title="แก้ไข"
              visible={isEditVisible}
              onCancel={() => setIsEditVisible(false)} // Close the edit form
              footer={[
                <Button key="back" onClick={() => setIsEditVisible(false)}>
                  ยกเลิก
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmitEdit}>
                  บันทึก
                </Button>,
              ]}
            >
              {/* Edit form content */}
              {editingItem && (
                <Form layout="vertical">
                  <Form.Item label="ชื่อหมวดหมู่">
                    {getFieldDecorator('title', {
                      initialValue: editingItem.title,
                      rules: [
                        { required: true, message: 'กรุณากรอกชื่อหมวดหมู่' },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </Form>
              )}
            </Modal>

            {/* Create Modal */}
            <Modal
              title="เพิ่ม"
              visible={isCreateVisible}
              onCancel={() => setIsCreateVisible(false)} // Close the create form
              footer={[
                <Button key="back" onClick={() => setIsCreateVisible(false)}>
                  ยกเลิก
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmitCreate}>
                  บันทึก
                </Button>,
              ]}
            >
              {/* Create form content */}
              <Form layout="vertical">
                <Form.Item label="ชื่อหมวดหมู่">
                  {getFieldDecorator('newTitle', {
                    initialValue: '',
                    rules: [
                      { required: true, message: 'กรุณากรอกชื่อหมวดหมู่' },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Form>
            </Modal>
          </Spin>
        </Form>
      </CustomModal>
    );
  }
);

export const CustomModal = styled(Modal)`
  #kiosksIds {
    display: inline-block;
  }
  .ant-checkbox-group-item {
    margin: 0;
    width: 50%;
  }
`;
