import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input, Icon, Button, Spin } from 'antd';
import { LoginContainer, FormBlock, LogoBlock } from './style';
import { MainContext } from '../../context/main';
import ls from 'local-storage';



export default Form.create()(
  ({ form: { getFieldDecorator, validateFields } }) => {
    // console.log('Login page');
    const { request } = useContext(MainContext);
    const { login, mainLoading } = useContext(MainContext);
    const history = useHistory();
    const [showLogin, setShowLogin] = useState(false);

    const handleSubmit = (e) => {
      e.preventDefault();
      validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values);
          const { username, password } = values;
          login({ username, password });
        }
      });
    };

    useEffect(() => {
      if (ls.get('isLogin')) {
        history.push('/');
      }
      setTimeout(() => {
        setShowLogin(true);
      }, 300);
    }, []);

    return (
      <LoginContainer>
        <FormBlock show={showLogin}>
          <Spin spinning={mainLoading}>
            <LogoBlock>
              <span>DLT Vehicle Tax Plus</span>
            </LogoBlock>
            <Form onSubmit={handleSubmit} className="login-form">
              <Form.Item hasFeedback>
                {getFieldDecorator('username', {
                  rules: [
                    { required: true, message: 'Please input your username!' },
                  ],
                })(
                  <Input
                    prefix={
                      <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                    placeholder="Username"
                  />,
                )}
              </Form.Item>
              <Form.Item hasFeedback>
                {getFieldDecorator('password', {
                  rules: [
                    { required: true, message: 'Please input your Password!' },
                  ],
                })(
                  <Input
                    prefix={
                      <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                    type="password"
                    placeholder="Password"
                  />,
                )}
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  Log in
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </FormBlock>
      </LoginContainer>
    );
  },
);
