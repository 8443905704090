import React, { useEffect, useContext, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import get from 'lodash/get';
import map from 'lodash/map';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import toSafeInteger from 'lodash/toSafeInteger';
import { chan } from '../../utils';
import { numberWithCommas, makeDecimalNumber } from '../../utils/form';
import moment from 'moment';
import { convertToDatetimeBE, convertToDateBE, convertToDatetimeFullBE } from '../../utils';
import styled from 'styled-components';
import {
  Spin,
  Descriptions,
  Button,
  Card,
  Row,
  Col,
  Modal,
  List,
  Popconfirm,
} from 'antd';

import { MainContext } from '../../context/main';
import { LongLine } from '../../style/global';

const { Meta } = Card;

export default () => {
  const history = useHistory();

  const { historicalId, date } = useParams();

  const { request } = useContext(MainContext);

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await request.get(`/scb/historical/detail/${historicalId}`, {
        includes: ['transaction'],
      });

      const result = get(res, ['result'], {});

      setData(result);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const back = () => {
    history.push(`/historical`);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const remarkColor = (text, o) => {
    if (!text) {
      //   if (
      //     moment(
      //       moment(get(o, ['transaction', 'created'], null)).format('YYYY-MM-DD'),
      //     ).isBefore(moment().format('YYYY-MM-DD'))
      //   ) {
      //     return (
      //       <span style={{ color: 'red' }}>
      //         ไม่ทำการชำระภายในเวลาที่กำหนด (ภายในวันที่ทำรายการ)
      //       </span>
      //     );
      //   }

      return text;
    }

    if (text.indexOf('Error') !== -1 || text.indexOf('[error]') !== -1) {
      return <span style={{ color: 'red' }}>{text}</span>;
    }

    return <span style={{ color: 'green' }}>{text}</span>;
  };

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <h2>
          {`SCB Historical Report - วันที่นำเข้าข้อมูล ${convertToDatetimeFullBE(get(data, 'created'))}`}
        </h2>
        <LongLine />
        <Descriptions
          title="รายละเอียดรายงาน SCB Historical Report"
          layout="horizontal"
        >
          <Descriptions.Item label="หมายเลขอ้างอิง 1">
            {get(data, ['reference_1'])}
          </Descriptions.Item>
          <Descriptions.Item label="หมายเลขอ้างอิง 2">
            {get(data, ['reference_2'])}
          </Descriptions.Item>
          <Descriptions.Item label="ยอดชำระ">
            {makeDecimalNumber(get(data, ['amount']))}
            {` บาท`}
          </Descriptions.Item>
          <Descriptions.Item label="เวลาทำรายการ">
            {`${convertToDateBE(moment(get(data, ['payment_date']), 'DDMMYYYY'))} ${moment(get(data, ['payment_time']), 'HHmmss').format(
              'HH:mm:ss',
            )}`}
          </Descriptions.Item>
          <Descriptions.Item label="ชื่อผู้ชำระ">
            {get(data, ['customer_name'])}
          </Descriptions.Item>

          <Descriptions.Item label="ชนิดข้อมูล">
            {get(data, ['record_type'])}
          </Descriptions.Item>

          <Descriptions.Item label="ลำดับข้อมูล">
            {get(data, ['sequence_no'])}
          </Descriptions.Item>

          <Descriptions.Item label="รหัสธนาคาร">
            {get(data, ['bank_code'])}
          </Descriptions.Item>

          <Descriptions.Item label="เลขที่บัญชีบริษัท">
            {get(data, ['company_account'])}
          </Descriptions.Item>

          <Descriptions.Item label="รหัสสาขา">
            {get(data, ['branch_no'])}
          </Descriptions.Item>

          <Descriptions.Item label="ประเภทธุรกรรม">
            {get(data, ['kind_of_transaction'])}
          </Descriptions.Item>

          <Descriptions.Item label="รหัสธุรกรรม">
            {get(data, ['transaction_code'])}
          </Descriptions.Item>

          {/* <Descriptions.Item label="ข้อมูลจากไฟล์">
            {get(data, ['raw'])}
          </Descriptions.Item> */}
        </Descriptions>
        {/* <LongLine /> */}
        {/* <Descriptions title="รายละเอียดการทำรายการ" layout="horizontal">
          <Descriptions.Item label="หมายเลขอ้างอิง 1">
            {get(data, ['transaction', 'reference_1'])}
          </Descriptions.Item>
          <Descriptions.Item label="หมายเลขอ้างอิง 2">
            {get(data, ['transaction', 'reference_2'])}
          </Descriptions.Item>
          <Descriptions.Item label="ช่องทางการรับป้ายภาษี">
            {get(data, ['transaction', 'printFlag'])
              ? 'ตู้ชำระภาษี'
              : 'ส่งไปรษณีย์'}
          </Descriptions.Item>
          <Descriptions.Item label="ค่าชำระภาษี">
            {numberWithCommas(data, ['transaction', 'dlt_taxAmt'])}
            {` บาท`}
          </Descriptions.Item>
          <Descriptions.Item label="ค่าไปรษณีย์">
            {numberWithCommas(data, ['transaction', 'dlt_feeAmt'])}
            {` บาท`}
          </Descriptions.Item>
          <Descriptions.Item label="เงินเพิ่ม">
            {numberWithCommas(data, ['transaction', 'dlt_addAmt'])}
            {` บาท`}
          </Descriptions.Item>
          <Descriptions.Item label="รวมราคา">
            {numberWithCommas(data, ['transaction', 'dlt_totalAmt'])}
            {` บาท`}
          </Descriptions.Item>
          <Descriptions.Item label="ราคาที่เรียกเก็บ">
            {numberWithCommas(data, ['transaction', 'amount'])}
            {` บาท`}
          </Descriptions.Item>
          <Descriptions.Item label="สถานะการชำระเงิน">
            {get(data, ['transaction', 'confirmation_id'], '') !== ''
              ? 'ชำระแล้ว'
              : 'ยังไม่ชำระ'}
          </Descriptions.Item>
          <Descriptions.Item label="เวลาทำรายการ">
            {get(data, ['transaction', 'created']) &&
              moment(get(data, ['transaction', 'created'])).format(
                'D MMM YYYY HH:mm',
              )}
          </Descriptions.Item>
          <Descriptions.Item label="หมายเหตุ">
            {remarkColor(get(data, ['transaction', 'remark'], ''), data)}
          </Descriptions.Item>
        </Descriptions>
        <LongLine />
        <Descriptions title="รายละเอียดการชำระ" layout="horizontal">
          <Descriptions.Item label="tranID">
            {get(
              data,
              ['transaction', 'confirmation_id', 'tranID'],
              'ไม่พบข้อมูล',
            )}
          </Descriptions.Item>
          <Descriptions.Item label="วันที่ชำระ">
            {get(data, ['transaction', 'confirmation_id', 'created'])
              ? moment(
                  get(data, ['transaction', 'confirmation_id', 'created']),
                ).format('D MMM YYYY HH:mm')
              : 'ไม่พบข้อมูล'}
          </Descriptions.Item>
          <Descriptions.Item label="ช่องทางการชำระ">
            {get(
              chan,
              [
                get(
                  data,
                  ['transaction', 'confirmation_id', 'channel'],
                  'ไม่ระบุ',
                ),
              ],
              'ไม่พบข้อมูล',
            )}
          </Descriptions.Item>
          <Descriptions.Item label="reference1">
            {get(
              data,
              ['transaction', 'confirmation_id', 'reference1'],
              'ไม่พบข้อมูล',
            )}
          </Descriptions.Item>
          <Descriptions.Item label="reference2">
            {get(
              data,
              ['transaction', 'confirmation_id', 'reference2'],
              'ไม่พบข้อมูล',
            )}
          </Descriptions.Item>
          <Descriptions.Item label="ยอดชำระ">
            {get(
              data,
              ['transaction', 'confirmation_id', 'amount'],
              'ไม่พบข้อมูล',
            )}
          </Descriptions.Item>
          <Descriptions.Item label="หมายเหตุ">
            {remarkColor(
              get(data, ['transaction', 'confirmation_id', 'remark'], ''),
              data,
            )}
          </Descriptions.Item>
        </Descriptions> */}
        <LongLine />
        <Button type="danger" ghost onClick={back}>
          ย้อนกลับ
        </Button>
      </Spin>
    </React.Fragment>
  );
};

const TextH3 = styled.span`
  font-size: 1.17em;
`;
const TextH5 = styled.span`
  font-size: 0.83em;
`;
