import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Table, Tag, Button, Spin, Row, Col } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { convertToDatetimeBE, convertToDateBE } from '../../utils'
import { Link } from 'react-router-dom';

import { MainContext } from '../../context/main';
import { getPage, getFilter, getSort, perPage, pageSizeOptions, showSizeChanger } from '../../utils/table';
import { makeDecimalNumber } from '../../utils/form';
import { chan } from '../../utils';
import { HeaderBlock } from '../../style/global';
import FilterModal from '../../component/FilterModal';

const searchKey = [
  { label: 'หมายเลขอ้างอิง 1', key: 'reference_1' },
  {
    label: 'วันที่มีผลชำระเงิน',
    key: 'date-range2',
    type: 'rangepickernotime',
  },
  {
    label: 'วันที่นำเข้าข้อมูล',
    key: 'date-range',
    type: 'rangepicker',
  }
];
const isAllow = (menuPermissions, targetmenuPermissions) => {
  const thisMenuId = "13"
  const permission = _.find(menuPermissions, { menuId: thisMenuId });
  return permission && permission.canManage;
};
export default () => {
  const history = useHistory();
  const { date } = useParams();

  const { request, userProfile } = useContext(MainContext);

  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState({});
  const [sorter, setSorter] = useState({
    sort: { key: 'created', desc: true },
  });
  const [pagination, setPagination] = useState({
    pageSize: perPage,
    pageSizeOptions,
    showSizeChanger
  });
  const [loading, setLoading] = useState(false);
  const [jobLoading, setJobLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const fetchData = async (params = {}) => {
    setLoading(true);
    try {
      const res = await request.get(`/scb/historicalall`, {
        ...params,
        ...searchData,
      });

      const totalData = _.get(res, ['total_data'], 0);

      const result = _.get(res, ['results'], []);

      setData(result);
      setPagination({ ...pagination, total: totalData, current: params.page, pageSize: params.per_page });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (paginationChange, filters, sorterChange) => {
    const pager = { ...pagination };
    pager.current = paginationChange.current;

    setPagination(pager);
    setSorter(getSort(sorterChange));

    fetchData({
      ...getFilter(filters),
      ...getSort(sorterChange),
      ...getPage(paginationChange),
    });
  };

  useEffect(() => {
    fetchData({
      // ...getPage(pagination),
      ...sorter,
    });
  }, [searchData, date]);

  const handleSearch = (value) => {
    pagination.current = 1;
    setSearchData({ ..._.omitBy(value, (o) => _.isNil(o) || _.isEmpty(o)) });

    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOpenModal = () => {
    setVisible(true);
  };

  const goBack = () => {
    history.push('/historical');
  };

  const runJob = async () => {
    setJobLoading(true);
    try {
      const res = await request.get('/scb/historical/job');

      if (_.get(res, ['success'])) {
        fetchData({
          ...sorter,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setJobLoading(false);
    }
  };

  const columns = [
    {
      title: 'ลำดับ',
      key: 'index',
      dataIndex: 'index',
    },
    {
      title: 'หมายเลขอ้างอิง 1',
      key: 'reference_1',
      dataIndex: 'reference_1',
    },
    {
      title: 'หมายเลขอ้างอิง 2',
      key: 'reference_2',
      dataIndex: 'reference_2',
    },
    {
      title: 'ยอดชำระ',
      key: 'amount',
      dataIndex: 'amount',
      render: (v) => makeDecimalNumber(v),
    },
    {
      title: 'วันที่มีผลชำระเงิน',
      key: 'payment_date',
      dataIndex: 'payment_date',
      render: (v) => convertToDateBE(moment(v, 'DDMMYYYY')),
    },
    {
      title: 'เวลาที่ชำระเงิน',
      key: 'payment_time',
      dataIndex: 'payment_time',
      render: (v) => moment(v, 'HHmmss').format('HH:mm:ss'),
    },
    {
      title: 'วันที่นำเข้าข้อมูล',
      key: 'created',
      dataIndex: 'created',
      sorter: true,
      defaultSortOrder: 'descend',
      render: (v) => convertToDatetimeBE(v),
    },
    {
      title: '',
      key: 'id',
      dataIndex: 'id',
      fixed: 'right',
      render: (v) => <Link to={`/historical/detail/${v}`}>รายละเอียด</Link>,
    },
  ];
  const handleDownload = async (fileName = 'รายงานข้อมูลการชำระเงินจากธนาคาร.xlsx') => {
    if (loading) return;
    try {
      setLoading(true);
      fileName = 'รายงานข้อมูลการชำระเงินจากธนาคาร.xlsx';
      await request.download('/scb/historicalreport', fileName, {
        ...getPage(pagination),
        ...sorter,
        ...searchData
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Spin spinning={loading}>
      <HeaderBlock style={{ fontSize: '20px', fontWeight: 'bold' }}>
        ข้อมูลการชำระเงินจากธนาคาร
      </HeaderBlock>
      <HeaderBlock>
        {isAllow(userProfile.menuPermissions) && (
          <>
            <Button type="primary" onClick={handleOpenModal}>
              ค้นหา
            </Button>
            <div>

              <Button type="primary" loading={jobLoading} onClick={runJob}>
                ดึงข้อมูลจากไฟล์
              </Button>


              <Button
                style={{ margin: '0 0 0 16px' }}
                icon="export"
                onClick={handleDownload}
              >
                Export
              </Button>
            </div>
          </>
        )}
      </HeaderBlock>

      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={pagination}
        onChange={handleTableChange}
        scroll={{ x: 'max-content' }}
      />

      {/* <Button type="danger" onClick={goBack}>
        กลับ
      </Button> */}
      <FilterModal
        visible={visible}
        handleSearch={handleSearch}
        handleCancel={handleCancel}
        renderInputFields={searchKey}
      />
    </Spin>
  );
};
