import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { convertToDatetimeBE, convertToDateBE } from '../../utils';
import { Table, Tag, Button, Icon, Divider, Popconfirm, Popover, Spin } from 'antd';
import { TagContainer, ButtonTag } from './style';
import _, { set } from 'lodash';
import { MainContext } from '../../context/main';
import ExportExcel from '../../component/ExportExcel';
import {
  getFilter,
  getSort,
  getPage,
  getColumnSearchProps,
  perPage,
  defaultPageSize,
  pageSizeOptions,
  showSizeChanger,

} from '../../utils/table';
import { formatPhoneNumber } from '../../utils';
import { HeaderBlock } from '../../style/global';
import FilterModal from '../../component/FilterModal';
import FormModal from './form';
import vehicleTypeData from '../../utils/staticData/vehicleTypeData';
import provinceData from '../../utils/staticData/provinceData';
const searchKey = [
  { label: 'ทะเบียนรถ', key: 'plate' },
  {
    label: 'จังหวัดจดทะเบียน', key: 'offRegDesc', type: 'select2',
    options: [
      { title: "ทั้งหมด", value: "" },
      ...provinceData.map((o) => ({ title: o.province, value: o.province }))
    ]
  },
  {
    label: 'ประเภทพรบ.', key: 'plateType', type: 'select2',
    options: [
      { title: "ทั้งหมด", value: "" },
      { title: "รถยนต์", value: "1" },
      { title: "รถขนส่ง", value: "2" },
    ]
  },
  {
    label: 'ประเภทรถ', key: 'vehTypeCode', type: 'select2',
    options: [
      { title: "ทั้งหมด", value: "" },
      ...vehicleTypeData.map((o) => ({ title: o.name, value: o.id }))
    ]
  },
  {
    label: 'การแจ้งเตือน',
    key: 'notifyType',
    type: 'select',
    options: [
      { title: 'ทั้งหมด', value: '' },
      { title: 'แจ้งเตือนก่อนวันครบกำหนดชำระภาษี 90 วัน', value: '2' },
      { title: 'แจ้งเตือนในวันครบกำหนด', value: '1' },
      { title: 'แจ้งเตือนตรวจสภาพรถก่อนชำระภาษี ที่ ตรอ.', value: '31' },
      { title: 'แจ้งเตือนตรวจสภาพรถก่อนชำระภาษี ที่ สำนักงานขนส่ง', value: '32' },

      // { title: 'ถูกเปิดดูใบคู่มือจดทะเบียนรถ', value: '4'},
      // { title: 'ได้รับการอนุมัติคำขอใบรับรองฯ ยังไม่ชำระเงิน', value: '21'},
      // { title: 'ได้รับการอนุมัติคำขอใบรับรองฯ และชำระเงินแล้ว', value: '22'},
    ],
  },
  {
    label: 'วันเวลาที่แจ้งเตือน',
    key: 'date-range',
    type: 'rangepicker',
  },

];

const isAllow = (menuPermissions, targetmenuPermissions) => {
  const thisMenuId = "17"
  const permission = _.find(menuPermissions, { menuId: thisMenuId });
  return permission && permission.canManage;
};

function getColor(status) {
  switch (status) {
    case true:
      return 'green';
    case false:
      return 'orange';
    default:
      return '';
  }
}

function getWord(status) {
  switch (status) {
    case true:
      return 'เปิดใช้งาน';
    case false:
      return 'ปิดใช้งาน';
    default:
      return 'ไม่พร้อมใช้งาน';
  }
}
const PopOverTag = ({ carmanual, onStatusChange }) => {

  const currentStatus = _.get(carmanual, ['carDelegated', 'isActived']);
  const color = getColor(currentStatus);
  const [visible, setVisible] = useState();
  const [isLoading, setIsLoading] = useState(false);
  console.log(currentStatus);
  const { request } = useContext(MainContext);

  const onVisibleChange = (e) => {
    setVisible((prevState) => !prevState);
  };

  const handleSelect = (newStatus) => async (e) => {
    if (newStatus === currentStatus) return;

    const body = {
      id2: carmanual.carDelegated._id,
      isActive: newStatus,
    };
    setIsLoading(true);

    const res = await request.put(`/carmanual/${carmanual._id}/isActive`, body);

    setIsLoading(false);

    if (_.get(res, ['success'])) {
      console.log("res", res);
      onStatusChange({
        id: carmanual._id,
        id2: carmanual.carDelegated._id,
        newStatus,
      });
    }

    onVisibleChange();
  };


  return (
    <Popover
      content={(
        <Spin spinning={isLoading}>
          <TagContainer>
            <ButtonTag underline onClick={handleSelect(true)}>
              <Tag color={currentStatus === true ? 'grey' : 'green'}>
                {getWord(true)}
              </Tag>
            </ButtonTag>
            <ButtonTag underline onClick={handleSelect(false)}>
              <Tag color={currentStatus === false ? 'grey' : 'orange'}>
                {getWord(false)}
              </Tag>
            </ButtonTag>
          </TagContainer>
        </Spin>
      )}
      title="สถานะการเปิดใช้งาน"
      trigger="click"
      visible={visible}
      onVisibleChange={onVisibleChange}
    >
      <ButtonTag>
        <Tag color={color}>
          {' '}
          {getWord(currentStatus)}
        </Tag>
      </ButtonTag>
    </Popover>
  );
};
export default () => {
  const { request, userProfile } = useContext(MainContext);
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState({});
  const [sorter, setSorter] = useState({
    sort: { key: 'created', desc: true },
  });
  const [pagination, setPagination] = useState({
    // pageSize: perPage,
    defaultPageSize: defaultPageSize,
    pageSizeOptions: pageSizeOptions,
    showSizeChanger: showSizeChanger,
  });
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [targetcarmanual, setTargetcarmanual] = useState(null);


  const fetchData = async (params = {}) => {
    try {
      setLoading(true);
      const res = await request.get('/notifierspersonal', {
        ...params,
        ...searchData,
      });

      const totalData = _.get(res, ['total_data'], 0);

      setData(_.get(res, ['results'], []));
      setPagination({ ...pagination, total: totalData, current: params.page, pageSize: params.per_page });
      console.log('res', res);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  const handleTableChange = (paginationChange, filters, sorterChange) => {
    const pager = { ...pagination };
    pager.current = paginationChange.current;

    setPagination(pager);
    setSorter(getSort(sorterChange));

    fetchData({
      ...getFilter(filters),
      ...getSort(sorterChange),
      ...getPage(paginationChange),
    });
  };

  useEffect(() => {
    fetchData({
      ...getPage(pagination),
      ...sorter,
    });
  }, [searchData]);

  const handleSearch = (value) => {
    pagination.current = 1;
    setSearchData({ ..._.omitBy(value, (o) => _.isNil(o) || _.isEmpty(o)) });

    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOpenModal = () => {
    setVisible(true);
  };

  const handleOpenModalForm = (carmanual) => (e) => {
    setTargetcarmanual(carmanual);
    setFormVisible(true);
    // console.log(carmanual);
  };

  const handleCloseModalForm = () => {
    setTargetcarmanual(null);
    setFormVisible(false);
  };
  const onStatusChange = ({ newStatus, id, id2 }) => {
    setData(
      _.map(data, (o) => {
        if (o._id === id && o.carDelegated._id === id2) o.carDelegated.isActived = newStatus;
        return o;
      }),
    );
  };

  const columns = [
    {
      title: 'ลำดับ',
      key: 'index',
      dataIndex: 'index',
    },
    {
      title: 'ทะเบียนรถ',
      key: 'carInfo',
      dataIndex: 'carInfo',
      width: "10%",
      render: (v) => {
        if (!v) return '-';
        const plate1 = v.plate1 || '';
        const plate2 = v.plate2 || '';
        const platetype = v.platetype || '';
        const offRegDesc = v.offRegDesc || '';
        const typecar = v.typecar || '';

        // Check if platetype is "2" or typecar is null/blank
        const displayTypecar = platetype === "2" || !typecar || typecar == "Null" ? "" : ` รย.${parseInt(typecar)}`;
        const spacePlate = platetype === "2" || !typecar || typecar == "Null" ? "-" : " ";

        return <>{`${plate1}${spacePlate}${plate2} ${offRegDesc}${displayTypecar}`}</>;
        // return <>{`${typecar}`}</>
      }

    },
    {
      title: 'การแจ้งเตือน',
      key: 'notifyType',
      dataIndex: 'notifyType',
      width: "20%",
      render: (v) => {
        // 5 LEVEL OF score มากที่สุด มาก ปานกลาง น้อย น้อยที่สุด
        if (v === null || v === undefined) return '(แจ้งเตือนเก่าก่อนปรับปรุงระบบ)';
        if (v === 99) return 'อื่น ๆ';
        if (v === 32) return 'แจ้งเตือนตรวจสภาพรถก่อนชำระภาษี ที่ สำนักงานขนส่ง';
        if (v === 31) return 'แจ้งเตือนตรวจสภาพรถก่อนชำระภาษี ที่ ตรอ.';
        if (v === 22) return 'ได้รับการอนุมัติคำขอใบรับรองฯ และชำระเงินแล้ว';
        if (v === 21) return 'ได้รับการอนุมัติคำขอใบรับรองฯ ยังไม่ชำระเงิน';
        if (v === 5) return 'แจ้งเตือนตรวจสภาพรถก่อนชำระภาษี 90 วัน';
        if (v === 4) return 'ถูกเปิดดูใบคู่มือจดทะเบียนรถ';
        if (v === 3) return 'แจ้งเตือนตรวจสภาพรถก่อนชำระภาษี';
        if (v === 2) return 'แจ้งเตือนก่อนวันครบกำหนดชำระภาษี 90 วัน';
        if (v === 1) return 'แจ้งเตือนในวันครบกำหนด';
      },
    },
    {
      title: 'การแจ้งเตือน (ข้อความ)',
      key: 'title',
      dataIndex: 'title',
      width: "30%",
      render: (v) => {
        if (v === null || v === undefined) return '-';
        return v;
      }
    },
    {
      title: 'วันเวลาที่แจ้งเตือน',
      key: 'created',
      dataIndex: 'created',
      sorter: true,
      render: (v) => convertToDatetimeBE(v),

    },
    {
      title: 'วันครบกำหนดชำระภาษี',
      key: 'carInfo.EXP_DATE',
      dataIndex: 'carInfo.EXP_DATE',
      sorter: true,
      render: (v) => {
        if (v === null || v === undefined) return '-';
        return convertToDateBE(v);
      }

    },
  ];

  const onSubmit = async ({
    title,
    AlertMessage,
    isActive,
    periodDateStart,
    periodTimeStart,
    periodDateEnd,
    periodTimeEnd,
  }) => {
    setFormLoading(true);
    let body = {};
    let res;
    try {
      if (targetcarmanual) {
        body.carmanualId = _.get(targetcarmanual, ['id']);
        if (targetcarmanual.title !== title)
          body.title = title;
        if (targetcarmanual.AlertMessage !== AlertMessage)
          body.AlertMessage = AlertMessage;
        if (targetcarmanual.isActive !== isActive)
          body.isActive = isActive;
        body.periodDateStart = periodDateStart;
        body.periodTimeStart = periodTimeStart;
        body.periodDateEnd = periodDateEnd;
        body.periodTimeEnd = periodTimeEnd;
        res = await request.put('/carmanual', body);
      } else {
        body = {
          title,
          AlertMessage,
          isActive,
          periodDateStart,
          periodTimeStart,
          periodDateEnd,
          periodTimeEnd,
        };
        res = await request.post('/carmanual', body);
      }

      if (_.get(res, ['success'])) {
        handleCloseModalForm();
        fetchData({
          ...getPage(pagination),
          ...sorter,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setFormLoading(false);
    }
  };

  const handleDownload = async (fileName = 'รายงานข้อมูลการแจ้งเตือน.xlsx') => {
    if (loading) return;
    try {
      setLoading(true);
      fileName = 'รายงานข้อมูลการแจ้งเตือน.xlsx';
      await request.download('/notifierspersonal/report', fileName, {
        ...getPage(pagination),
        ...sorter,
        ...searchData
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadSummary = async (fileName = 'รายงานสรุปข้อมูลการแจ้งเตือน.xlsx') => {
    if (loading) return;
    try {
      setLoading(true);
      fileName = 'รายงานสรุปข้อมูลการแจ้งเตือน.xlsx';
      await request.download('/notifierspersonal/reportSummary', fileName, {
        ...getPage(pagination),
        ...sorter,
        ...searchData
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <HeaderBlock style={{ fontSize: '20px', fontWeight: 'bold' }}>
        สอบถามรายการรถที่ได้แจ้งเตือนก่อนวันครบกำหนดชำระภาษี 90 วัน/แจ้งเตือนในวันครบกำหนด/แจ้งเตือนตรวจสภาพรถก่อนชำระภาษี
      </HeaderBlock>
      <HeaderBlock>
        <Button type="primary" onClick={handleOpenModal}>
          ค้นหา
        </Button>
        {isAllow(userProfile.menuPermissions) && (
          <div>
            <Button
              style={{ margin: '0 0 0 16px' }}
              icon="export"
              onClick={handleDownload}
            >
              Export
            </Button>
            <Button
              style={{ margin: '0 0 0 16px' }}
              icon="export"
              onClick={handleDownloadSummary}
            >
              รายงานสรุป
            </Button>
          </div>

        )}
      </HeaderBlock>

      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 'max-content' }}
      />
      <FilterModal
        visible={visible}
        handleSearch={handleSearch}
        handleCancel={handleCancel}
        renderInputFields={searchKey}
      />
      <FormModal
        onSubmit={onSubmit}
        formLoading={formLoading}
        data={targetcarmanual}
        visible={formVisible}
        onCancel={handleCloseModalForm}
      ></FormModal>
    </>
  );
};
