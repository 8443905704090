import React, { useEffect, useState, useContext } from 'react';
import {
  Modal,
  Form,
  Select,
  DatePicker,
  Input,
  Button,
  Spin,
  Card,
  Checkbox
} from 'antd';
import _ from 'lodash';
import get from 'lodash/get';
import { isPassword } from '../../utils/form';
import { MainContext } from '../../context/main';
import styled from 'styled-components';

const { Option } = Select;

export default Form.create()(
  ({
    visible,
    onSubmit = () => { },
    onCancel = () => { },
    form: { getFieldDecorator, validateFields, resetFields, getFieldValue },
    data,
    formLoading,
    kioskList
  }) => {
    const { request, userProfile } = useContext(MainContext);
    const [usernameList, setUsernameList] = useState([]);
    const [adminPermissionIdList, setAdminPermissionIdList] = useState([]);


    const handleSubmit = (e) => {
      e.preventDefault();

      validateFields((err, fieldsValue) => {
        if (err) {
          return;
        }
        onSubmit(_.omit(fieldsValue));
      });
    };

    useEffect(() => {
      if (!visible) {
        resetFields();
      }
    }, [visible]);

    useEffect(() => {
      if (visible) {
        const fetchUsernames = async () => {
          try {
            const params = { per_page: 10000 };
            const response = await request.get('/admins', {
              ...params,
            });
            const data = response.results;
            setUsernameList(data);
          } catch (error) {
            console.error('Error fetching usernames:', error);
            // Handle the error case
          }
        };

        const fetchAdminPermissionIds = async () => {
          try {
            const response = await request.get('/adminpermission?per_page=10000');
            const data = response.results;
            setAdminPermissionIdList(data);
          } catch (error) {
            console.error('Error fetching adminPermissionIds:', error);
            // Handle the error case
          }
        };

        fetchUsernames();
        fetchAdminPermissionIds();
      } else {
        resetFields();
      }
    }, [visible]);


    return (
      <CustomModal
        title={data ? 'กำหนดกลุ่มผู้ใช้งาน' : 'เพิ่ม'}
        visible={visible}
        style={{ minWidth: '800px' }}
        onCancel={onCancel}
        footer={[
          <Button key="back" onClick={onCancel}>
            ยกเลิก
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit}>
            บันทึก
          </Button>,
        ]}
      >
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>
          <Spin spinning={formLoading}>
            <Form.Item label="ชื่อบัญชีผู้ใช้งาน">
              {getFieldDecorator('_id', {
                initialValue: get(data, ['_id']),
                rules: [
                  {
                    required: !data,
                    message: 'กรุณากรอกชื่อบัญชีผู้ใช้งาน',
                  },
                ],
              })(
                <Select
                  showSearch  // Enable search functionality
                  placeholder="เลือกชื่อบัญชีผู้ใช้งาน"
                  optionFilterProp="children"  // Specify the prop to filter on (in this case, the option's children)
                  // filterOption={(input, option) =>
                  //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  // }  // Filter options based on the search input
                  disabled={data !== null}
                >
                  {usernameList.map((_id) => (
                    <Select.Option key={_id.id} value={_id.id}>
                      {_id.username}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item label="กลุ่มผู้ใช้งาน">
              {getFieldDecorator('adminPermissionId', {
                initialValue: get(data, ['adminPermissionId']),
                rules: [
                  {
                    required: !data,
                    message: 'กรุณาเลือกกลุ่มผู้ใช้งาน',
                  },
                ],
              })(
                <Select placeholder="เลือกกลุ่มผู้ใช้งาน">
                  {adminPermissionIdList
                    .filter((adminPermissionId) => adminPermissionId.isActive === true)
                    .map((adminPermissionId) => (
                      <Option key={adminPermissionId._id} value={adminPermissionId._id}>
                        {adminPermissionId.groupName}
                      </Option>
                    ))}
                </Select>

              )}
            </Form.Item>

          </Spin>
        </Form>
      </CustomModal>
    );
  },
);

export const CustomModal = styled(Modal)`
  #kiosksIds {
    display: inline-block;
  }
  .ant-checkbox-group-item {
    margin: 0;
    width: 50%;
  }
`;