import styled from 'styled-components';

export const CardBlockTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  i {
    margin-right: 4px;
  }
`;
