import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ls from 'local-storage';

import get from 'lodash/get';
import upperCase from 'lodash/upperCase';
import includes from 'lodash/includes';

import { notification, Modal } from 'antd';
import { useHistory } from 'react-router-dom';

import serialize from '../utils/serialize';
import Swal from 'sweetalert2';

const translate = (content) => {
  let message = get(content, 'th');
  if (!message) {
    message = get(content, 'en', content || '');
  }

  return message;
};

const MainContext = React.createContext();

function MainContextProvider({ children }) {
  const [userProfile, setUserProfile] = useState({});
  const [mainLoading, setMainLoading] = useState(false);
  const [alertStatus, setAlertStatus] = useState(false);
  const [isAuth, setIsAuth] = useState(true);
  const intervalId = useRef(null);
  const history = useHistory();

  const openNotification = (type = 'info', message) => {
    notification[type]({
      message: translate(get(message, 'title', '')),
      description: translate(get(message, 'detail', '')),
    });
  };

  const request = async (endpoint, config) => {
    try {
      const type = get(config, ['type']);
      const method = upperCase(get(config, 'method', 'get'));
      const params = get(config, 'params');
      const body = get(config, 'body', {});

      const path = `${process.env.REACT_APP_API}${endpoint}${params ? `?${serialize(params)}` : ''
        }`;

      if (type === 'download') {
        const res = await axios({
          url: path,
          method: 'GET',
          responseType: 'blob', // important
          withCredentials: true,
        });

        const data = get(res, 'data');

        const fileName = get(config, ['fileName']);
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        return fileName;
      }

      const res = await axios({
        method,
        url: path,
        withCredentials: true,
        data: body,
      });

      const data = get(res, 'data');

      if (includes(['POST', 'PUT', 'DELETE'], method)) {
        if (get(data, 'success')) {
          openNotification('success', {
            title: 'สำเร็จ',
            detail: get(data, 'message', ''),
          });
        } else if (!get(data, 'success') && get(data, 'apiType')) {
          openNotification('error', {
            title: 'ไม่สำเร็จ',
            detail: get(data, 'message', ''),
          });
        }
      }

      return data;
    } catch (error) {
      const errorAlert = get(config, 'error_alert');
      let response = get(error, 'response');

      if (!response) {
        response = get(error, 'request');
      }

      const data = get(response, 'data');

      let title = get(response, ['data', 'title']);
      let detail = get(
        response,
        ['data', 'message'],
        get(response, ['data', 'error_message']),
      );

      const status = get(response, ['status']);

      if (!title)
        title = status === 0 ? 'Server Error' : get(response, ['status']);

      if (!detail) {
        detail =
          status === 0 ? 'Server Not Response' : get(response, ['statusText']);
      }

      if (errorAlert !== 'none') {
        openNotification('error', {
          title,
          detail,
        });
      }

      if (includes([401], status)) {
        ls.remove('isLogin');
        setIsAuth(false);
        history.push('/login');
      }

      return error;
    }
  };

  const login = async ({ username, password }) => {
    setMainLoading(true);
    try {
      const res = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API}/auth/login`,
        withCredentials: true,
        data: {
          username,
          password,
        },
      });

      const data = get(res, ['data']);

      if (!get(data, ['success'])) {
        const err = new Error();
        err.response = {
          status: 401,
          statusText: 'Unauthorizeds',
        };
        throw err;
      }

      if (!get(data, ['success'])) {
        const err = new Error();
        err.response = {
          status: 401,
          statusText: 'Unauthorizeds',
        };
        throw err;
      }

      openNotification('success', {
        title: 'success',
        detail: get(data, ['message'], 'login success'),
      });

      ls.set('isLogin', true);
      if (!get(data, ['firstlogin'])) {
        setIsAuth(true);
        history.push('/');
      }
    } catch (error) {
      let response = get(error, 'response');

      if (!response) {
        response = get(error, 'request');
      }

      let title = get(response, ['data', 'title']);
      let detail = get(
        response,
        ['data', 'message'],
        get(response, ['data', 'error_message']),
      );

      const status = get(response, ['status']);

      if (!title)
        title = status === 0 ? 'Server Error' : get(response, ['status']);
      if (!detail) {
        detail =
          status === 0 ? 'Server Not Response' : get(response, ['statusText']);
      }

      openNotification('error', {
        title,
        detail,
      });
    } finally {
      setMainLoading(false);
    }
  };

  const logout = () => {
    Modal.confirm({
      title: 'กรุณายืนยัน',
      content: "คลิก 'ตกลง' เพื่อออกจากระบบ",
      onOk: async () => {
        // console.log("OK");
        await request('/auth/logout');
        ls.remove('isLogin');
        setIsAuth(false);
        history.push('/login');
      },
      onCancel() {
        // console.log('Cancel');
      },
      okText: 'ตกลง',
      cancelText: 'ยกเลิก',
    });
  };

  const getProfile = async () => {
    try {

      const response = await fetch(`${process.env.REACT_APP_SOCKET_URL}/static/assets/ServerDown.txt`);
      let swalText = 'กรุณาตรวจสอบการเชื่อมต่อกับระบบ';
      if (response.ok) {
        swalText = await response.text()
        console.log(swalText)
      }

      const profile = await request('/auth/profile');
      if (get(profile, ['message']) == 'Network Error') {
        Swal.fire({
          title: 'ไม่สามารถเชื่อมต่อกับระบบได้',
          text: swalText,
          icon: 'warning',
          button: 'ปิด',
          width: 800,
        });
      }
      setUserProfile(profile);
    } catch (err) {
      console.error(err);
      // console.log err status
      if (err.message == "Failed to fetch") {
        let swalText = 'กรุณาตรวจสอบการเชื่อมต่อกับระบบ';
        Swal.fire({
          title: 'ไม่สามารถเชื่อมต่อกับระบบได้',
          text: swalText,
          icon: 'warning',
          button: 'ปิด',
          width: 800,
        });
      }
    }
  };

  const getAlertStatus = async () => {
    console.log({ intervalId });
    try {
      const alert_status = await request('/kiosk/alert_status');

      const status = alert_status.result;

      console.log({
        status,
      });

      setAlertStatus(status);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (isAuth) {
      getProfile();
      getAlertStatus();
      if (intervalId.current) clearInterval(intervalId.current);
      intervalId.current = setInterval(getAlertStatus, 1000 * 60 * 60);
    } else {
      clearInterval(intervalId.current);
    }

    return () => clearInterval(intervalId.current);
  }, [isAuth]);
  // send api to check server status every 10 seconds
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const response = fetch(`${process.env.REACT_APP_SOCKET_URL}/static/assets/ServerDown.txt`);
  //     if (response.ok) {
  //       console.log('server is running');
  //     } else {
  //       console.log('server is down');
  //     }
  //   }, 10000);
  //   return () => clearInterval(interval);
  // }, []);
  return (
    <MainContext.Provider
      value={{
        request: {
          get: async (endpoint, params) => await request(endpoint, { params }),
          post: async (endpoint, body) =>
            await request(endpoint, { method: 'post', body }),
          put: async (endpoint, body) =>
            await request(endpoint, { method: 'put', body }),
          delete: async (endpoint, params) =>
            await request(endpoint, { method: 'delete', params }),
          download: async (endpoint, fileName, params) =>
            await request(endpoint, { type: 'download', params, fileName }),
        },
        login,
        logout,
        mainLoading,
        userProfile,
        getProfile,
        alertStatus,
        getAlertStatus,
      }}
    >
      {children}
    </MainContext.Provider>
  );
}

export { MainContext, MainContextProvider };
