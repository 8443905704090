import React, { useState, useContext, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import get from 'lodash/get';

import { Drawer } from 'antd';
import Form from './formedit';
import GoogleMaps from '../../component/GoogleMap';
import { MainContext } from '../../context/main';
import { LongLine } from '../../style/global';

export default ({ kioskId, setKioskId, fetchTableData }) => {
  const [location, setLocation] = useState({
    lat: 13.7943234,
    lng: 100.4583443,
  });

  const history = useHistory();
  // const { kioskId } = useParams();
  const { request, getAlertStatus } = useContext(MainContext);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const onSave = async (data) => {
    setSaveLoading(true);
    const tempData = { ...data, ...location };
    delete tempData.serial_number;
    delete tempData.next_kioskPaper_reference;

    const res = await request.put(`/kiosk/${kioskId}`, {
      ...tempData,
    });

    fetchTableData();

    setSaveLoading(false);
    getAlertStatus();
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await request.get(`/kiosk/${kioskId}`, {
        id: kioskId,
      });

      setData(get(res, ['result'], {}));

      const tempLocation = get(res, ['result', 'location']);
      if (tempLocation) {
        setLocation(tempLocation);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const back = () => {
    setKioskId('');
    setData();
    fetchTableData();
    // history.push('/kiosk');
  };

  const afterVisibleChange = () => {
    console.log('end');
    setShowMap(!!kioskId);
  };

  useEffect(() => {
    if (kioskId && kioskId !== '0') {
      fetchData();
    }
  }, [kioskId]);

  return (
    <Drawer
      visible={!!kioskId}
      width="100%"
      getContainer={() => document.getElementById('main-content-layout')}
      // getContainer={false}
      style={{ position: 'absolute' }}
      bodyStyle={{ paddingTop: 48 }}
      onClose={back}
      afterVisibleChange={afterVisibleChange}
    >
      {showMap && (
        <GoogleMaps
          // key={`${loading ? 'loading' : 'success'}`}
          location={location}
          setLocation={setLocation}
        />
      )}
      <LongLine />
      <Form back={back} loading={saveLoading} data={data} onSave={onSave} fetchData={fetchData} />
    </Drawer>
  );
};
