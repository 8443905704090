import moment from 'moment';

export const formatPhoneNumber = (phone) =>
  phone.replace(/(\d{2,3})(\d{3})(\d{4})/, '$1-$2-$3');

export const chan = {
  ATM: 'ชำระผ่านตู้ ATM',
  CDM: 'ชำระผ่านตู้ CDM',
  PHON: 'กด SMS บน Mobile (ปัจจุบันไม่มีใช้)',
  ENET: 'ชำระผ่าน SCB Easy Net หรือ SCB Easy App',
  TELE: 'ชำระผ่าน Tele Banking (IVR)',
  TELL: 'ชำระผ่าน Counter สาขา',
  PTNR: 'Partner (ปัจจุบันไม่มีใช้)',
  EDC: 'ชำระผ่านเครื่อง EDC',
};

export const convertToDatetimeBE = (datetime) => {
  return `${moment(datetime).format('D MMM')} ${parseInt(moment(datetime).format('YYYY')) + 543} ${moment(datetime).format('HH:mm:ss')}`
}
export const convertToDateBE = (datetime) => {
  return `${moment(datetime).format('D MMM')} ${parseInt(moment(datetime).format('YYYY')) + 543}`
}
export const convertToDatetimeFullBE = (datetime) => {
  return `${moment(datetime).format('D MMMM')} ${parseInt(moment(datetime).format('YYYY')) + 543} เวลา ${moment(datetime).format('HH:mm:ss')}`
}