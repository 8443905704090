import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { Table, Tag, Button, Icon, Divider, Popconfirm, Popover, Spin } from 'antd';
import { TagContainer, ButtonTag } from './style';
import _, { set } from 'lodash';
import { MainContext } from '../../context/main';
import ExportExcel from '../../component/ExportExcel';
import {
  getFilter,
  getSort,
  getPage,
  getColumnSearchProps,
  perPage,
  defaultPageSize,
  pageSizeOptions,
  showSizeChanger,

} from '../../utils/table';
import { formatPhoneNumber } from '../../utils';
import { HeaderBlock } from '../../style/global';
import FilterModal from '../../component/FilterModal';
import FormModal from './form';

const searchKey = [
  {
    label: 'วันที่เริ่มต้น - สิ้นสุดสำรวจ',
    key: 'date-range',
    type: 'rangepicker',
  },
  {
    label: 'ฟังก์ชัน/กระบวนงาน',
    key: 'surveytype',
    type: 'select',
    options: [
      { title: 'ทั้งหมด', value: 'ALL', selected: true },
      { title: 'ชำระภาษีประจำปี', value: 'Tax' },
      { title: 'ใบคู่มือจดทะเบียนรถอิเล็กทรอนิกส์', value: 'CarManual' },
      { title: 'ขอใบรับรองการจดทะเบียนรถ', value: 'OwnerCert' },
      { title: 'เครื่องหมายการเสียภาษีประจำปีอิเล็กทรอนิกส์', value: 'CircleTax' },
      { title: 'ภาพรวมระบบ', value: 'OverAll' },
    ],
  }
];

const isAllow = (menuPermissions, targetmenuPermissions) => {
  const thisMenuId = "16"
  const permission = _.find(menuPermissions, { menuId: thisMenuId });
  return permission && permission.canManage;
};

function getColor(status) {
  switch (status) {
    case true:
      return 'green';
    case false:
      return 'orange';
    default:
      return '';
  }
}

function getWord(status) {
  switch (status) {
    case true:
      return 'เปิดใช้งาน';
    case false:
      return 'ปิดใช้งาน';
    default:
      return 'ไม่พร้อมใช้งาน';
  }
}

export default () => {
  const { request, userProfile } = useContext(MainContext);
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState({});
  const [sorter, setSorter] = useState({
    sort: { key: 'created', desc: true },
  });
  const [pagination, setPagination] = useState({
    // pageSize: perPage,
    defaultPageSize: defaultPageSize,
    pageSizeOptions: pageSizeOptions,
    showSizeChanger: showSizeChanger,
  });
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [targetsurveySetting, setTargetsurveySetting] = useState({});


  //export EXCEL
  const [visibleExportExcelModal, setVisibleExportExcelModal] = useState(false);
  const toggleExportExcelModal = () => {
    setVisibleExportExcelModal(!visibleExportExcelModal);
  };
  const handleDownload = async (fileName = 'รายงานข้อมูลการสำรวจความพึงพอใจ.xlsx') => {
    if (loading) return;
    try {
      setLoading(true);
      fileName = 'รายงานข้อมูลการสำรวจความพึงพอใจ.xlsx';
      await request.download('/survey/report', fileName, {
        ...getPage(pagination),
        ...sorter,
        ...searchData
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  const fetchData = async (params = {}) => {
    try {
      setLoading(true);
      const res = await request.get('/survey', {
        ...params,
        ...searchData,
      });

      const totalData = _.get(res, ['total_data'], 0);
      // add sum data to last row
      let result = _.get(res, ['results'], []);
      let sum = 0;
      for (let i = 0; i < result.length; i++) {
        sum += result[i].count;
      }
      result.push({
        score: "sum",
        count: sum,
        percent: sum ? 100 : null,
        index: 6
      })
      setData(result);

      // setData(_.get(res, ['results'], []));
      setPagination({ ...pagination, total: totalData, current: params.page, pageSize: params.per_page });
      console.log('res', res);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (paginationChange, filters, sorterChange) => {
    const pager = { ...pagination };
    pager.current = paginationChange.current;

    setPagination(pager);
    setSorter(getSort(sorterChange));

    fetchData({
      ...getFilter(filters),
      ...getSort(sorterChange),
      ...getPage(paginationChange),
    });
  };

  useEffect(() => {
    if (searchData.surveytype == null) {
      searchData.surveytype = "ALL"
    }
    fetchData({
      ...getPage(pagination),
      ...sorter,
    });
  }, [searchData]);

  const handleSearch = (value) => {
    pagination.current = 1;
    setSearchData({ ..._.omitBy(value, (o) => _.isNil(o) || _.isEmpty(o)) });

    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOpenModal = () => {
    setVisible(true);
  };

  const handleOpenModalForm = (surveySetting) => async (e) => {
    const res = await request.get('/survey/setting', {});
    if (res.results.length > 0) {
      surveySetting = res.results[0];
    }
    setTargetsurveySetting(surveySetting);
    setFormVisible(true);
  };

  const handleCloseModalForm = () => {
    setTargetsurveySetting(null);
    setFormVisible(false);
  };
  const onStatusChange = ({ newStatus, id, id2 }) => {
    setData(
      _.map(data, (o) => {
        if (o._id === id && o.carDelegated._id === id2) o.carDelegated.isActived = newStatus;
        return o;
      }),
    );
  };

  const columns = [
    {
      title: 'ลำดับ',
      key: 'index',
      dataIndex: 'index',
    },
    {
      title: 'ระดับความพึงพอใจ',
      key: 'score',
      dataIndex: 'score',
      render: (v) => {
        // 5 LEVEL OF score มากที่สุด มาก ปานกลาง น้อย น้อยที่สุด
        if (v === 5) return 'มากที่สุด';
        if (v === 4) return 'มาก';
        if (v === 3) return 'ปานกลาง';
        if (v === 2) return 'น้อย';
        if (v === 1) return 'น้อยที่สุด';
        if (v === "sum") return 'รวม';
      },
    },
    {
      title: 'จำนวนผู้ประเมินความพึงพอใจ (คน)',
      key: 'count',
      dataIndex: 'count',
      render: (record) => {
        // Check if record.count is defined before applying toLocaleString()
        return record !== undefined ? record.toLocaleString() : '';
      },
    },
    {
      title: 'จำนวนผู้ประเมินความพึงพอใจ (%)',
      key: 'percent',
      dataIndex: 'percent',
      render: (record) => {
        // Check if record.percent is defined before formatting
        return record !== null ? record.toFixed(2) : '0';
      },
    },
    // {
    //   title: 'ฟังก์ชัน/กระบวนงาน',
    //   key: '',
    //   dataIndex: '',
    //   render: (record) => {
    //     // Check if record.percent is defined before formatting
    //     return "ภาพรวม"
    //     // return searchData.surveyType
    //   },
    // },
  ];

  const onSubmit = async ({
    OverAll_isActive,
    OverAll_isLimitTime,
    OverAll_LimitTimeStart,
    OverAll_LimitTimeEnd,
    OwnerCert_isActive,
    OwnerCert_isLimitTime,
    OwnerCert_LimitTimeStart,
    OwnerCert_LimitTimeEnd,
    Tax_isActive,
    Tax_isLimitTime,
    Tax_LimitTimeStart,
    Tax_LimitTimeEnd,
    CarManual_isActive,
    CarManual_isLimitTime,
    CarManual_LimitTimeStart,
    CarManual_LimitTimeEnd,
    CircleTax_isActive,
    CircleTax_isLimitTime,
    CircleTax_LimitTimeStart,
    CircleTax_LimitTimeEnd,
    ExtAgency_isActive,
    ExtAgency_isLimitTime,
    ExtAgency_LimitTimeStart,
    ExtAgency_LimitTimeEnd,
    ExtAgency_Title,
    ExtAgency_URL,
  }) => {
    setFormLoading(true);
    let body = {};
    let res;
    try {
      if (targetsurveySetting) {
        body.surveySettingId = _.get(targetsurveySetting, ['id']);
        body.OverAll_isActive = OverAll_isActive;
        body.OverAll_isLimitTime = OverAll_isLimitTime;
        body.OverAll_LimitTimeStart = OverAll_LimitTimeStart;
        body.OverAll_LimitTimeEnd = OverAll_LimitTimeEnd;
        body.OwnerCert_isActive = OwnerCert_isActive;
        body.OwnerCert_isLimitTime = OwnerCert_isLimitTime;
        body.OwnerCert_LimitTimeStart = OwnerCert_LimitTimeStart;
        body.OwnerCert_LimitTimeEnd = OwnerCert_LimitTimeEnd;
        body.Tax_isActive = Tax_isActive;
        body.Tax_isLimitTime = Tax_isLimitTime;
        body.Tax_LimitTimeStart = Tax_LimitTimeStart;
        body.Tax_LimitTimeEnd = Tax_LimitTimeEnd;
        body.CarManual_isActive = CarManual_isActive;
        body.CarManual_isLimitTime = CarManual_isLimitTime;
        body.CarManual_LimitTimeStart = CarManual_LimitTimeStart;
        body.CarManual_LimitTimeEnd = CarManual_LimitTimeEnd;
        body.CircleTax_isActive = CircleTax_isActive;
        body.CircleTax_isLimitTime = CircleTax_isLimitTime;
        body.CircleTax_LimitTimeStart = CircleTax_LimitTimeStart;
        body.CircleTax_LimitTimeEnd = CircleTax_LimitTimeEnd;
        body.ExtAgency_isActive = ExtAgency_isActive;
        body.ExtAgency_isLimitTime = ExtAgency_isLimitTime;
        body.ExtAgency_LimitTimeStart = ExtAgency_LimitTimeStart;
        body.ExtAgency_LimitTimeEnd = ExtAgency_LimitTimeEnd;
        body.ExtAgency_Title = ExtAgency_Title;
        body.ExtAgency_URL = ExtAgency_URL;
        res = await request.put('/survey/setting', body);
      } else {
        body = {
          OverAll_isActive,
          OverAll_isLimitTime,
          OverAll_LimitTimeStart,
          OverAll_LimitTimeEnd,
          OwnerCert_isActive,
          OwnerCert_isLimitTime,
          OwnerCert_LimitTimeStart,
          OwnerCert_LimitTimeEnd,
          Tax_isActive,
          Tax_isLimitTime,
          Tax_LimitTimeStart,
          Tax_LimitTimeEnd,
          CarManual_isActive,
          CarManual_isLimitTime,
          CarManual_LimitTimeStart,
          CarManual_LimitTimeEnd,
          CircleTax_isActive,
          CircleTax_isLimitTime,
          CircleTax_LimitTimeStart,
          CircleTax_LimitTimeEnd,
          ExtAgency_isActive,
          ExtAgency_isLimitTime,
          ExtAgency_LimitTimeStart,
          ExtAgency_LimitTimeEnd,
          ExtAgency_Title,
          ExtAgency_URL,
        };
        res = await request.post('/survey/setting', body);
      }
      console.log(res)
      if (_.get(res, ['success'])) {
        handleCloseModalForm();
        fetchData({
          ...getPage(pagination),
          ...sorter,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setFormLoading(false);
    }
  };

  return (
    <>
      <HeaderBlock style={{ fontSize: '20px', fontWeight: 'bold' }}>
        จัดการแบบสำรวจความพึงพอใจในการใช้งานระบบ
      </HeaderBlock>
      <HeaderBlock>
        <Button type="primary" onClick={handleOpenModal}>
          ค้นหา
        </Button>
        {isAllow(userProfile.menuPermissions) && (
          <div>
            {/* <Button type="primary" onClick={handleOpenModalForm(null)} icon="setting" style={{ marginRight: '10px' }}>
              ตั้งค่าแบบสำรวจความพึงพอใจจากหน่วยงานภายนอก
            </Button> */}

            <Button type="primary" onClick={handleOpenModalForm(null)} icon="setting">
              ตั้งค่าแบบสำรวจความพึงพอใจ
            </Button>
            {/* <Button
              style={{ margin: '0 0 0 16px' }}
              icon="export"
              onClick={toggleExportExcelModal}
            >
              Export
            </Button> */}
            <Button
              style={{ margin: '0 0 0 16px' }}
              icon="export"
              onClick={handleDownload}
            >
              Export
            </Button>
          </div>
        )}
      </HeaderBlock>
      {/* <ExportExcel
        visible={visibleExportExcelModal}
        handleOk={toggleExportExcelModal}
        handleCancel={toggleExportExcelModal}
        handleDownload={handleDownload}
        total={_.get(pagination, 'total', 0)}
        issurveySetting={true}
      /> */}

      <span style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'left' }}>เงื่อนไขการค้นหา : {" "}

        {_.map(_.omitBy(searchData, _.isNil), (v, k) => {
          let dataToDisplay = v;
          if (k === 'surveytype') {
            dataToDisplay = "ฟังก์ชัน/กระบวนงาน : " + _.get(_.find(searchKey, { key: k }).options, _.findIndex(_.find(searchKey, { key: k }).options, { value: v }), {}).title
          }
          if (k === 'start_search_date') {
            dataToDisplay = "วันที่เริ่มสำรวจ : " + moment(v).format('DD/MM/YYYY')
          }
          if (k === 'end_search_date') {
            dataToDisplay = "วันที่สิ้นสุดสำรวจ : " + moment(v).format('DD/MM/YYYY')
          }
          return (
            <TagContainer key={k} style={{ display: 'inline-block' }}>
              <Tag key={k}>
                {dataToDisplay}
              </Tag>
            </TagContainer>
          )
        })}
      </span>

      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={false}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 'max-content' }}
      />
      <FilterModal
        visible={visible}
        handleSearch={handleSearch}
        handleCancel={handleCancel}
        renderInputFields={searchKey}
      />
      <FormModal
        onSubmit={onSubmit}
        formLoading={formLoading}
        data={targetsurveySetting}
        visible={formVisible}
        onCancel={handleCloseModalForm}
      />
    </>
  );
};
