import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { convertToDatetimeBE, convertToDateBE } from '../../utils';
import { Table, Tag, Button } from 'antd';

import _ from 'lodash';
import { MainContext } from '../../context/main';

import {
  getFilter,
  getSort,
  getPage,
  getColumnSearchProps,
  perPage,
  defaultPageSize,
  pageSizeOptions,
  showSizeChanger,
} from '../../utils/table';
import { formatPhoneNumber } from '../../utils';
import { HeaderBlock } from '../../style/global';
import FilterModal from '../../component/FilterModal';

const searchKey = [
  { label: 'ชื่อโปรแกรม', key: 'name_space' },
  { label: 'คำอธิบาย', key: 'label' },
  {
    label: 'ประเภท',
    key: 'type',
    type: 'select',
    options: [
      { title: 'ทั้งหมด', value: ''},
      { title: 'info', value: 'info' },
      { title: 'error', value: 'error' },
    ],
  },
  {
    label: 'เวลาที่เกิด',
    key: 'date-range',
    type: 'rangepicker',
  },
];

const isAllow = (menuPermissions, targetmenuPermissions) => {
  const thisMenuId = "14"
  const permission = _.find(menuPermissions, { menuId: thisMenuId });
  return permission && permission.canManage;
};

const columns = [
  {
    title: 'ลำดับ',
    key: 'index',
    dataIndex: 'index',
  },
  {
    title: 'ประเภท',
    key: 'type',
    dataIndex: 'type',
    render: (v) => {
      return (
        <Tag color={v === 'info' ? 'green' : 'volcano'}>
          {v === 'info' ? 'info' : 'error'}
        </Tag>
      );
    },
  },
  {
    title: 'ชื่อโปรแกรม',
    key: 'name_space',
    dataIndex: 'name_space',
  },
  {
    title: 'คำอธิบาย',
    key: 'label',
    dataIndex: 'label',
  },
  {
    title: 'รายละเอียด',
    key: 'detail',
    dataIndex: 'detail',
    width: '40%',
  },
  {
    title: 'เวลาที่เกิด',
    key: 'created',
    dataIndex: 'created',
    sorter: () => { },
    defaultSortOrder: 'descend',
    render: (v) => convertToDatetimeBE(v), 
  },
];

export default () => {
  const { request, userProfile } = useContext(MainContext);
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState({});
  const [sorter, setSorter] = useState({
    sort: { key: 'created', desc: true },
  });
  const [pagination, setPagination] = useState({
    // pageSize: perPage,
    defaultPageSize,
    pageSizeOptions,
    showSizeChanger,
  });
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const fetchData = async (params = {}) => {
    try {
      setLoading(true);
      console.log(searchData)
      const res = await request.get('/logs', {
        ...params,
        ...searchData,
      });

      const totalData = _.get(res, ['total_data'], 0);

      setData(_.get(res, ['results'], []));
      setPagination({ ...pagination, total: totalData, current: params.page, pageSize: params.per_page });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (paginationChange, filters, sorterChange) => {
    const pager = { ...pagination };
    pager.current = paginationChange.current;

    setPagination(pager);
    setSorter(getSort(sorterChange));

    fetchData({
      ...getFilter(filters),
      ...getSort(sorterChange),
      ...getPage(paginationChange),
    });
  };

  useEffect(() => {
    fetchData({
      ...getPage(pagination),
      ...sorter,
    });
  }, [searchData]);

  const handleSearch = (value) => {
    pagination.current = 1;
    setSearchData({ ..._.omitBy(value, (o) => _.isNil(o) || _.isEmpty(o)) });

    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOpenModal = () => {
    setVisible(true);
  };

  return (
    <>
      <HeaderBlock style={{ fontSize: '20px', fontWeight: 'bold' }}>
        สอบถามประวัติ (Log) การทำงานของระบบต่าง ๆ
      </HeaderBlock>
      <HeaderBlock>
        <Button type="primary" onClick={handleOpenModal}>
          ค้นหา
        </Button>
      </HeaderBlock>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 'max-content' }}
      />
      <FilterModal
        visible={visible}
        handleSearch={handleSearch}
        handleCancel={handleCancel}
        renderInputFields={searchKey}
      />
    </>
  );
};
