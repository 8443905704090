import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import {
  Table,
  Tag,
  Button,
  Divider,
  Modal,
  Input,
  Typography,
  message,
  Popconfirm,
  Popover,
  Spin,
} from 'antd';
import _ from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import { LongLine, HeaderBlock } from '../../style/global';
import { MainContext } from '../../context/main';
import { SocketContext } from '../../context/socket';
import FilterModal from '../../component/FilterModal';
import { ReactComponent as GoogleMapIcon } from '../../images/icon/google-maps.svg';
import DrawerExport from '../../component/DrawerExport';
import {
  getFilter,
  getSort,
  getPage,
  getColumnSearchProps,
  perPage,
  defaultPageSize,
  pageSizeOptions,
  showSizeChanger,
} from '../../utils/table';
import { formatPhoneNumber } from '../../utils';

import Detail from './detail';

import { TagContainer, ButtonTag } from './style';

const { TextArea } = Input;
const { Title, Paragraph, Text } = Typography;

function getColor(status) {
  switch (status) {
    case 'activate':
      return 'green';
    case 'canceled':
      return '';
    default:
      return 'orange';
  }
}

function getWord(status) {
  switch (status) {
    case 'activate':
      return 'ใช้งาน';
    case 'canceled':
      return 'ยกเลิกการใช้งาน';
    default:
      return 'ไม่พร้อมใช้งาน';
  }
}

const searchKey = [{ label: 'รหัสตู้', key: 'kiosk_code' }];

const PopOverTag = ({ kiosk, onStatusChange }) => {
  const currentStatus = _.get(kiosk, ['status']);
  const color = getColor(currentStatus);
  const [visible, setVisible] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const { request } = useContext(MainContext);

  const onVisibleChange = (e) => {
    setVisible((prevState) => !prevState);
  };

  const handleSelect = (newStatus) => async (e) => {
    if (newStatus === currentStatus) return;

    const body = {
      status: newStatus,
    };

    setIsLoading(true);

    const res = await request.put(`/kiosk/${kiosk.id}/status`, body);

    setIsLoading(false);

    if (_.get(res, ['success'])) {
      onStatusChange({
        id: kiosk.id,
        newStatus,
      });
    }

    onVisibleChange();
  };

  return (
    <Popover
      content={(
        <Spin spinning={isLoading}>
          <TagContainer>
            <ButtonTag underline onClick={handleSelect('activate')}>
              <Tag color={currentStatus === 'activate' ? 'grey' : 'green'}>
                {getWord('activate')}
              </Tag>
            </ButtonTag>
            <ButtonTag underline onClick={handleSelect('deactivate')}>
              <Tag color={currentStatus === 'deactivate' ? 'grey' : 'orange'}>
                {getWord('deactivate')}
              </Tag>
            </ButtonTag>
            <ButtonTag underline onClick={handleSelect('canceled')}>
              <Tag color={currentStatus === 'canceled' ? 'grey' : ''}>
                {getWord('canceled')}
              </Tag>
            </ButtonTag>
          </TagContainer>
        </Spin>
      )}
      title="สถานะตู้รับชำระภาษี"
      trigger="click"
      visible={visible}
      onVisibleChange={onVisibleChange}
    >
      <ButtonTag>
        <Tag color={color}>
          {' '}
          {getWord(currentStatus)}
        </Tag>
      </ButtonTag>
    </Popover>
  );
};

const isAllow = (menuPermissions, targetmenuPermissions) => {
  const thisMenuId = "4"
  const permission = _.find(menuPermissions, { menuId: thisMenuId });
  return permission && permission.canManage;
};

export default () => {
  const history = useHistory();
  const { request, userProfile } = useContext(MainContext);
  const { kiosks } = useContext(SocketContext);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    // pageSize: perPage
    defaultPageSize: defaultPageSize,
    pageSizeOptions: pageSizeOptions,
    showSizeChanger: showSizeChanger,
  });
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [secretVisible, setSecretVisible] = useState(false);
  const [searchData, setSearchData] = useState({});
  const [sorter, setSorter] = useState({
    sort: { key: 'created', desc: false },
  });
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [drawerExportVisible, setDrawerExportVisible] = useState(false);
  const [kioskId, setKioskId] = useState('');

  const [secretKey, setSecretKey] = useState(null);

  const fetchData = async (
    params = {
      ...getPage(pagination),
      ...sorter,
    },
  ) => {
    try {
      setLoading(true);
      const res = await request.get('/kiosk', {
        ...params,
        ...searchData,
      });

      const totalData = _.get(res, ['total_data'], 0);

      setData(_.get(res, ['results'], []));
      setPagination({ ...pagination, total: totalData, current: params.page, pageSize: params.per_page });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (paginationChange, filters, sorterChange) => {
    const pager = { ...pagination };
    pager.current = paginationChange.current;

    setPagination(pager);
    setSorter(getSort(sorterChange));

    fetchData({
      ...getFilter(filters),
      ...getSort(sorterChange),
      ...getPage(paginationChange),
    });
  };

  useEffect(() => {
    fetchData({
      ...getPage(pagination),
    });
  }, []);

  const handleAdd = async () => {
    let res;
    try {
      setLoading(true);
      res = await request.post('/kiosk', {});
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      const id = _.get(res, ['result', 'id']);

      if (id) {
        fetchData();
        setKioskId(id);
        // history.push(`/kiosk/${id}`);
      }
    }
  };

  const renewSecret = (id) => async () => {
    setModalLoading(true);
    try {
      const res = await request.get(`/kiosk/${id}/key/renew`);
      setSecretKey({ ..._.get(res, ['result']), id });
    } catch (error) {
      console.error(error);
    } finally {
      setModalLoading(false);
    }
  };

  const openSecretModal = (id) => async () => {
    setModalLoading(true);
    setSecretVisible(true);
    try {
      const res = await request.get(`/kiosk/${id}/key`);
      setSecretKey({ ..._.get(res, ['result']), id });
    } catch (error) {
      console.error(error);
    } finally {
      setModalLoading(false);
    }
  };

  const closeSecretModal = () => {
    setSecretVisible(false);
    setSecretKey(null);
  };

  const handleCopyClipboard = (text, key = '') => () => {
    const input = document.createElement('TextArea');
    // input.setAttribute('value', text);
    input.innerHTML = text;

    document.body.appendChild(input);
    input.select();
    const result = document.execCommand('copy');
    document.body.removeChild(input);

    message.warning(`${key} is copied`);

    return result;
  };

  const handleDownloadSecret = (text, key = '') => () => {
    if (!key) {
      message.error('filename is empty');
      return;
    }

    const fileName = `${key}.pem`;
    const url = window.URL.createObjectURL(new Blob([text]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  };

  const onStatusChange = ({ newStatus, id }) => {
    setData(
      _.map(data, (o) => {
        if (o.id === id) o.status = newStatus;

        return o;
      }),
    );
  };

  useEffect(() => {
    fetchData();
  }, [searchData]);

  const handleSearch = (value) => {
    pagination.current = 1;
    setSearchData({ ..._.omitBy(value, (o) => _.isNil(o) || _.isEmpty(o)) });

    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOpenModal = () => {
    setVisible(true);
  };

  const handleExcelSummaryDownload = async (
    filename = 'print_log_report.xlsx',
    params,
  ) => {
    setSummaryLoading(true);

    await request.download('/print_log', filename, params);
    setSummaryLoading(false);
  };
  const handleOpenDrawerExport = () => {
    setDrawerExportVisible(true);
  };
  const handleCloseDrawerExport = () => {
    setDrawerExportVisible(false);
  };

  const columns = [
    {
      title: 'หมายเลขตู้',
      key: 'serial_number',
      dataIndex: 'serial_number',
      sorter: true,
      defaultSortOrder: 'ascend',
    },
    {
      title: 'รหัสตู้',
      key: 'kiosk_code',
      dataIndex: 'kiosk_code',
    },
    {
      title: 'รายละเอียดสถานที่ติดตั้ง',
      key: 'detail',
      dataIndex: 'detail',
    },
    {
      title: 'สถานะ',
      key: 'status',
      dataIndex: 'status',
      render: (status, o) => (
        isAllow(userProfile.menuPermissions) ? (
          <PopOverTag onStatusChange={onStatusChange} kiosk={o} />
        ) : (
          <Tag color={status ? 'green' : 'red'}>{status ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}</Tag>
        )
      ),
    },
    {
      title: 'สถานะของเครื่อง',
      key: 'id',
      dataIndex: 'kioskStatus',
      render: (active, o) => {
        let statusPayTaxColor = 'red';
        let statusPayTaxText = 'ออฟไลน์';
        if (_.find(kiosks, (k) => k.kiosk === o.id)) {
          statusPayTaxColor = 'green';
          statusPayTaxText = 'ออนไลน์';
        }
        return <Tag color={statusPayTaxColor}>{statusPayTaxText}</Tag>;
      },
    },
    {
      title: 'ปริมาณกระดาษคงเหลือ (แผ่น)',
      key: 'paper_count',
      dataIndex: 'paper_count',
      render: (paperCount, o) => {
        const paperMin = o.paper_min;
        const style = paperMin >= paperCount ? { color: 'red' } : {};
        return <span style={{ ...style }}>{paperCount}</span>;
      },
    },
    {
      title: 'ปริมาณหมึกพิมพ์คงเหลือที่สามารถพิมพ์ได้ (แผ่น)',
      key: 'ink_count',
      dataIndex: 'ink_count',
      render: (inkCount, o) => {
        const inkMin = o.ink_min;
        const style = inkMin >= inkCount ? { color: 'red' } : {};
        return <span style={{ ...style }}>{inkCount}</span>;
      },
    },
    {
      title: 'สถานะหมึกพิมพ์',
      key: 'ink_status',
      dataIndex: 'ink_status',
      render: (status, o) => {
        let statusPayTaxColor = 'red';
        let statusPayTaxText = 'ปริมาณต่ำ/ไม่พร้อมทำการพิมพ์';
        if (status === 'ready') {
          statusPayTaxColor = 'green';
          statusPayTaxText = 'พร้อมทำการพิมพ์';
        }
        return <Tag color={statusPayTaxColor}>{statusPayTaxText}</Tag>;
      },
    },
    {
      title: 'ที่ตั้ง',
      key: 'lat-lon',
      width: 60,
      render: (record) => (
        <a
          href={`https://www.google.co.th/maps/place?q=${record.location.lat},${record.location.lng}`}
          target="_blank"
        >
          <GoogleMapIcon width={20} />
          {/* <>
            {`lat: ${record.location.lat}`}
            <br />
            {`lng: ${record.location.lng}`}
          </> */}
        </a>
      ),
    },
    {
      title: isAllow(userProfile.menuPermissions) ? 'จัดการ' : '',
      width: isAllow(userProfile.menuPermissions) ? '10%' : '0px',
      dataIndex: 'id',
      fixed: 'right',
      render: (id, o) => (
        isAllow(userProfile.menuPermissions) && (
          <span>
            <a
              disabled={_.get(o, ['status']) === 'canceled'}
              onClick={openSecretModal(id)}
            >
              secret key
            </a>
            <Divider type="vertical" />
            <Link
              onClick={(e) => {
                e.preventDefault();
                setKioskId(id);
              }}
            // to={`/kiosk/${id}`}
            // to={`/kiosk/${id}`}
            >
              แก้ไข
            </Link>
          </span>
        )
      ),
      width: isAllow(userProfile.menuPermissions) ? '150px' : '0px',
    },
  ];
  return (
    <>
      <HeaderBlock style={{ fontSize: '20px', fontWeight: 'bold' }}>
        บันทึกจัดการตู้ชำระภาษีรถ (Kiosk)
      </HeaderBlock>
      <HeaderBlock>
        <div>
          <Button type="primary" onClick={handleOpenModal}>
            ค้นหา
          </Button>
          {isAllow(userProfile.menuPermissions) && (
            <Button
              style={{ margin: '0 0 0 16px' }}
              type="primary"
              onClick={handleAdd}
            >
              เพิ่ม
            </Button>
          )}
        </div>
        {isAllow(userProfile.menuPermissions) && (
          <Button
            style={{ margin: '0 0 0 16px' }}
            icon="export"
            onClick={handleOpenDrawerExport}
          >
            Export รายงานการวิเคราะห์ปริมาณการพิมพ์
          </Button>
        )}
      </HeaderBlock>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: window.innerWidth < 500 ? 'max-content' : 'false', y: window.innerHeight-470 }}
      />
      <DrawerExport
        filename="print_log_report"
        visible={drawerExportVisible}
        onClose={handleCloseDrawerExport}
        handleExcelSummaryDownload={handleExcelSummaryDownload}
        confirmLoading={summaryLoading}
      />
      <FilterModal
        visible={visible}
        handleSearch={handleSearch}
        handleCancel={handleCancel}
        renderInputFields={searchKey}
      />
      <Modal
        title="Secret Key"
        visible={secretVisible}
        onCancel={closeSecretModal}
        style={{ minWidth: '800px', top: '20px', minHeight: '800px' }}
        footer={[
          <Popconfirm
            key="renew"
            title="กรุณายืนยันการตั้งค่า secret key ใหม่"
            onConfirm={renewSecret(_.get(secretKey, ['id']))}
            okText="ตกลง"
            cancelText="ยกเลิก"
          >
            <Button type="danger" loading={modalLoading}>
              Renew
            </Button>
          </Popconfirm>,
          <Button key="back" onClick={closeSecretModal} loading={modalLoading}>
            ปิด
          </Button>,
        ]}
      >
        <Title level={3}>Kiosk id</Title>
        <TextArea rows={1} value={_.get(secretKey, ['id'])} />
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            onClick={handleCopyClipboard(_.get(secretKey, ['id']), 'kiosk_id')}
            loading={modalLoading}
          >
            คัดลอก
          </Button>
        </div>
        <LongLine />
        <Title level={3}>Access Token</Title>
        <TextArea rows={3} value={_.get(secretKey, ['access_token'])} />
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            onClick={handleCopyClipboard(
              _.get(secretKey, ['access_token']),
              'access_token',
            )}
            loading={modalLoading}
          >
            คัดลอก
          </Button>
        </div>
        <LongLine />
        <Title level={3}>Private Key</Title>
        <TextArea rows={3} value={_.get(secretKey, ['private_key'])} />
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            style={{
              marginRight: '20px',
            }}
            onClick={handleDownloadSecret(
              _.get(secretKey, ['private_key']),
              'private',
            )}
            loading={modalLoading}
          >
            ดาวน์โหลด
          </Button>
          <Button
            onClick={handleCopyClipboard(
              _.get(secretKey, ['private_key']),
              'private_key',
            )}
            loading={modalLoading}
          >
            คัดลอก
          </Button>
        </div>
        <LongLine />
        <Title level={3}>Public Key</Title>
        <TextArea rows={3} value={_.get(secretKey, ['public_key'])} />
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            style={{
              marginRight: '20px',
            }}
            onClick={handleDownloadSecret(
              _.get(secretKey, ['public_key']),
              'public',
            )}
            loading={modalLoading}
          >
            ดาวน์โหลด
          </Button>
          <Button
            onClick={handleCopyClipboard(
              _.get(secretKey, ['public_key']),
              'public_key',
            )}
            loading={modalLoading}
          >
            คัดลอก
          </Button>
        </div>
        <LongLine />
      </Modal>
      <Detail
        fetchTableData={fetchData}
        kioskId={kioskId}
        setKioskId={setKioskId}
      />
    </>
  );
};
