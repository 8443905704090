import React, { useContext, useState } from 'react';
import { Menu, Icon, Badge, AutoComplete, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { MainContext } from '../../context/main';
import { MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons';

export default ({ page }) => {
    const [openKeys, setOpenKeys] = useState([]);
    const onSubMenuOpenChange = (keys) => {
        setOpenKeys(keys);
    };
    const { userProfile, alertStatus } = useContext(MainContext);
    const hasPermission = (menuId) => {
        const menuPermissions = _.get(userProfile, ['menuPermissions']);
        const permission = _.find(menuPermissions, { menuId });
        return permission && permission.canView;
    };


    return (
        <Menu
            key={page}
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[page]}
            openKeys={openKeys}
            onOpenChange={onSubMenuOpenChange} // Call this handler when sub-menu opens/closes
            style={{}}
        >
            {(hasPermission('1')) ? (
                <Menu.Item key="taxlist">
                    <Tooltip title="สอบถามประวัติชำระภาษีรถประจำปีผ่าน DLT Vehicle Tax Plus และตู้ชำระภาษีประจำปี" placement="right">
                        <Link to="/taxlist">
                            <Icon type="file-text" />
                            <span className="nav-text">รายการชำระภาษี</span>
                        </Link>
                    </Tooltip>
                </Menu.Item>
            ) : (
                // <Menu.Item key="taxlist">
                //     <Tooltip title="สอบถามประวัติชำระภาษีรถประจำปีผ่าน DLT Vehicle Tax Plus และตู้ชำระภาษีประจำปี">
                //         <Link to="/taxlist">
                //             <Icon type="file-text" />
                //             <span className="nav-text">รายการชำระภาษี</span>
                //         </Link>
                //     </Tooltip>
                // </Menu.Item>
                <></>
            )}
            {/* {hasPermission('2') && (
                <Menu.Item key="carmanual">
                    <Tooltip title="สอบถามประวัติเข้าถึงใบคู่มือจดทะเบียนรถรูปแบบอิเล็กทรอนิกส์">
                        <Link to="/carmanual">
                            <Icon type="file-text" />
                            <span className="nav-text">การบริหารจัดการใบคู่มือ</span>
                        </Link>
                    </Tooltip>
                </Menu.Item>
            )} */}
            {hasPermission('3') && (
                <Menu.Item key="ownercert">
                    <Tooltip title="บันทึกพิจารณารายการคำขอใบรับรองจดทะเบียนรถรูปแบบอิเล็กทรอนิกส์" placement="right">
                        <Link to="/ownercert">
                            <Icon type="file-text" />
                            <span className="nav-text">การบริหารจัดการใบรับรอง</span>
                        </Link>
                    </Tooltip>
                </Menu.Item>
            )}
            {hasPermission('4') && (
                <Menu.Item key="kiosk">
                    <Tooltip title="บันทึกจัดการตู้ชำระภาษีรถ (Kiosk)" placement="right">
                        <Link to="/kiosk">
                            {alertStatus && (
                                <Badge
                                    style={{ width: '10px', height: '10px' }}
                                    offset={[15, -10]}
                                    status="error"
                                >
                                    <span className="head-example" />
                                </Badge>
                            )}
                            <Icon type="desktop" />
                            <span className="nav-text">ตู้ชำระภาษีรถ</span>
                        </Link>
                    </Tooltip>
                </Menu.Item>
            )}
            {hasPermission('5') && (
                <Menu.Item key="usersreport">
                    <Tooltip title="บันทึกตอบปัญหาการใช้งานระบบ DLT Vehicle Tax Plus" placement="right">
                        <Link to="/usersreport">
                            <Icon type="tablet" />
                            <span className="nav-text">จัดการแจ้งปัญหา</span>
                        </Link>
                    </Tooltip>
                </Menu.Item>
            )}
            {hasPermission('6') && (
                <Menu.Item key="notifiers">
                    <Tooltip title="บันทึกจัดการแจ้งเตือนเปิด-ปิดระบบ DLT Vehicle Tax Plus" placement="right">
                        <Link to="/notifiers">
                            <Icon type="tablet" />
                            <span className="nav-text">จัดการแอพ DLT Vehicle Tax Plus</span>
                        </Link>
                    </Tooltip>
                </Menu.Item>
            )}
            {hasPermission('7') && (
                <Menu.Item key="users">
                    <Tooltip title="สอบถามข้อมูลผู้ลงทะเบียนใช้งานระบบ DLT Vehicle Tax Plus" placement="right">
                        <Link to="/users">
                            <Icon type="team" />
                            {/* <span className="nav-text">การจัดการบัญชีผู้ใช้งาน</span> */}
                            <span className="nav-text">รายการบัญชีผู้ลงทะเบียน</span>
                        </Link>
                    </Tooltip>
                </Menu.Item>
            )}
            {/* {hasPermission('8') && (
                <Menu.Item key="admins">
                    <Link to="/admins">
                        <Icon type="user" />
                        <span className="nav-text">การจัดการเจ้าหน้าที่</span>
                    </Link>
                </Menu.Item>
            )} */}
            {(hasPermission('9') || hasPermission('10')) && (

                <Menu.SubMenu key="permissions" title={
                    <span className="submenu-title-wrapper">
                        <Icon type="unlock" />
                        บันทึกจัดการผู้ใช้งานเข้ากลุ่มผู้ใช้งาน
                    </span>}
                >
                    {hasPermission('10') && (
                        <Menu.Item key="adminpermissionmanagement">
                            <Tooltip title="บันทึก/แก้ไข กลุ่มผู้ใช้งานและสิทธิ์ใช้งานระบบ" placement="right">
                                <Link to="/adminpermissionmanagement">
                                    <Icon type="team" />
                                    <span className="nav-text">กลุ่มผู้ใช้งานและสิทธิ์</span>
                                </Link>
                            </Tooltip>
                        </Menu.Item>
                    )}
                    {hasPermission('9') && (
                        <Menu.Item key="addminpermissionassign">
                            <Tooltip title="บันทึกจัดการผู้ใช้งานเข้ากลุ่มผู้ใช้งาน" placement="right">
                                <Link to="/addminpermissionassign">
                                    <Icon type="unlock" />
                                    <span className="nav-text">ผู้ใช้งานและกลุ่ม</span>
                                </Link>
                            </Tooltip>
                        </Menu.Item>
                    )}
                </Menu.SubMenu>
            )}
            {hasPermission('11') && (
                <Menu.Item key="elearningmanagement">
                    <Tooltip title="บันทึกจัดการสื่อออนไลน์แนะนำกระบวนงานด้านทะเบียนรถ" placement="right">
                        <Link to="/elearningmanagement">
                            <Icon type="youtube" />
                            <span className="nav-text">สื่อออนไลน์</span>
                        </Link>
                    </Tooltip>
                </Menu.Item>
            )}
            {hasPermission('8') && (
                <Menu.Item key="systemmanual">
                    <Tooltip title="บันทึกจัดการจัดการคู่มือใช้งานระบบ" placement="right">
                        <Link to="/systemmanual">
                            <Icon type="youtube" />
                            <span className="nav-text">จัดการคู่มือใช้งานระบบ</span>
                        </Link>
                    </Tooltip>
                </Menu.Item>
            )}
            {hasPermission('12') && (
                <Menu.Item key="tos">
                    <Tooltip title="บันทึก/แก้ไขข้อความของประกาศนโยบายความเป็นส่วนตัว (Privacy Notice)" placement="right">
                        <Link to="/tos">
                            <Icon type="user" />
                            <span className="nav-text">นโยบายความเป็นส่วนตัว</span>
                        </Link>
                    </Tooltip>
                </Menu.Item>
            )}
            {(hasPermission('14') || hasPermission('15') || hasPermission('18') || hasPermission('19') || hasPermission('20') || hasPermission('21')) && (
                <Menu.SubMenu key="logs" title={
                    <span className="submenu-title-wrapper">
                        <Icon type="unordered-list" />
                        สอบถามข้อมูลประวัติ (Log)
                    </span>}
                >
                    {hasPermission('14') && (
                        <Menu.Item key="logs">
                            <Tooltip title="สอบถามประวัติ (Log) การทำงานของระบบต่าง ๆ" placement="right">
                                <Link to="/logs">
                                    <Icon type="setting" />
                                    <span className="nav-text">ประวัติการทำงานของระบบต่างๆ</span>
                                </Link>
                            </Tooltip>
                        </Menu.Item>
                    )}
                    {hasPermission('15') && (
                        <Menu.Item key="userlogs" style={{ height: 80 }}>
                            <Tooltip title="สอบถามประวัติ (Log) การใช้งานของโมบายแอพพลิเคชัน (Application)" placement="right">
                                <Link to="/userlogs">
                                    <Icon type="user" />
                                    <span className="nav-text">
                                        ประวัติการใช้งาน Mobile App
                                        <br />
                                        <Icon type="none" /> และ Kiosk
                                    </span>
                                </Link>
                            </Tooltip>
                        </Menu.Item>
                    )}
                    {hasPermission('18') && (
                        <Menu.Item key="adminlogs">
                            <Tooltip title="สอบถามประวัติ (Log) การใช้งานระบบบริหารจัดการ DLT Vehicle Tax Plus (Back Office)" placement="right">
                                <Link to="/adminlogs">
                                    <Icon type="user" />
                                    <span className="nav-text">ประวัติการใช้งานระบบบริหารจัดการฯ</span>
                                </Link>
                            </Tooltip>
                        </Menu.Item>
                    )}
                    {hasPermission('20') && (
                        <Menu.Item key="transactionlogs">
                            <Tooltip title="สอบถามประวัติ (Log) ของการชำระภาษีประจำปี" placement="right">
                                <Link to="/transactionlogs">
                                    <Icon type="file-text" />
                                    <span className="nav-text">ประวัติการชำระภาษีประจำปี</span>
                                </Link>
                            </Tooltip>
                        </Menu.Item>
                    )}
                    {hasPermission('21') && (
                        <Menu.Item key="carmanuallogs">
                            <Tooltip title="สอบถามประวัติ (Log) การเข้าถึงใบคู่มือจดทะเบียนรถรูปแบบอิเล็กทรอนิกส์" placement="right">
                                <Link to="/carmanuallogs">
                                    <Icon type="file-text" />
                                    <span className="nav-text">ประวัติการเข้าถึงใบคู่มือ</span>
                                </Link>
                            </Tooltip>
                        </Menu.Item>
                    )}
                    {hasPermission('19') && (
                        <Menu.Item key="ownercerlogs" style={{ height: 80 }}>
                            <Tooltip title="สอบถามประวัติ (Log) การขอใบรับรองการจดทะเบียนรูปแบบอิเล็กทรอนิกส์" placement="right">
                                <Link to="/ownercerlogs">
                                    <Icon type="file-text" />
                                    <span className="nav-text">
                                        ประวัติการขอใบรับรอง
                                        <br />
                                        <Icon type="none" /> การจดทะเบียนรถฯ</span>
                                </Link>
                            </Tooltip>
                        </Menu.Item>
                    )}
                </Menu.SubMenu>
            )}
            {(hasPermission('13') || hasPermission('16') || hasPermission('17')) && (
                <Menu.SubMenu key="reports" title={
                    <span className="submenu-title-wrapper">
                        <Icon type="file-text" />
                        รายงาน
                    </span>}
                >
                    {hasPermission('13') && (
                        <Menu.Item key="historical">
                            <Tooltip title="ข้อมูลการชำระเงินจากธนาคาร" placement="right">
                                <Link to="/historical">
                                    <Icon type="file-text" />
                                    <span className="nav-text">ข้อมูลการชำระเงินจากธนาคาร</span>
                                </Link>
                            </Tooltip>
                        </Menu.Item>
                    )}
                    {hasPermission('16') && (
                        <Menu.Item key="survey">
                            <Tooltip title="จัดการแบบสำรวจความพึงพอใจในการใช้งานระบบ" placement="right">
                                <Link to="/survey">
                                    <Icon type="smile" />
                                    <span className="nav-text">ข้อมูลการสำรวจความพึงพอใจ</span>
                                </Link>
                            </Tooltip>
                        </Menu.Item>
                    )}
                    {hasPermission('17') && (
                        <Menu.Item key="notifierspersonal">
                            <Tooltip title="สอบถามรายการรถที่ได้แจ้งเตือนก่อนวันครบกำหนดชำระภาษี 90 วัน/แจ้งเตือนในวันครบกำหนด/แจ้งเตือนตรวจสภาพรถก่อนชำระภาษี" placement="right">
                                <Link to="/notifierspersonal">
                                    <Icon type="bell" />
                                    <span className="nav-text">ข้อมูลการแจ้งเตือนต่างๆ</span>
                                </Link>
                            </Tooltip>
                        </Menu.Item>
                    )}
                </Menu.SubMenu>
            )}
        </Menu>
    );
};
