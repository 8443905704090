import React, { useEffect, useContext, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import get from 'lodash/get';
import map from 'lodash/map';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import toSafeInteger from 'lodash/toSafeInteger';
import moment from 'moment';
import styled from 'styled-components';
import {
  Spin,
  Descriptions,
  Button,
  Card,
  Row,
  Col,
  Modal,
  List,
  Popconfirm,
  Drawer,
  Icon,
  Input,
  Typography,
} from 'antd';
import { numberWithCommas } from '../../utils/form';
import { chan } from '../../utils';

import { MainContext } from '../../context/main';
import { LongLine } from '../../style/global';

import { convertToDatetimeBE } from '../../utils'

import swal from 'sweetalert2';


const { Meta } = Card;
const { Text } = Typography;
export default ({ transactionId, setTransactionId }) => {
  const history = useHistory();

  // const { transactionId } = useParams();

  const { request } = useContext(MainContext);

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState([]);


  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await request.get(`/transactions/${transactionId}`, {
        includes: ['confirmed', 'ownerCertDoc'],
      });

      const result = get(res, ['result'], {});

      setData(result);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  const back = () => {
    setTransactionId('');
    setData({});
    // history.push('/transactions');
  };

  const [note, setNote] = useState('');
  const [visible, setVisible] = useState(false);
  const [isApproving, setIsApproving] = useState(true);
  const handleOk = () => {
    setLoading(true);
    const res = request
      .post(`/transactions/approval/${transactionId}`, {
        approval: isApproving,
        note: note, // Include the note in the request payload
      })
      .then((res) => {
        if (get(res, ['success'])) {
          fetchData();
        }
        setLoading(false);
        setVisible(false); // Close the popup
      });
  };


  const handleCancelNote = () => {
    setNote(''); // Reset the note value
    setVisible(false); // Close the popup without sending the note
  };

  const openModal = () => {
    setVisible(true); // Show the popup
  };

  const openModalApprove = () => {
    setIsApproving(true); // Set the isApproving value
    setVisible(true); // Show the popup
  };

  const openModalDecline = () => {
    setIsApproving(false); // Set the isApproving value
    setVisible(true); // Show the popup
  };

  const handleChangeNote = (e) => {
    setNote(e.target.value); // Update the note value as the user types
  };


  const approvalDecline = () => {
    setLoading(true);
    const res = request.post(`/transactions/approval/${transactionId}`, {
      approval: false,
    })
      .then((res) => {
        if (get(res, ['success'])) {
          fetchData();
        }
      });
    setLoading(false);
  };

  const recallAddReceiptData = () => {
    const res = request.post(`/transactions/repair_addReceipt/${transactionId}`, {})
      .then((res) => {
        if (get(res, ['success'])) {
          fetchData();
        }
      });
    console.log("Use recallAddReceiptData ")
  }


  useEffect(() => {
    if (transactionId) {
      fetchData();
    }
  }, [transactionId]);

  const remarkColor = (text, o) => {
    if (!text) {
      if (
        moment(moment(get(o, ['created'], null)).format('YYYY-MM-DD')).isBefore(
          moment().format('YYYY-MM-DD'),
        )
      ) {
        return (
          <span style={{ color: 'red' }}>
            ไม่ทำการชำระภายในเวลาที่กำหนด (ภายในวันที่ทำรายการ)
          </span>
        );
      }

      return text;
    }

    if (text.indexOf('Error') !== -1 || text.indexOf('[error]') !== -1) {
      return <span style={{ color: 'red' }}>{text}</span>;
    }

    return <span style={{ color: 'green' }}>{text}</span>;
  };

  var ownerCertDoc = get(data, 'ownerCertDoc');

  const openFile = (fileBase64, fileName) => {
    try {
      const byteCharacters = atob(fileBase64.split(',')[1]);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const extension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
      let mimeType;

      switch (extension) {
        case 'jpg':
        case 'jpeg':
          mimeType = 'image/jpeg';
          break;
        case 'png':
          mimeType = 'image/png';
          break;
        case 'pdf':
          mimeType = 'application/pdf';
          break;
        default:
          // mimeType = 'application/octet-stream';
          mimeType = 'image/jpeg';
          break;
      }

      const blob = new Blob(byteArrays, { type: mimeType });
      const fileUrl = URL.createObjectURL(blob);

      window.open(fileUrl);
    } catch (error) {
      // show error message
      swal.fire({
        title: 'ไม่สามารถเปิดไฟล์ได้',
        text: 'ไฟล์อาจเสียหาย หรือการอัพโหลดไม่สมบูรณ์',
        icon: 'error',
        confirmButtonText: 'ปิด',
      });
      console.error(error);
    }
  };

  const renderFileItem = (item) => (
    <List.Item style={styles.fileItem}>
      <div style={styles.leftContent}>
        <Icon type="file" style={styles.fileIcon} />
        <span style={styles.fileName}>{item.file_name}</span>
      </div>
      { // if item.file_base64 is curupted then not show button


      }
      <div style={styles.rightContent}>
        <Button type="link" onClick={() => openFile(item.file_base64, item.file_name)}>
          เรียกดู
        </Button>
      </div>
    </List.Item>
  );

  const renderEmpty = () => (
    <div style={styles.emptyContainer}>
      ไม่มีเอกสารประกอบ
    </div>
  );

  return (
    <>
      <Drawer
        visible={!!transactionId}
        width="100%"
        getContainer={() => document.getElementById('main-content-layout')}
        // getContainer={false}
        style={{ position: 'absolute' }}
        onClose={back}
      >
        <Spin spinning={loading}>
          <h2>
            รายการคำขอใบรับรองการจดทะเบียนรถรูปแบบอิเล็กทรอนิกส์
          </h2>
          <LongLine />
          <Descriptions
            title="รายละเอียดรถ"
            layout="horizontal"
          >
            <Descriptions.Item label="ประเภทรถ">
              {get(data, 'vehTypeCodeDesc')}
            </Descriptions.Item>
            <Descriptions.Item label="ทะเบียน">
              {`${get(data, 'plate1', '')}${get(data, 'vehTypeCodeDesc') == null ? "-" : " "}${toSafeInteger(
                get(data, 'plate2', ''),
              )}`}
            </Descriptions.Item>
            <Descriptions.Item label="จังหวัด">
              {get(data, 'offRegDesc')}
            </Descriptions.Item>
            <Descriptions.Item label="ยี่ห้อ">
              {get(data, ['carinfo', 'BRAND'])}
            </Descriptions.Item>
            <Descriptions.Item label="สี">
              {get(data, ['carinfo', 'COLOR'])}
            </Descriptions.Item>
            <Descriptions.Item label="เลขประจำตัวประชาชนเจ้าของรถ">
              {get(data, 'ownIdNo')}
            </Descriptions.Item>
            <Descriptions.Item label="วันสิ้นอายุ">
              {get(data, 'THAI_EXP_DATE')}
            </Descriptions.Item>
          </Descriptions>
          <LongLine />

          <Descriptions title="รายละเอียดคำขอ" layout="horizontal">
            <Descriptions.Item label="หมายเลขอ้างอิง">
              {get(data, 'reference_1')}
            </Descriptions.Item>
            {/* <Descriptions.Item label="หมายเลขอ้างอิง 2">
              {get(data, 'reference_2')}
            </Descriptions.Item> */}
            <Descriptions.Item label="ชื่อ-นามสกุล">
              {get(data, ['transaction_channel']) !== 'kiosk'
                ? get(data, 'fname')
                : 'ไม่ระบุ'}
              &nbsp;
              {get(data, ['transaction_channel']) !== 'kiosk'
                ? get(data, 'lname')
                : 'ไม่ระบุ'}
            </Descriptions.Item>
            <Descriptions.Item>
            </Descriptions.Item>
            <Descriptions.Item label="วันเวลายื่นคำขอ">
              {get(data, 'created') &&
                convertToDatetimeBE(get(data, 'created'))}
            </Descriptions.Item>
            <Descriptions.Item label="ค่าธรรมเนียมที่จัดเก็บ">
              {numberWithCommas(data, 'amount')} บาท
            </Descriptions.Item>
          </Descriptions>
          <LongLine />
          <Descriptions title="รายการเอกสารหลักฐานประกอบคำขอ" layout="horizontal">
            <Descriptions.Item span={3}>
              {ownerCertDoc?.length > 0 ? (
                <List
                  dataSource={ownerCertDoc}
                  renderItem={renderFileItem}
                  itemLayout="vertical"
                  style={styles.fileList}
                />
              ) : (
                renderEmpty()
              )}
            </Descriptions.Item>
          </Descriptions>
          <LongLine />

          <Descriptions title="รายละเอียดการพิจารณา/อนุมัติ" layout="horizontal">
            <Descriptions.Item label="ชื่อ-นามสกุล">
              {get(data, 'approveDetail.firstName')}  {get(data, 'approveDetail.lastName')}
            </Descriptions.Item>
            <Descriptions.Item label="ตำแหน่ง">
              {get(data, 'approveDetail.position')}
            </Descriptions.Item>
            <Descriptions.Item label="รหัสสำนักงาน">
              {get(data, 'approveDetail.officeCode')}
            </Descriptions.Item>
            <Descriptions.Item label="ชื่อสำนักงาน">
              {get(data, 'approveDetail.officeName')}
            </Descriptions.Item>
            <Descriptions.Item label="ผลการพิจารณา">
              {get(data, 'is_approved', '') !== ''
                ? 'อนุมัติแล้ว'
                : 'ยังไม่อนุมัติ'}
            </Descriptions.Item>
            <Descriptions.Item label="วันเวลาพิจารณา/อนุมัติ">
              {get(data, 'approveDetail.approveDate') &&
                convertToDatetimeBE(get(data, 'approveDetail.approveDate'))}
            </Descriptions.Item>
            {/* <Descriptions.Item label="ผลการตรวจสอบจากระบบฐานข้อมูลกลาง (MDM)" span={3}>
              *ขาดข้อมูลส่วนนี้*
            </Descriptions.Item> */}

            <Descriptions.Item label="หมายเหตุการพิจารณา/อนุมัติ" span={3}>
              {get(data, 'approveDetail.approveNote')}
            </Descriptions.Item>
          </Descriptions>
          <LongLine />

          <Descriptions
            title="สถานะการชำระเงิน"
            // layout="vertical"
            layout="horizontal"
          >
            <Descriptions.Item label="สถานะการชำระเงิน">
              {get(data, 'confirmation_id', '') !== ''
                ? 'ชำระแล้ว'
                : 'ยังไม่ชำระ'}
            </Descriptions.Item>
            {get(data, 'confirmation_id') && (
              <>
                {/* <LongLine /> */}
                <Descriptions.Item label="tranID">
                  {get(data, ['confirmation_id', 'tranID'])}
                </Descriptions.Item>
                <Descriptions.Item label="วันเวลาที่ชำระเงิน">
                  {get(data, ['confirmation_id', 'created']) &&
                    convertToDatetimeBE(get(data, ['confirmation_id', 'created']))
                  }
                </Descriptions.Item>
                <Descriptions.Item label="ช่องทางการชำระ">
                  {get(chan, [
                    get(data, ['confirmation_id', 'channel'], 'ไม่ระบุ'),
                  ])}
                </Descriptions.Item>
                <Descriptions.Item label="reference1">
                  {get(data, ['confirmation_id', 'reference1'])}
                </Descriptions.Item>
                <Descriptions.Item label="reference2">
                  {get(data, ['confirmation_id', 'reference2'])}
                </Descriptions.Item>
                <Descriptions.Item label="ยอดชำระ">
                  {get(data, ['confirmation_id', 'amount'])}
                </Descriptions.Item>
                <Descriptions.Item label="หมายเหตุ">
                  {remarkColor(
                    get(data, ['confirmation_id', 'remark'], ''),
                    data,
                  )}
                </Descriptions.Item>
              </>
            )}
          </Descriptions>
          <LongLine />

          <Row justify="space-between">
            <Col sm={12}>
              <Button type="danger" ghost onClick={back}>
                ย้อนกลับ
              </Button>
            </Col>
            <Col sm={12} style={{ textAlign: "right" }}>
              <Button type="primary" onClick={openModalApprove}>
                อนุมัติ
              </Button>
              <Button type="danger" onClick={openModalDecline}>
                ไม่อนุมัติ
              </Button>
            </Col>
          </Row>
        </Spin>
      </Drawer>
      <Modal
        title={
          <Text strong>{isApproving ? "การอนุมัติคำขอ" : "หมายเหตุการไม่อนุมัติ"}</Text> // Add bold text to the title
        }
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancelNote}
        confirmLoading={loading}
        okText="บันทึก" // Set the label of the "OK" button to "บันทึก"
        cancelText="ยกเลิก" // Set the label of the "Cancel" button to "ยกเลิก"
      >
        {isApproving ?
          <>ท่านต้องการที่จะอนุมัติคำขอนี้ใช่ไหม</>
          :
          <Input.TextArea
            value={note}
            onChange={handleChangeNote}
            autoSize={{ minRows: 5, maxRows: 5 }} // Set the number of visible rows to 5
            resize="none" // Make the TextArea non-resizable
          />
        }
      </Modal>
    </>
  );
};

const TextH3 = styled.span`
  font-size: 1.17em;
`;
const TextH5 = styled.span`
  font-size: 0.83em;
`;

const PromotionText = styled.span`
  color: red;
`;

const styles = {
  fileListContainer: {
    width: '100%',
    maxWidth: '100%',
  },
  fileList: {
    width: '100%',
    maxWidth: '100%',
  },
  fileItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    marginBottom: '8px',
  },
  leftContent: {
    display: 'flex',
    alignItems: 'center',
  },
  fileIcon: {
    fontSize: '48px', // Adjust the icon size as desired
    marginRight: '8px',
  },
  fileName: {
    fontWeight: 'bold',
  },
  rightContent: {
    marginLeft: 'auto',
  },
  emptyContainer: {
    textAlign: 'center',
    padding: '16px',
    fontSize: '16px',
    color: '#999',
  },
};