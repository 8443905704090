import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { convertToDatetimeBE } from '../../utils'
import { Table, Tag, Button, Icon, Divider, Popconfirm, Popover, Spin } from 'antd';
import { TagContainer, ButtonTag } from './style';
import _ from 'lodash';
import { MainContext } from '../../context/main';

import {
  getFilter,
  getSort,
  getPage,
  getColumnSearchProps,
  perPage,
  defaultPageSize,
  pageSizeOptions,
  showSizeChanger,

} from '../../utils/table';
import { formatPhoneNumber } from '../../utils';
import { HeaderBlock } from '../../style/global';
import FilterModal from '../../component/FilterModal';
import FormModal from './form';
import SettingFromModal from './settingform';

const isAllow = (menuPermissions, targetmenuPermissions) => {
  const thisMenuId = "11"
  const permission = _.find(menuPermissions, { menuId: thisMenuId });
  return permission && permission.canManage;
};

function getColor(status) {
  switch (status) {
    case true:
      return 'green';
    case false:
      return 'orange';
    default:
      return '';
  }
}

function getWord(status) {
  switch (status) {
    case true:
      return 'เปิดใช้งาน';
    case false:
      return 'ปิดใช้งาน';
    default:
      return 'ไม่พร้อมใช้งาน';
  }
}
const PopOverTag = ({ elearning, onStatusChange }) => {
  const currentStatus = _.get(elearning, ['isActive']);
  const color = getColor(currentStatus);
  const [visible, setVisible] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const { request } = useContext(MainContext);

  const onVisibleChange = (e) => {
    setVisible((prevState) => !prevState);
  };

  const handleSelect = (newStatus) => async (e) => {
    if (newStatus === currentStatus) return;

    const body = {
      isActive: newStatus,
    };
    setIsLoading(true);

    const res = await request.put(`/elearningmanagement/${elearning.id}/isActive`, body);

    setIsLoading(false);

    if (_.get(res, ['success'])) {
      onStatusChange({
        id: elearning.id,
        newStatus,
      });
    }

    onVisibleChange();
  };

  return (
    <Popover
      content={(
        <Spin spinning={isLoading}>
          <TagContainer>
            <ButtonTag underline onClick={handleSelect(true)}>
              <Tag color={currentStatus === true ? 'grey' : 'green'}>
                {getWord(true)}
              </Tag>
            </ButtonTag>
            <ButtonTag underline onClick={handleSelect(false)}>
              <Tag color={currentStatus === false ? 'grey' : 'orange'}>
                {getWord(false)}
              </Tag>
            </ButtonTag>
          </TagContainer>
        </Spin>
      )}
      title="สถานะการเปิดใช้งาน"
      trigger="click"
      visible={visible}
      onVisibleChange={onVisibleChange}
    >
      <ButtonTag>
        <Tag color={color}>
          {' '}
          {getWord(currentStatus)}
        </Tag>
      </ButtonTag>
    </Popover>
  );
};
export default () => {
  const { request, userProfile } = useContext(MainContext);
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState({});
  const [sorter, setSorter] = useState({
    sort: { key: 'created', desc: false },
  });
  const [pagination, setPagination] = useState({
    // pageSize: perPage,
    defaultPageSize,
    pageSizeOptions,
    showSizeChanger,
  });
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [targetELearning, setTargetELearning] = useState(null);

  const fetchCategory = async () => {
    try {
      const response = await request.get('/elearningmanagement/setting');
      const data = response.results;
      console.log(data);

      // Update the options array with the fetched data
      const updatedOptions = [
        { title: 'ทั้งหมด', value: '' },
        ...data.map(item => ({ title: item.title, value: item.id })),
      ];

      // Update the searchKey object with the new options array
      setSearchKey(prevSearchKey => ({
        ...prevSearchKey,
        2: {
          ...prevSearchKey[2],
          options: updatedOptions,
        },
      }));
    } catch (error) {
      console.error('Error fetching Category:', error);
      // Handle the error case
    }
  };

  const [searchKey, setSearchKey] = useState([
    { label: 'ชื่อสื่อออนไลน์', key: 'title' },
    {
      label: 'สถานะ',
      key: 'isActive',
      type: 'select',
      options: [
        { title: 'ไม่ระบุ', value: '' },
        { title: 'เปิดใช้งาน', value: 'true' },
        { title: 'ปิดใช้งาน', value: 'false' },
      ],
    },
    {
      label: 'ประเภทสื่อออนไลน์',
      key: 'categoryId',
      type: 'select',
      options: [], // Initially empty
    },
  ]);

  useEffect(() => {
    if (visible) {
      fetchCategory();
    }
  }, [visible]);

  const fetchData = async (params = {}) => {
    try {
      setLoading(true);
      const res = await request.get('/elearningmanagement', {
        ...params,
        ...searchData,
      });

      const totalData = _.get(res, ['total_data'], 0);

      setData(_.get(res, ['results'], []));
      setPagination({ ...pagination, total: totalData, current: params.page, pageSize: params.per_page });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (elearningId) => async () => {
    try {
      setLoading(true);
      const res = await request.delete(`/elearningmanagement/${elearningId}`);

      if (_.get(res, ['success'])) {
        fetchData();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (paginationChange, filters, sorterChange) => {
    const pager = { ...pagination };
    pager.current = paginationChange.current;

    setPagination(pager);
    setSorter(getSort(sorterChange));
    console.log(pagination)
    fetchData({
      ...getFilter(filters),
      ...getSort(sorterChange),
      ...getPage(paginationChange),
    });
  };

  useEffect(() => {
    fetchData({
      ...getPage(pagination),
      ...sorter,
    });
  }, [searchData]);

  const handleSearch = (value) => {
    pagination.current = 1;
    setSearchData({ ..._.omitBy(value, (o) => _.isNil(o) || _.isEmpty(o)) });

    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOpenModal = () => {
    setVisible(true);
  };

  const handleOpenModalForm = (elearningmanagement) => (e) => {
    setTargetELearning(elearningmanagement);
    setFormVisible(true);
    // console.log(elearningmanagement);
  };

  const handleCloseModalForm = () => {
    setTargetELearning(null);
    setFormVisible(false);
  };
  const onStatusChange = ({ newStatus, id }) => {
    setData(
      _.map(data, (o) => {
        if (o.id === id) o.isActive = newStatus;

        return o;
      }),
    );
  };

  const columns = [
    // {
    //   title: 'เลือก',
    //   key: 'selected',
    //   dataIndex: '',
    // },
    {
      title: 'ลำดับ',
      key: 'index',
      width: '5%',
      dataIndex: 'index',
    },
    {
      title: 'ชื่อสื่อออนไลน์',
      key: 'title',
      width: '50%',
      render: (record) => `${record.title}`,
    },
    {
      title: 'วันเวลาเริ่มเผยแพร่',
      key: 'LimitTimeStartDate',
      width: '15%',
      dataIndex: 'LimitTimeStartDate',
      sorter: () => { },
      render: (v) => convertToDatetimeBE(v),
    },
    {
      title: 'วันเวลาสิ้นสุด',
      key: 'LimitTimeEndDate',
      width: '15%',
      dataIndex: 'LimitTimeEndDate',
      sorter: () => { },
      defaultSortOrder: 'ascend',
      render: (v, r) => {
        if (r.isLimitTime == false) {
          return 'ไม่กำหนด'
        }

        return convertToDatetimeBE(v)
      }
    },
    {
      title: 'สถานะ',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (status, o) => (
        isAllow(userProfile.menuPermissions) ? (
          <PopOverTag onStatusChange={onStatusChange} elearning={o} />
        ) : (
          <Tag color={status ? 'green' : 'orange'}>{status ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}</Tag>
        )
      ),
    },
    {
      title: isAllow(userProfile.menuPermissions) ? 'จัดการ' : '',
      width: isAllow(userProfile.menuPermissions) ? '10%' : '0px',
      key: 'action',
      render: (o) =>
        isAllow(userProfile.menuPermissions) ? (
          <span>
            <a
              onClick={
                isAllow(userProfile.menuPermissions, o.menuPermissions)
                  ? handleOpenModalForm(o)
                  : () => { }
              }
            >
              แก้ไข
            </a>
            <Divider type="vertical" />
            <Popconfirm
              key="delete"
              title="กรุณายืนยันการลบ สื่อออนไลน์ ดังกล่าว"
              onConfirm={handleDelete(_.get(o, ['id']))}
              okText="ตกลง"
              cancelText="ยกเลิก"
            >
              <a>ลบ</a>
            </Popconfirm>
          </span>
        ) : null,
    },
  ];

  const onSubmit = async ({
    title,
    description,
    image,
    url,
    isActive,
    isLimitTime,
    LimitTimeStartDate,
    LimitTimeStartTime,
    LimitTimeEndDate,
    LimitTimeEndTime,
    thumbnailpath,
    categoryId,
  }) => {
    setFormLoading(true);
    let body = {};
    let res;
    try {
      if (targetELearning) {
        body.elearningId = _.get(targetELearning, ['id']);
        if (targetELearning.title !== title)
          body.title = title;
        if (targetELearning.description !== description)
          body.description = description;
        if (targetELearning.image !== image)
          body.image = image;
        if (targetELearning.url !== url)
          body.url = url;
        if (targetELearning.isActive !== isActive)
          body.isActive = isActive;
        if (targetELearning.isLimitTime !== isLimitTime)
          body.isLimitTime = isLimitTime;
        if (targetELearning.LimitTimeStartDate !== LimitTimeStartDate || targetELearning.LimitTimeStartTime !== LimitTimeStartTime) {
          const startTime = moment(LimitTimeStartTime, 'YYYY-MM-DD').format('HH:mm:ss');
          body.LimitTimeStartDate = moment(LimitTimeStartDate).format('YYYY-MM-DD') + ' ' + startTime;
        }
        if (targetELearning.LimitTimeEndDate !== LimitTimeEndDate || targetELearning.LimitTimeEndTime !== LimitTimeEndTime) {
          const endTime = moment(LimitTimeEndTime, 'YYYY-MM-DD').format('HH:mm:ss');
          body.LimitTimeEndDate = moment(LimitTimeEndDate).format('YYYY-MM-DD') + ' ' + endTime;
        }
        if (targetELearning.categoryId !== categoryId) {
          body.categoryId = categoryId;
        }
        res = await request.put('/elearningmanagement', body);
      } else {
        const startTime = moment(LimitTimeStartTime, 'YYYY-MM-DD').format('HH:mm:ss');
        LimitTimeStartDate = moment(LimitTimeStartDate).format('YYYY-MM-DD') + ' ' + startTime;
        const endTime = moment(LimitTimeEndTime, 'YYYY-MM-DD').format('HH:mm:ss');
        LimitTimeEndDate = moment(LimitTimeEndDate).format('YYYY-MM-DD') + ' ' + endTime;
        body = {
          title,
          description,
          image,
          url,
          isActive,
          isLimitTime,
          LimitTimeStartDate,
          LimitTimeEndDate,
          thumbnailpath,
          categoryId,
        };
        res = await request.post('/elearningmanagement', body);
      }

      if (_.get(res, ['success'])) {
        handleCloseModalForm();
        fetchData({
          ...getPage(pagination),
          ...sorter,
        });

      }
    } catch (e) {
      console.error(e);
    } finally {
      setFormLoading(false);
    }
  };

  // Modal Setting
  const [visibleSettingModal, setVisibleSettingModal] = useState(false);
  const [settingModalData, setSettingModalData] = useState(null);
  const [settingModalLoading, setSettingModalLoading] = useState(false);

  const fetchSettingModalData = async () => {
    try {
      setSettingModalLoading(true);
      const res = await request.get('/elearningmanagement/setting');
      const data = res.results;
      setSettingModalData(data);
    } catch (error) {
      console.error(error);
    } finally {
      setSettingModalLoading(false);
    }
  };

  const handleOpenModalSetting = () => {
    setVisibleSettingModal(true);
  }

  const handleCloseModalSetting = () => {
    setVisibleSettingModal(false);
  }

  useEffect(() => {
    if (visibleSettingModal) {
      fetchSettingModalData();
    }
  }, [visibleSettingModal]);


  return (
    <>
      <HeaderBlock style={{ fontSize: '20px', fontWeight: 'bold' }}>
        บันทึกจัดการสื่อออนไลน์แนะนำกระบวนงานด้านทะเบียนรถ
      </HeaderBlock>
      <HeaderBlock>
        <Button type="primary" onClick={handleOpenModal}>
          ค้นหา
        </Button>
        {isAllow(userProfile.menuPermissions) && (
          <div>
            <Button type="primary"
              style={{ margin: '0 0 0 16px' }}
              icon="setting"
              onClick={handleOpenModalSetting}
            >
              ตั้งค่าสื่อออนไลน์
            </Button>
            <Button style={{ margin: '0 0 0 16px' }} type="primary" onClick={handleOpenModalForm(null)}>
              เพิ่ม
            </Button>
          </div>
        )}
      </HeaderBlock>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 'max-content' }}
      />
      <FilterModal
        visible={visible}
        handleSearch={handleSearch}
        handleCancel={handleCancel}
        renderInputFields={searchKey}
      />
      <FormModal
        onSubmit={onSubmit}
        formLoading={formLoading}
        data={targetELearning}
        visible={formVisible}
        onCancel={handleCloseModalForm}
      ></FormModal>

      <SettingFromModal
        formLoading={settingModalLoading}
        data={settingModalData}
        visible={visibleSettingModal}
        onCancel={handleCloseModalSetting}
      />
    </>
  );
};
