import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { convertToDatetimeBE } from '../../utils'
import { Table, Tag, Button, Icon, Divider, Popconfirm } from 'antd';

import _ from 'lodash';
import { MainContext } from '../../context/main';

import {
  getFilter,
  getSort,
  getPage,
  getColumnSearchProps,
  perPage,
  defaultPageSize,
  pageSizeOptions,
  showSizeChanger,
} from '../../utils/table';
import { formatPhoneNumber } from '../../utils';
import { HeaderBlock } from '../../style/global';
import FilterModal from '../../component/FilterModal';
import FormModal from './form';
import Header from 'antd/lib/calendar/Header';
const searchKey = [
  { label: 'ชื่อ', key: 'first_name' },
  { label: 'ชื่อบัญชีผู้ใช้งาน', key: 'username' },
];

const isAllow = (menuPermissions, targetmenuPermissions) => {
  const thisMenuId = "8"
  const permission = _.find(menuPermissions, { menuId: thisMenuId });
  return permission && permission.canManage;
};

export default () => {
  const { request, userProfile } = useContext(MainContext);
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState({});
  const [sorter, setSorter] = useState({
    sort: { key: 'created', desc: false },
  });
  const [pagination, setPagination] = useState({
    // pageSize: perPage,
    // defaultPageSize: 10,
    defaultPageSize,
    pageSizeOptions,
    showSizeChanger,
  });
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [targetUser, setTargetUser] = useState(null);
  const [kioskList, setKioskList] = useState([])

  const fetchData = async (params = {}) => {
    try {
      setLoading(true);
      const res = await request.get('/admins', {
        ...params,
        ...searchData,
      });

      const totalData = _.get(res, ['total_data'], 0);

      setData(_.get(res, ['results'], []));
      setPagination({ ...pagination, total: totalData, current: params.page, pageSize: params.per_page });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchKiosk = async () => {
    const res = await request.get('/kiosk', {
      per_page: 100
    })
    setKioskList(res.results)
  }

  const handleDelete = (id) => async () => {
    try {
      setLoading(true);
      const res = await request.delete(`/admins/${id}`);

      if (_.get(res, ['success'])) {
        const pager = { ...pagination };
        const current = pager.current;
        const totalData = pager.total - 1;

        if (data.length === 1 && current > 1) {
          pager.current = current - 1; // Move to the previous page if the current page becomes empty
        }

        setData(data.filter((item) => item.id !== id));
        setPagination({ ...pager, total: totalData });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (paginationChange, filters, sorterChange) => {
    const pager = { ...pagination };
    pager.current = paginationChange.current;

    setPagination(pager);
    setSorter(getSort(sorterChange));

    fetchData({
      ...getFilter(filters),
      ...getSort(sorterChange),
      ...getPage(paginationChange),
    });
  };

  useEffect(() => {
    fetchData({
      ...getPage(pagination),
      ...sorter,
    });
    fetchKiosk()
  }, [searchData]);

  const handleSearch = (value) => {
    pagination.current = 1;
    setSearchData({ ..._.omitBy(value, (o) => _.isNil(o) || _.isEmpty(o)) });

    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOpenModal = () => {
    setVisible(true);
  };

  const handleOpenModalForm = (user) => (e) => {
    setTargetUser(user);
    setFormVisible(true);
  };

  const handleCloseModalForm = () => {
    setTargetUser(null);
    setFormVisible(false);
  };

  const columns = [
    {
      title: 'ชื่อบัญชีผู้ใช้งาน',
      key: 'username',
      // ...getColumnSearchProps(`ค้นหา โดย อีเมล`),
      dataIndex: 'username',
    },
    {
      title: 'ชื่อ - สกุล',
      key: 'first_name',
      // ...getColumnSearchProps(`ค้นหา โดย ชื่อ`),
      render: (record) => `${record.first_name} ${record.last_name}`,
    },
    {
      title: 'ระดับ',
      key: 'role',
      // ...getColumnSearchProps(`ค้นหา โดย อีเมล`),
      dataIndex: 'role',
      render: (role) =>
        role === 'super_admin' ? 'ผู้ดูแลระบบระดับสูง' : 'ผู้ดูแลระบบ',
    },
    {
      title: 'Created',
      key: 'created',
      dataIndex: 'created',
      sorter: () => { },
      defaultSortOrder: 'ascend',
      render: (v) => convertToDatetimeBE(v),
    },
    {
      title: 'Updated',
      dataIndex: 'updated',
      key: 'updated',
      sorter: () => { },
      render: (v) => convertToDatetimeBE(v),
    },
    {
      title: isAllow(userProfile.menuPermissions) ? 'จัดการ' : '',
      width: isAllow(userProfile.menuPermissions) ? '10%' : '0px',
      key: 'action',
      render: (o) =>
        isAllow(userProfile.menuPermissions) ? (
          <span>
            <a
              onClick={
                isAllow(userProfile.menuPermissions, o.menuPermissions)
                  ? handleOpenModalForm(o)
                  : () => { }
              }
            >
              แก้ไข
            </a>
            <Divider type="vertical" />
            <Popconfirm
              key="delete"
              title="กรุณายืนยันการลบผู้ดูแลระบบ"
              onConfirm={handleDelete(_.get(o, ['id']))}
              okText="ตกลง"
              cancelText="ยกเลิก"
            >
              <a>ลบ</a>
            </Popconfirm>
          </span>
        ) : null,
    },
  ];

  const onSubmit = async ({
    username,
    first_name,
    last_name,
    password,
    role,
    isAllowViewMobileTransactions,
    kiosksIds
  }) => {
    setFormLoading(true);
    let body = {};
    let res;
    try {
      if (targetUser) {
        body.id = _.get(targetUser, ['id']);
        if (targetUser.first_name !== first_name) body.first_name = first_name;
        if (targetUser.last_name !== last_name) body.last_name = last_name;
        if (targetUser.isAllowViewMobileTransactions !== isAllowViewMobileTransactions) body.isAllowViewMobileTransactions = isAllowViewMobileTransactions;
        if (targetUser.kiosksIds !== kiosksIds) body.kiosksIds = kiosksIds;
        res = await request.put('/admins', body);
      } else {
        body = {
          username,
          first_name,
          last_name,
          password,
          role,
          isAllowViewMobileTransactions,
          kiosksIds
        };
        res = await request.post('/admins', body);
      }

      // if (_.get(res, ['success'])) {
      //   handleCloseModalForm();
      //   fetchData({
      //     ...getPage(pagination),
      //     ...sorter,
      //   });
      // }
      if (_.get(res, ['success'])) {
        handleCloseModalForm();
        const updatedRow = res.result; // Assuming `res.result` contains the new/updated row object

        if (targetUser) {
          // Update existing row
          const updatedData = data.map((item) =>
            item.id === targetUser.id ? updatedRow : item
          );
          setData(updatedData);
        } else {
          // Create new row
          const updatedData = [updatedRow, ...data];
          setData(updatedData);
          setPagination((prevPagination) => ({
            ...prevPagination,
            total: prevPagination.total + 1,
          }));
        }
      }

    } catch (e) {
      console.error(e);
    } finally {
      setFormLoading(false);
    }
  };

  return (
    <>
      <HeaderBlock style={{ fontSize: '20px', fontWeight: 'bold' }}>
        บันทึกจัดการผู้ใช้งานระบบ (เจ้าหน้าที่)
      </HeaderBlock>
      <HeaderBlock>
        <Button type="primary" onClick={handleOpenModal}>
          ค้นหา
        </Button>
        {isAllow(userProfile.menuPermissions) && (
          <Button type="primary" onClick={handleOpenModalForm(null)}>
            เพิ่ม
          </Button>
        )}
      </HeaderBlock>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 'max-content' }}
      />
      <FilterModal
        visible={visible}
        handleSearch={handleSearch}
        handleCancel={handleCancel}
        renderInputFields={searchKey}
      />
      <FormModal
        onSubmit={onSubmit}
        formLoading={formLoading}
        data={targetUser}
        visible={formVisible}
        onCancel={handleCloseModalForm}
        kioskList={kioskList}
      ></FormModal>
    </>
  );
};
