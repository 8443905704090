export default [
    {
        "province": "กรุงเทพมหานคร",
        "short": "กท"
    },
    {
        "province": "กระบี่",
        "short": "กบ"
    },
    {
        "province": "กาญจนบุรี",
        "short": "กจ"
    },
    {
        "province": "กาฬสินธุ์",
        "short": "กส"
    },
    {
        "province": "กําแพงเพชร",
        "short": "กพ"
    },
    {
        "province": "ขอนแก่น",
        "short": "ขก"
    },
    {
        "province": "จันทบุรี",
        "short": "จบ"
    },
    {
        "province": "ฉะเชิงเทรา",
        "short": "ฉช"
    },
    {
        "province": "ชลบุรี",
        "short": "ชบ"
    },
    {
        "province": "ชัยนาท",
        "short": "ชน"
    },
    {
        "province": "ชัยภูมิ",
        "short": "ชย"
    },
    {
        "province": "ชุมพร",
        "short": "ชพ"
    },
    {
        "province": "เชียงราย",
        "short": "ชร"
    },
    {
        "province": "เชียงใหม่",
        "short": "ชม"
    },
    {
        "province": "ตรัง",
        "short": "ตง"
    },
    {
        "province": "ตราด",
        "short": "ตร"
    },
    {
        "province": "ตาก",
        "short": "ตก"
    },
    {
        "province": "นครนายก",
        "short": "นย"
    },
    {
        "province": "นครปฐม",
        "short": "นฐ"
    },
    {
        "province": "นครพนม",
        "short": "นพ"
    },
    {
        "province": "นครราชสีมา",
        "short": "นม"
    },
    {
        "province": "นครศรีธรรมราช",
        "short": "นศ"
    },
    {
        "province": "นครสวรรค์",
        "short": "นว"
    },
    {
        "province": "นนทบุรี",
        "short": "นบ"
    },
    {
        "province": "นราธิวาส",
        "short": "นธ"
    },
    {
        "province": "น่าน",
        "short": "นน"
    },
    {
        "province": "บึงกาฬ",
        "short": "บก"
    },
    {
        "province": "บุรีรัมย์",
        "short": "บร"
    },
    {
        "province": "ปทุมธานี",
        "short": "ปท"
    },
    {
        "province": "ประจวบคีรีขันธ์",
        "short": "ปข"
    },
    {
        "province": "ปราจีนบุรี",
        "short": "ปจ"
    },
    {
        "province": "ปัตตานี",
        "short": "ปน"
    },
    {
        "province": "พะเยา",
        "short": "พย"
    },
    {
        "province": "พระนครศรีอยุธยา",
        "short": "อย"
    },
    {
        "province": "พังงา",
        "short": "พง"
    },
    {
        "province": "พัทลุง",
        "short": "พท"
    },
    {
        "province": "พิจิตร",
        "short": "พจ"
    },
    {
        "province": "พิษณุโลก",
        "short": "พล"
    },
    {
        "province": "เพชรบุรี",
        "short": "พบ"
    },
    {
        "province": "เพชรบูรณ์",
        "short": "พช"
    },
    {
        "province": "แพร่",
        "short": "พร"
    },
    {
        "province": "ภูเก็ต",
        "short": "ภก"
    },
    {
        "province": "มหาสารคาม",
        "short": "มค"
    },
    {
        "province": "มุกดาหาร",
        "short": "มห"
    },
    {
        "province": "แม่ฮ่องสอน",
        "short": "มส"
    },
    {
        "province": "ยโสธร",
        "short": "ยส"
    },
    {
        "province": "ยะลา",
        "short": "ยล"
    },
    {
        "province": "ร้อยเอ็ด",
        "short": "รอ"
    },
    {
        "province": "ระนอง",
        "short": "รน"
    },
    {
        "province": "ระยอง",
        "short": "รย"
    },
    {
        "province": "ราชบุรี",
        "short": "รบ"
    },
    {
        "province": "ลพบุรี",
        "short": "ลบ"
    },
    {
        "province": "ลําปาง",
        "short": "ลป"
    },
    {
        "province": "ลําพูน",
        "short": "ลพ"
    },
    {
        "province": "เลย",
        "short": "ลย"
    },
    {
        "province": "ศรีสะเกษ",
        "short": "ศก"
    },
    {
        "province": "สกลนคร",
        "short": "สน"
    },
    {
        "province": "สงขลา",
        "short": "สข"
    },
    {
        "province": "สตูล",
        "short": "สต"
    },
    {
        "province": "สมุทรปราการ",
        "short": "สป"
    },
    {
        "province": "สมุทรสงคราม",
        "short": "สส"
    },
    {
        "province": "สมุทรสาคร",
        "short": "สค"
    },
    {
        "province": "สระแก้ว",
        "short": "สก"
    },
    {
        "province": "สระบุรี",
        "short": "สบ"
    },
    {
        "province": "สิงห์บุรี",
        "short": "สห"
    },
    {
        "province": "สุโขทัย",
        "short": "สท"
    },
    {
        "province": "สุพรรณบุรี",
        "short": "สพ"
    },
    {
        "province": "สุราษฎ์ธานี",
        "short": "สฎ"
    },
    {
        "province": "สุรินทร์",
        "short": "สร"
    },
    {
        "province": "หนองคาย",
        "short": "นค"
    },
    {
        "province": "หนองบัวลําภู",
        "short": "นภ"
    },
    {
        "province": "อ่างทอง",
        "short": "อท"
    },
    {
        "province": "อํานาจเจริญ",
        "short": "อจ"
    },
    {
        "province": "อุดรธานี",
        "short": "อด"
    },
    {
        "province": "อุตรดิตถ์",
        "short": "อต"
    },
    {
        "province": "อุทัยธานี",
        "short": "อน"
    },
    {
        "province": "อุบลราชธานี",
        "short": "อบ"
    },
    {
        "province": "เบตง",
        "short": "บต"
    }
]
