import React, { useEffect, useState, useContext } from 'react';
import {
  Modal,
  Form,
  Select,
  DatePicker,
  Input,
  Button,
  Spin,
  Card,
  Checkbox,
  Upload,
  Icon,
  Switch,
  message,
  TimePicker,
  Popover,
} from 'antd';
import _, { now } from 'lodash';
import get from 'lodash/get';
import { MainContext } from '../../context/main';
import styled from 'styled-components';
import moment from 'moment';

const { Option } = Select;

export default Form.create()(
  ({
    visible,
    onSubmit = () => { },
    onCancel = () => { },
    form: { getFieldDecorator, validateFields, resetFields, getFieldValue },
    data,
    formLoading,
  }) => {
    const handleSubmit = (e) => {
      e.preventDefault();

      validateFields(async (err, fieldsValue) => {
        if (err) {
          return;
        }
        if (!_.isNil(fieldsValue.image.file)) {
          fieldsValue.image = await getBase64(fieldsValue.image.file.originFileObj);
        }
        setImageUrl(null);
        // console.log(fieldsValue);
        onSubmit(_.omit(fieldsValue));
      });
    };

    useEffect(() => {
      if (!visible) {
        resetFields();
      }
      if (get(data, "image")) {
        setImageUrl(_.get(data, ['image']));
      }
    }, [visible]);
    const getBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    const beforeUpload = (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('รองรับเฉพาะ ไฟล์ PNG และ JPG เท่านั้น!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('ขนาดไฟล์ต้องไม่เกิน 2MB!');
      }
      return isJpgOrPng && isLt2M;
    };
    const [imageUrl, setImageUrl] = useState(get(data, '[image]', null));
    const handleFileRead = async (e) => {
      const file = e.file;
      if (beforeUpload(file)) {
        const base64 = await getBase64(file.originFileObj);
        setImageUrl(base64);
      }
    }
    const setOldImageUrl = (e) => {
      setImageUrl(get(data, '[image]', null));
    }
    const uploadButton = (
      <div>
        <Icon type="upload" />
        <div
          style={{
            marginTop: 16,
          }}
        >
          อัพโหลดรูปภาพ
        </div>
      </div>
    );
    const { request } = useContext(MainContext);
    const fetchCategory = async () => {
      const res = await request.get(`/systemmanual/setting`);
      let data = {};
      if (res.results) {
        data = res.results;
      }

      setCategoryList(data);
    };
    const [categoryList, setCategoryList] = useState([]);
    useEffect(() => {
      if (visible)
        fetchCategory();
    }, [visible]);
    return (

      <CustomModal
        title={data ? 'แก้ไขคู่มือการใช้งานระบบ' : 'เพิ่มคู่มือการใช้งานระบบ'}
        visible={visible}
        style={{ minWidth: '800px' }}
        onCancel={onCancel}
        footer={[
          <Button key="back" onClick={onCancel}>
            ยกเลิก
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit}>
            บันทึก
          </Button>,
        ]}
      >

        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>
          <Spin spinning={formLoading}>
            <Form.Item label="รูปภาพปกคู่มือการใช้งานระบบ">
              {getFieldDecorator('image', {
                initialValue: get(data, '[image]'),
                valuePropName: 'file',
                rules: [
                  {
                    required: !data,
                    message: 'กรุณาเลือกรูปภาพ',
                  },
                ],
              })(
                <Upload
                  action="#"
                  listType="picture-card"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={e => handleFileRead(e)}
                >

                  {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                </Upload>
              )}
            </Form.Item>
            <Form.Item label="ชื่อคู่มือการใช้งานระบบ">
              {getFieldDecorator('title', {
                initialValue: get(data, ['title']),
                rules: [
                  {
                    required: !data,
                    message: 'กรุณากรอกชื่อคู่มือการใช้งานระบบ',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="คำอธิบาย">
              {getFieldDecorator('description', {
                initialValue: get(data, ['description']),
                rules: [
                  {
                    required: !data,
                    message: 'กรุณากรอกคำอธิบาย',
                  },
                ],
              })(<Input.TextArea style={{ resize: 'none', height: '120px' }} />)}
            </Form.Item>
            <Form.Item label="Youtube Video ID">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {getFieldDecorator('url', {
                  initialValue: get(data, ['url']),
                  rules: [
                    {
                      required: !data,
                      message: 'กรุณากรอก Youtube Video ID',
                    },
                  ],
                })(
                  <Input />
                )}
                <Popover
                  content={
                    <div style={{ maxWidth: '300px' }}>
                      ให้นำ ตัวอักษรหลัง v= และก่อน & <br />จาก url มากรอก เช่น<br />
                      https://www.youtube.com/watch?v=<a style={{ color: 'black', fontWeight: 'bold', cursor: 'text', backgroundColor: 'yellow' }}>dQw4w9WgXcQ</a>&ab_channel=RickAstley <br />นำมากรอกแค่ dQw4w9WgXcQ
                    </div>
                  }
                  title="วิธีการหา Youtube Video ID"
                  trigger="click"
                >
                  <Icon type='question-circle' style={{ color: 'rgba(0, 0, 0, 0.45)', marginLeft: '8px', cursor: 'pointer' }} />
                </Popover>
              </div>
            </Form.Item>
            <Form.Item label="หมวดหมู่">
              {getFieldDecorator('categoryId', {
                initialValue: get(data, ['categoryId']),
                rules: [
                  {
                    required: !data,
                    message: 'กรุณาเลือกหมวดหมู่',
                  },
                ],
              })(
                <Select
                  showSearch
                  placeholder="กรุณาเลือกหมวดหมู่"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }>
                  {_.map(categoryList, (category) => (
                    <Select.Option key={category.id} value={category.id}>
                      {category.title}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item label="สถานะ">
              {getFieldDecorator('isActive', {
                valuePropName: 'checked',
                initialValue: get(data, ['isActive'], true),
              })(
                <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" />
              )}
            </Form.Item>
            <Form.Item label="กำหนดช่วงเวลาเผยแพร่">
              {getFieldDecorator('isLimitTime', {
                valuePropName: 'checked',
                initialValue: get(data, ['isLimitTime'], false),
              })(
                <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" />
              )}
            </Form.Item>
            <Form.Item label="วันเวลาเริ่มเผยแพร่">
              {getFieldDecorator('LimitTimeStartDate', {
                initialValue: moment(get(data, ['LimitTimeStartDate'])) || moment(),
                rules: [
                  {
                    required: !data,
                    message: 'กรุณากรอกวันที่เริ่ม',
                  }
                ],
              })(
                <DatePicker defaultPicker={moment()} />
              )}
              {getFieldDecorator('LimitTimeStartTime', {
                initialValue: moment(get(data, ['LimitTimeStartDate'])) || moment(),
                rules: [
                  {
                    required: !data,
                    message: 'กรุณากรอกเวลาเริ่มต้นประกาศ',
                  }
                ],
              })(
                <TimePicker defaultPicker={moment()} format={'HH:mm'} />
              )}
            </Form.Item>
            <Form.Item label="วันเวลาสิ้นสุด">
              {getFieldDecorator('LimitTimeEndDate', {
                initialValue: moment(get(data, ['LimitTimeEndDate'])) || moment(),
                rules: [
                  {
                    required: !data,
                    message: 'กรุณากรอกวันสิ้นสุด',
                  }
                ],
              })(
                <DatePicker />
              )}
              {getFieldDecorator('LimitTimeEndTime', {
                initialValue: moment(get(data, ['LimitTimeEndDate'])) || moment(),
                rules: [
                  {
                    required: !data,
                    message: 'กรุณากรอกเวลาเริ่มต้นประกาศ',
                  }
                ],
              })(
                <TimePicker defaultPicker={moment()} format={'HH:mm'} />
              )}
            </Form.Item>
          </Spin>
        </Form>
      </CustomModal>
    );
  },
);

export const CustomModal = styled(Modal)`
  #kiosksIds {
    display: inline-block;
  }
  .ant-checkbox-group-item {
    margin: 0;
    width: 50%;
  }
`;