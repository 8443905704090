import React, { useContext } from 'react';
import {
  Switch,
  Route,
  useParams,
  Redirect,
  useHistory,
} from 'react-router-dom';
import {
  Layout, Avatar, Dropdown, Icon, Menu, message,
} from 'antd';
import _ from 'lodash';
import NavSider from '../component/NavSider';
import PrivateRoute from './PrivateRoute';
import UserScreens from '../screens/UserScreens';
import AdminScreen from '../screens/AdminScreen';
import ProfileScreen from '../screens/ProfileScreen';
import LogScreen from '../screens/LogScreen';
import TransactionScreen from '../screens/TransactionScreen';
import TransactionScreenDetail from '../screens/TransactionScreen/detail';
import HistoricalScreen from '../screens/HistoricalScreen';
import HistoricalItemScreen from '../screens/HistoricalScreen/items';
import HistoricalScreenDetail from '../screens/HistoricalScreen/detail';
import PrinterScreen from '../screens/PrinterScreen';
import KioskScreen from '../screens/KioskScreen';
import KioskScreenDetail from '../screens/KioskScreen/detail';
import ELearningManagementScreen from '../screens/ELearningManagementScreen';
import TosScreen from '../screens/TosScreen';
import NotifiersScreen from '../screens/NotifiersScreen';
import UsersreportScreen from '../screens/UsersreportScreen';
import OwnercertScreen from '../screens/OwnercertScreen';
import CarmanualScreen from '../screens/CarmanualScreen';
import TaxlistScreen from '../screens/TaxlistScreen';
import AdminPermissionScreen from '../screens/AdminPermissionScreen';
import AdminPermissionAssignScreen from '../screens/AdminPermissionAssignScreen';
import UserLogScreen from '../screens/UserLogScreen';
import SurveyScreen from '../screens/SurveyScreen';
import NotifiersPersonalScreen from '../screens/NotifiersPersonalScreen';
import AdminLogScreen from '../screens/AdminLogScreen';
import TransactionLogScreen from '../screens/TransactionLogScreen';
import OwnercertLogScreen from '../screens/OwnercertLogScreen';
import CarmanualLogScreen from '../screens/CarmanualLogScreen';
import SystemManualScreen from '../screens/SystemManualScreen';
import { MainContext } from '../context/main';

const {
  Header, Content, Footer, Sider,
} = Layout;

export default () => {
  const { page, subPage } = useParams();
  const { logout, userProfile } = useContext(MainContext);
  const history = useHistory();
  const hasPermission = (menuId) => {
    const menuPermissions = _.get(userProfile, ['menuPermissions']);
    const permission = _.find(menuPermissions, { menuId });
    return permission && permission.canView;
  };

  const handleMenuClick = (e) => {
    const { key } = e;
    if (key === 'logOut') {
      logout();
      return;
    }

    if (key === 'profile') {
      history.push('/profile');
      return;
    }

    if (key === 'Queues') {
      window.location = '/api/v1/b/system/queues';
    }
  };

  const menu = (
    <>
      <Menu onClick={handleMenuClick}>
        <Menu.Item key="profile">
          <Icon type="profile" />
          ข้อมูลส่วนตัว
        </Menu.Item>
      </Menu>
      {/* {userProfile.role === 'super_admin' && (
        <Menu onClick={handleMenuClick}>
          <Menu.Item key="Queues">
            <Icon type="menu" />
            Queues
          </Menu.Item>
        </Menu>
      )} */}
      <Menu onClick={handleMenuClick}>
        <Menu.Item key="logOut">
          <Icon type="logout" />
          ลงชื่อออก
        </Menu.Item>
      </Menu>
    </>
  );

  return (
    <Layout style={{ height: '100vh' }}>
      <Sider
        width="300px"
        breakpoint="lg"
        collapsedWidth="0"
        collapsible={true}
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <div className="logo">
          <img alt="" src="/logo.png" />
        </div>
        <NavSider page={page} />
      </Sider>
      <Layout id="main-content-layout" style={{ position: 'relative' }}>
        <Header
          style={{
            background: '#fff',
            padding: '16px 16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            height: 'unset',
            lineHeight: 'unset',
          }}
        >
          <div style={{ flex: 2, display: window.innerWidth < 500 ? 'none' : 'block'  }}>
            {/* <h2 style={{ lineHeight: '30px', marginBottom: '0px' }}>ระบบบริหารการรับชำระภาษีประจำปี ผ่านตู้รับชำระภาษีประจำปี และ DLT Vehicle Tax Plus App</h2> */}
            <h2 style={{ lineHeight: '30px', marginBottom: '0px' }}>ระบบบริหารจัดการ DLT Vehicle Tax Plus และตู้ชำระภาษีรถประจำปี</h2>
          </div>
          <div style={{ flex: 1, display: window.innerWidth < 500 ? 'none' : 'block' }}>
            {

              (_.get(userProfile, 'first_name') && _.get(userProfile, 'last_name')) &&
              <p style={{ marginBottom: '0px' }}>{`${_.get(userProfile, 'title_name')}${_.get(userProfile, 'first_name')} ${_.get(userProfile, 'last_name')} | ${_.get(userProfile, 'orgFullNameDes')}`}</p>
            }
          </div>
          &nbsp;
          <Dropdown overlay={menu}>
            <div
              style={{ cursor: 'pointer' }}
              onClick={(e) => e.preventDefault()}
            >
              <Avatar icon="user" />
            </div>
          </Dropdown>

        </Header>
        <Content style={{ margin: '24px 16px 0', overflowY: 'scroll', overflowX: 'hidden' }}>
          <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
            <Switch>
              <PrivateRoute exact path="/">
                <Redirect
                  to={{
                    // change to blank page
                    pathname: '/',
                  }}
                />
              </PrivateRoute>
              {(hasPermission('1')) && (
                <PrivateRoute exact path="/transactions">
                  <TransactionScreen type="mobile" />
                </PrivateRoute>
              )}
              {(hasPermission('1')) && (
                <PrivateRoute exact path="/transactions-kiosk">
                  <TransactionScreen type="kiosk" />
                </PrivateRoute>
              )}
              {(hasPermission('1')) && (
                <PrivateRoute exact path="/transactions/:transactionId">
                  <TransactionScreenDetail />
                </PrivateRoute>
              )}
              {hasPermission('13') && (
                <PrivateRoute exact path="/historical">
                  <HistoricalScreen />
                </PrivateRoute>
              )}
              {hasPermission('13') && (
                <PrivateRoute exact path="/historical/:date">
                  <HistoricalItemScreen />
                </PrivateRoute>
              )}
              {hasPermission('13') && (
                <PrivateRoute exact path="/historical/:date/:historicalId">
                  <HistoricalScreenDetail />
                </PrivateRoute>
              )}
              {hasPermission('7') && (
                <PrivateRoute exact path="/users">
                  <UserScreens />
                </PrivateRoute>
              )}

              {/*   <PrivateRoute exact path="/printer">
                <PrinterScreen />
                </PrivateRoute> */}
              {hasPermission('4') && (
                <PrivateRoute exact path="/kiosk">
                  <KioskScreen />
                </PrivateRoute>
              )}
              {hasPermission('4') && (
                <PrivateRoute exact path="/kiosk/:kioskId">
                  <KioskScreenDetail />
                </PrivateRoute>
              )}

              {_.get(userProfile, ['role']) === 'super_admin' && (
                <PrivateRoute exact path="/admins">
                  <AdminScreen />
                </PrivateRoute>
              )}
              {/* {_.get(userProfile, ['role']) === 'super_admin' && ( */}
              {hasPermission('9') && (
                <PrivateRoute exact path="/addminpermissionassign">
                  <AdminPermissionAssignScreen />
                </PrivateRoute>
              )}
              {/* {_.get(userProfile, ['role']) === 'super_admin' && ( */}
              {hasPermission('10') && (
                <PrivateRoute exact path="/adminpermissionmanagement">
                  <AdminPermissionScreen />
                </PrivateRoute>
              )}
              {hasPermission('14') && (
                <PrivateRoute exact path="/logs">
                  <LogScreen />
                </PrivateRoute>
              )}

              <PrivateRoute exact path="/profile">
                <ProfileScreen />
              </PrivateRoute>

              {hasPermission('11') && (
                <PrivateRoute exact path="/elearningmanagement">
                  <ELearningManagementScreen />
                </PrivateRoute>
              )}

              {hasPermission('12') && (
                <PrivateRoute exact path="/tos">
                  <TosScreen />
                </PrivateRoute>
              )}

              {hasPermission('6') && (
                <PrivateRoute exact path="/notifiers">
                  <NotifiersScreen />
                </PrivateRoute>
              )}

              {hasPermission('5') && (
                <PrivateRoute exact path="/usersreport">
                  <UsersreportScreen />
                </PrivateRoute>
              )}

              {hasPermission('3') && (
                <PrivateRoute exact path="/ownercert">
                  <OwnercertScreen />
                </PrivateRoute>
              )}

              {/* <PrivateRoute exact path="/carmanual">
                <CarmanualScreen />
              </PrivateRoute> */}

              {(hasPermission('1')) && (
                <PrivateRoute exact path="/taxlist">
                  <TaxlistScreen />
                </PrivateRoute>
              )}
              {hasPermission('15') && (
                <PrivateRoute exact path="/userlogs">
                  <UserLogScreen />
                </PrivateRoute>
              )}
              {hasPermission('16') && (
                <PrivateRoute exact path="/survey">
                  <SurveyScreen />
                </PrivateRoute>
              )}
              {hasPermission('17') && (
                <PrivateRoute exact path="/notifierspersonal">
                  <NotifiersPersonalScreen />
                </PrivateRoute>
              )}
              {hasPermission('18') && (
                <PrivateRoute exact path="/adminlogs">
                  <AdminLogScreen />
                </PrivateRoute>
              )}
              {hasPermission('20') && (
                <PrivateRoute exact path="/transactionlogs">
                  <TransactionLogScreen />
                </PrivateRoute>
              )}
              {hasPermission('19') && (
                <PrivateRoute exact path="/ownercerlogs">
                  <OwnercertLogScreen />
                </PrivateRoute>
              )}
              {hasPermission('21') && (
                <PrivateRoute exact path="/carmanuallogs">
                  <CarmanualLogScreen />
                </PrivateRoute>
              )}
              {hasPermission('8') && (
                <PrivateRoute exact path="/systemmanual">
                  <SystemManualScreen />
                </PrivateRoute>
              )}

            </Switch>
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          DLT Vehicle Tax Plus กรมการขนส่งทางบก 1032 ถนนพหลโยธิน แขวงจอมพล เขตจตุจักร กรุงเทพมหานคร 10900.
        </Footer>
      </Layout>
    </Layout>
  );
};
