import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { convertToDatetimeBE } from '../../utils'
import { Table, Tag, Button, Popconfirm, Popover, Spin } from 'antd';
import { TagContainer, ButtonTag } from './style';

import _ from 'lodash';
import { MainContext } from '../../context/main';

import {
  getFilter,
  getSort,
  getPage,
  getColumnSearchProps,
  perPage,
  defaultPageSize,
  pageSizeOptions,
  showSizeChanger,
} from '../../utils/table';
import { formatPhoneNumber } from '../../utils';
import { HeaderBlock } from '../../style/global';
import FilterModal from '../../component/FilterModal';
import ExportExcelUser from '../../component/ExportExcelUser';
const searchKey = [
  { label: 'ชื่อ', key: 'first_name' },
  { label: 'นามสกุล', key: 'last_name' },
  { label: 'เลขประจําตัวประชาชน', key: 'identity_number' },
  { label: 'อีเมล', key: 'email' },
  { label: 'เบอร์โทรศัพท์ติดต่อ', key: 'telephone' },
  { label: 'ช่วงเวลาที่ลงทะเบียน', key: 'date-range', type: 'rangepicker' },
  {
    label: 'ระดับการพิสูจน์ตัวตน', key: 'ial_level', type: 'select2',
    options: [
      {
        title: 'IAL 1.1', value: '1.1',
      },
      {
        title: 'IAL 1.3', value: '1.3',
      },
      {
        title: 'IAL 2.3', value: '2.3',
      },
    ]
  },
];

export default () => {
  const { request, userProfile } = useContext(MainContext);
  const [visibleExportExcelModal, setVisibleExportExcelModal] = useState(false);
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState({});
  const [sorter, setSorter] = useState({
    sort: { key: 'created', desc: true },
  });
  const [pagination, setPagination] = useState({
    // pageSize: perPage,
    defaultPageSize: defaultPageSize,
    pageSizeOptions: pageSizeOptions,
    showSizeChanger: showSizeChanger,

  });
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const fetchData = async (params = {}) => {
    try {
      setLoading(true);
      const res = await request.get('/users', {
        ...params,
        ...searchData,
        includes: ['verified', "deleted"],
      });

      console.log(res);

      const totalData = _.get(res, ['total_data'], 0);
      const totalDataForExport = _.get(res, ['total_data_for_export'], 0);
      setData(_.get(res, ['results'], []));
      setPagination({ ...pagination, total: totalData, current: params.page, pageSize: params.per_page, totalDataForExport });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (paginationChange, filters, sorterChange) => {
    const pager = { ...pagination };
    pager.current = paginationChange.current;

    setPagination(pager);
    setSorter(getSort(sorterChange));

    fetchData({
      ...getFilter(filters),
      ...getSort(sorterChange),
      ...getPage(paginationChange),
    });
  };

  useEffect(() => {
    fetchData({
      ...getPage(pagination),
      ...sorter,
    });
  }, [searchData]);

  const handleSearch = (value) => {
    pagination.current = 1;
    setSearchData({ ..._.omitBy(value, (o) => _.isNil(o) || _.isEmpty(o)) });

    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOpenModal = () => {
    setVisible(true);
  };
  const onStatusChange = ({ newStatus, id }) => {
    setData(
      _.map(data, (o) => {
        if (o.id === id) o.ial_level = newStatus;

        return o;
      }),
    );
  };

  const onStatusChangedeleted = ({ newStatus, id }) => {
    setData(
      _.map(data, (o) => {
        if (o.id === id) o.deleted = newStatus;

        return o;
      }),
    );
  };

  const columns = [
    {
      title: 'ลำดับ',
      key: 'index',
      dataIndex: 'index',
      width: "70px"
    },
    {
      title: 'ชื่อ - สกุล',
      key: 'first_name',
      // ...getColumnSearchProps(`ค้นหา โดย ชื่อ`),
      render: (record) => `${record.first_name} ${record.last_name}`,
    },
    {
      title: 'เลขประจําตัวประชาชน',
      key: 'identity_number',
      // ...getColumnSearchProps(`ค้นหา โดย เลขประจําตัวประชาชน`),
      dataIndex: 'identity_number',
    },
    {
      title: 'อีเมล',
      key: 'email',
      // ...getColumnSearchProps(`ค้นหา โดย อีเมล`),
      dataIndex: 'email',
    },
    {
      title: 'โทรศัพท์ติดต่อ',
      key: 'telephone',
      dataIndex: 'telephone',
      render: (telephone) => formatPhoneNumber(telephone),
    },
    {
      title: 'ระดับการพิสูจน์ตัวตน',
      key: 'ial_level',
      dataIndex: 'ial_level',
      render: (status, o) => {

        return <PopOverTag onStatusChange={onStatusChange} users={o} />
      },
    },

    {
      title: 'สถานะการยืนยันตัวตน',
      key: 'verified',
      dataIndex: 'verified',
      render: (v) => (
        <Tag color={v ? 'green' : 'volcano'}>
          {v ? 'ยืนยันแล้ว' : 'ยังไม่ยืนยัน'}
        </Tag>
      ),
    },
    {
      title: 'สถานะการใช้งาน',
      key: 'deleted',
      dataIndex: 'deleted',
      // render: (v) => (
      //   <Tag color={v ? 'green' : 'volcano'}>
      //     {v ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}
      //   </Tag>
      // ),
      render: (status, o) => {
        return <PopOverTagDeleted onStatusChange={onStatusChangedeleted} users={o} />
      },
    },
    {
      title: 'ช่องทางการลงทะเบียน',
      key: 'registered_chanel',
      dataIndex: 'registered_chanel',
      render: (v) => v || 'ไม่มีข้อมูล',
    },
    {
      title: 'วันที่ลงทะเบียน',
      key: 'created',
      dataIndex: 'created',
      sorter: () => { },
      defaultSortOrder: 'descend',
      render: (v) => convertToDatetimeBE(v),
    },
    // {
    //   title: 'ที่อยู่ผู้ลงทะเบียน',
    //   key: 'address',
    //   dataIndex: 'address',
    // },
    // {
    //   title: 'Token สพร.',
    //   key: 'DGA_access_token',
    //   dataIndex: 'DGA_access_token',
    //   render: (v) => v || 'ไม่มีข้อมูล',
    // },


    // {
    //   title: "Updated",
    //   dataIndex: "updated",
    //   sorter: () => {},
    //   render: v => moment(v).format("DD-MM-YYYY")
    // }
  ];

  const isAllow = (menuPermissions, targetmenuPermissions) => {
    const thisMenuId = "7"
    const permission = _.find(menuPermissions, { menuId: thisMenuId });
    return permission && permission.canManage;
  };

  const PopOverTag = ({ users, onStatusChange }) => {

    const currentStatus = _.get(users, ['ial_level']);
    const [visible, setVisible] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const { request } = useContext(MainContext);

    const onVisibleChange = (e) => {
      return false;
      setVisible((prevState) => !prevState);
    };

    const handleSelect = (newStatus) => async (e) => {
      if (newStatus === currentStatus) return;

      const body = {
        ial_level: newStatus,
      };
      setIsLoading(true);

      const res = await request.put(`/users/${users.id}/ial_level`, body);

      setIsLoading(false);

      if (_.get(res, ['success'])) {
        onStatusChange({
          id: users.id,
          newStatus,
        });
      }

      onVisibleChange();
    };

    return (
      <Popover
        content={(
          <Spin spinning={isLoading}>
            <TagContainer>
              <ButtonTag underline onClick={handleSelect("1.1")}>
                <Tag>
                  IAL 1.1
                </Tag>
              </ButtonTag>
              <ButtonTag underline onClick={handleSelect("1.3")}>
                <Tag>
                  IAL 1.3
                </Tag>
              </ButtonTag>
              <ButtonTag underline onClick={handleSelect("2.3")}>
                <Tag>
                  IAL 2.3
                </Tag>
              </ButtonTag>
            </TagContainer>
          </Spin>
        )}
        title="ระดับการพิสูจน์ตัวตน"
        trigger="click"
        visible={visible}
        onVisibleChange={onVisibleChange}
      >
        <ButtonTag>
          <Tag>
            {' '}
            IAL {currentStatus}
          </Tag>
        </ButtonTag>
      </Popover>
    );
  };

  const PopOverTagDeleted = ({ users, onStatusChange }) => {


    const currentStatus = _.get(users, ['deleted']);
    const [visible, setVisible] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const { request } = useContext(MainContext);

    const onVisibleChange = (e) => {
      if (!isAllow(userProfile.menuPermissions)) {
        return false;
      }
      setVisible((prevState) => !prevState);
    };

    const handleSelect = (newStatus) => async (e) => {
      if (newStatus === currentStatus) return;

      const body = {
        isDeleted: newStatus,
      };
      setIsLoading(true);

      const res = await request.put(`/users/${users.id}/is_deleted`, body);

      setIsLoading(false);

      if (_.get(res, ['success'])) {
        onStatusChange({
          id: users.id,
          newStatus,
        });
      }

      onVisibleChange();
    };

    return (
      <Popover
        content={(
          <Spin spinning={isLoading}>
            <TagContainer>
              <ButtonTag underline onClick={handleSelect(false)}>
                <Tag>
                  เปิดใช้งาน
                </Tag>
              </ButtonTag>
              <ButtonTag underline onClick={handleSelect(true)}>
                <Tag>
                  ปิดใช้งาน
                </Tag>
              </ButtonTag>
            </TagContainer>
          </Spin>
        )}
        title="สถานะการใช้งาน"
        trigger="click"
        visible={visible}
        onVisibleChange={onVisibleChange}
      >
        <ButtonTag>
          <Tag color={currentStatus ? 'volcano' : 'green'}>
            {currentStatus ? 'ปิดใช้งาน' : 'เปิดใช้งาน'}
          </Tag>
        </ButtonTag>
      </Popover>
    );
  };

  const toggleExportExcelModal = () => {
    setVisibleExportExcelModal(!visibleExportExcelModal);
  };

  const handleDownload = (filename = 'report.xlsx', page, per_page = 1000) => request.download('/users/export', filename, {
    ...getPage(pagination),
    ...sorter,
    ...searchData,
    page,
    per_page,
  });

  return (
    <>
      <HeaderBlock style={{ fontSize: '20px', fontWeight: 'bold' }}>
        สอบถามข้อมูลผู้ลงทะเบียนใช้งานระบบ DLT Vehicle Tax Plus
      </HeaderBlock>
      <ExportExcelUser
        visible={visibleExportExcelModal}
        handleOk={toggleExportExcelModal}
        handleCancel={toggleExportExcelModal}
        handleDownload={handleDownload}
        total={_.get(pagination, 'totalDataForExport', 0)}
      />
      <HeaderBlock>
        <Button type="primary" onClick={handleOpenModal}>
          ค้นหา
        </Button>
        {isAllow(userProfile.menuPermissions) && (
          <div>
            <Button
              style={{ margin: '0 0 0 16px' }}
              icon="export"
              onClick={toggleExportExcelModal}
            >
              Export ข้อมูลบัญชีผู้ลงทะเบียน
            </Button>
          </div>
        )}
      </HeaderBlock>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        // scroll={{ x: window.innerWidth < 500 ? 'max-content' : 'false', y: window.innerHeight - 470 }}
      />
      <FilterModal
        visible={visible}
        handleSearch={handleSearch}
        handleCancel={handleCancel}
        renderInputFields={searchKey}
      />
    </>
  );
};
