import styled, { createGlobalStyle } from 'styled-components';

export const HeaderBlock = styled.div`
  width: 100%;
  margin: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SpanColor = styled.span`
  color: ${(props) => props.color || '#000'};
`;

export const LongLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin: 8px 0 16px;
`;

export const GlobalStyle = createGlobalStyle`
  .two-rows-label .ant-form-item-label {
    padding: 4px 0 !important;
    label {
      white-space: normal;
      margin-right: 12px;
      display: inline-block;
      text-align: right;
      line-height: 22px;
    }
  }
`;
