import React, { useState, useEffect, useContext } from 'react';
import {
  Table, Tag, Button, Spin, Row, Col, Divider,
} from 'antd';
import Axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { convertToDatetimeBE, convertToDateBE } from '../../utils';
import { Link } from 'react-router-dom';

import { MainContext } from '../../context/main';
import {
  getPage, getFilter, getSort, perPage, defaultPageSize, pageSizeOptions, showSizeChanger,
} from '../../utils/table';
import { chan } from '../../utils';
import { HeaderBlock } from '../../style/global';
import FilterModal from '../../component/FilterModal';
import vehicleTypeData from '../../utils/staticData/vehicleTypeData';
import ExportExcel from '../../component/ExportExcel';
import DrawerExport from '../../component/DrawerExport';
import DrawerExportDaily from '../../component/DrawerExportDaily';
import Detail from './detail';
import provinceData from '../../utils/staticData/provinceData';

const isAllow = (menuPermissions, targetmenuPermissions) => {
  const thisMenuId = "3"
  const permission = _.find(menuPermissions, { menuId: thisMenuId });
  return permission && permission.canManage;
};


export default (props) => {
  const { request, userProfile } = useContext(MainContext);

  const [visibleExportExcelModal, setVisibleExportExcelModal] = useState(false);
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [searchData, setSearchData] = useState({});
  const [sorter, setSorter] = useState({
    sort: { key: 'created', desc: true },
  });
  const [pagination, setPagination] = useState({
    // pageSize: perPage,
    defaultPageSize: defaultPageSize,
    pageSizeOptions: pageSizeOptions,
    showSizeChanger: showSizeChanger,
  });
  const [loading, setLoading] = useState(false);
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [drawerExportVisible, setDrawerExportVisible] = useState(false);
  const [drawerExportDailyVisible, setDrawerExportDailyVisible] = useState(false);
  const [transactionId, setTransactionId] = useState('');

  useEffect(() => {
    fetchData()
  }, [props.type])

  const fetchData = async (params = {}) => {
    try {
      setLoading(true);
      const res = await request.get('/transactions', {
        ...params,
        ...searchData,
        select: [
          'reference_1',
          'plate1',
          'plate2',
          'is_success',
          'vehTypeCode',
          'offRegDesc',
          'confirmation_id',
          'reference_2',
          'ownIdNo',
          'transaction_channel',
          'created_by',
          'is_approved',
          'receiptDetail',
          'is_downloaded',
        ],
        includes: ['confirmed', 'kiosk', 'invoice', 'receipt', 'printlog'],
        transaction_channel: props.type,
        transaction_type: 'OWNERCERT'
      });
      const totalData = _.get(res, ['total_data'], 0);

      const result = _.get(res, ['results'], []);

      setData(result);
      setPagination({ ...pagination, total: totalData, current: params.page, pageSize: params.per_page });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (paginationChange, filters, sorterChange) => {
    const pager = { ...pagination };
    pager.current = paginationChange.current;

    setPagination(pager);
    setSorter(getSort(sorterChange));

    fetchData({
      ...getFilter(filters),
      ...getSort(sorterChange),
      ...getPage(paginationChange),
    });
  };

  useEffect(() => {
    fetchData({
      // ...getPage(pagination),
      ...sorter,
    });
  }, [searchData]);

  const handleSearch = (value) => {
    pagination.current = 1;
    setSearchData({ ..._.omitBy(value, (o) => _.isNil(o) || _.isEmpty(o)) });

    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOpenModal = () => {
    setVisible(true);
  };

  const toggleExportExcelModal = () => {
    setVisibleExportExcelModal(!visibleExportExcelModal);
  };

  const handleDownload = (filename = 'report.xlsx', page, per_page = 1000) => request.download('/transactions/exportownercert', filename, {
    ...getPage(pagination),
    ...sorter,
    ...searchData,
    page,
    per_page,
    transaction_channel: props.type,
    transaction_type: 'OWNERCERT'
  });

  const handleDownloadSummary = async (fileName = 'รายงานสรุปการขอใบรับรอง.xlsx') => {
    if (loading) return;
    try {
      setLoading(true);
      fileName = 'รายงานสรุปการขอใบรับรอง.xlsx';
      await request.download('/transactions/exportownercertSummary', fileName, {
        ...getPage(pagination),
        ...sorter,
        ...searchData
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // const handleExcelSummaryDownload = async (
  //   filename = 'report.xlsx',
  //   params,
  // ) => {
  //   setSummaryLoading(true);

  //   await request.download('/transactions/exportownercertSummary', filename, params);
  //   setSummaryLoading(false);
  // };

  // const handleExcelSummaryDailyDownload = async (
  //   filename = 'report.xlsx',
  //   params,
  // ) => {
  //   setSummaryLoading(true);

  //   await request.download('/transactions/report-daily', filename, { ...params, transaction_channel: props.type, transaction_type: 'OWNERCERT' });
  //   setSummaryLoading(false);
  // };

  const handleOpenDrawerExport = () => {
    setDrawerExportVisible(true);
  };
  const handleCloseDrawerExport = () => {
    setDrawerExportVisible(false);
  };

  const handleOpenDrawerDailyExport = () => {
    setDrawerExportDailyVisible(true);
  };
  const handleCloseDrawerDailyExport = () => {
    setDrawerExportDailyVisible(false);
  };

  const columns = (setTransactionId) => [
    {
      title: 'ลำดับ',
      key: 'index',
      dataIndex: 'index',
    },
    {
      title: 'หมายเลขอ้างอิง',
      key: 'reference_1',
      dataIndex: 'reference_1',
    },
    {
      title: 'ชื่อ-นามสกุลผู้ยื่นคำขอ',
      key: 'created_byname',
      dataIndex: 'created_byname',
    },
    {
      title: 'ทะเบียน',
      key: 'plate1',
      dataIndex: 'plate1',
      render: (plate1, allData) => (
        <>
          {`${plate1}${_.get(allData, 'vehTypeCode', '') ? ' ' : '-'}${_.get(allData, 'plate2', '')} `}
          {/* <br /> */}
          {`${_.get(allData, 'offRegDesc', '')} `}
          {/* <br /> */}
          {_.get(allData, 'vehTypeCode', '') ? `รย.${parseInt(_.get(allData, 'vehTypeCode', ''), 10)}` : ''}
        </>
      ),
    },
    {
      title: 'วันเวลาบันทึกคำขอ',
      key: 'created',
      dataIndex: 'created',
      sorter: true,
      defaultSortOrder: 'descend',
      render: (v) => convertToDatetimeBE(v),
    },
    {
      title: 'ค่าธรรมเนียน (บาท)',
      key: 'amount',
      dataIndex: 'amount',
      render: (text) => parseFloat(text).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
    },
    {
      title: 'ผลพิจารณา',
      key: 'is_approved',
      dataIndex: 'is_approved',
      render: (isApproved, o) => {
        let statusColor = 'orange';
        let statusText = 'รออนุมัติ';
        if (typeof isApproved === 'boolean' && isApproved) {
          statusColor = 'green';
          statusText = 'อนุมัติ';
        } else if (typeof isApproved === 'string' && isApproved.toLowerCase() === 'true') {
          statusColor = 'green';
          statusText = 'อนุมัติ';
        } else if (typeof isApproved === 'boolean' && !isApproved) {
          statusColor = 'red';
          statusText = 'ไม่อนุมัติ';
        } else if (_.get(o, ['confirmation_id'])) {
          statusColor = 'orange';
          statusText = 'รออนุมัติ';
        } else if (
          typeof isApproved === 'string' &&
          moment(moment(o.created).format('YYYY-MM-DD')).isBefore(moment().subtract(3, 'days').format('YYYY-MM-DD'))
        ) {
          statusColor = 'red';
          statusText = 'เลยกำหนดเวลาชำระ';
        }

        return <Tag color={statusColor}>{statusText}</Tag>;
      },

    },
    {
      title: 'วันเวลาที่อนุมัติ',
      key: 'approveDetail',
      dataIndex: 'approveDetail',
      render: (v) => {
        if (v) {
          return convertToDatetimeBE(v.approveDate);
        }
        return '';
      }
    },
    {
      title: 'ชื่อ-นามสกุลผู้พิจารณา',
      key: 'approveDetailName',
      dataIndex: 'approveDetail',
      render: (v) => {
        if (v) {
          return `${v.firstName} ${v.lastName}`;
        }
        return '';
      }
    },
    {
      title: 'สถานะการชำระเงิน',
      key: 'is_success',
      dataIndex: 'is_success',
      render: (isSuccess, o) => {
        let statusColor = 'orange';
        let statusText = 'รอดำเนินการ';
        if (isSuccess) {
          statusColor = 'green';
          statusText = 'สำเร็จ';
        } else if (
          moment(moment(o.created).format('YYYY-MM-DD')).isBefore(
            moment().subtract(3, 'days').format('YYYY-MM-DD')
          )
          || _.get(o, ['confirmation_id'])
        ) {
          statusColor = 'red';
          statusText = 'เลยกำหนดเวลาชำระ';
        }
        return <Tag color={statusColor}>{statusText}</Tag>;
      },
    },
    {
      title: 'ดาวน์โหลดใบรับรอง',
      key: 'is_downloaded',
      dataIndex: 'is_downloaded',
      render: (isDownloaded, o) => {
        let statusColor = 'orange';
        let statusText = 'ยังไม่ดาวน์โหลด';
        if (isDownloaded) {
          statusColor = 'green';
          statusText = 'ดาวน์โหลดสำเร็จ';
        }
        return <Tag color={statusColor}>{statusText}</Tag>;
      },
    },
    {
      title: isAllow(userProfile.menuPermissions) ? 'จัดการ' : '',
      width: isAllow(userProfile.menuPermissions) ? '10%' : '0px',
      key: 'id',
      dataIndex: 'id',
      fixed: 'right',
      render: (v, o) => {
        return (
          isAllow(userProfile.menuPermissions) && (
            <span style={{ display: "block", height: "0px", maxHeight: "0px", position: "absolute", marginTop: "-20px" }}>
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  setTransactionId(v);
                }}
              >
                รายละเอียดและการพิจารณา
              </Link>
              {(_.get(o, ['receiptDetail']) && _.get(o, ['receiptDetail', 'link'])) && (
                <>
                  <Divider type="vertical" />
                  <a
                    target="_blank"
                    href={_.get(o, ['receiptDetail', 'link'])}
                  >
                    ใบเสร็จรับเงิน
                  </a>
                </>
              )}
              {(_.get(o, ['is_success']) && _.get(o, ['is_approved'])) && (
                <>
                  <Divider type="vertical" />
                  <a
                    target="_blank"
                    href={`${process.env.REACT_APP_API}/receipt/ownercert/${_.get(o, [
                      'reference_1'
                    ])}`}
                  >
                    ใบรับรอง
                  </a>
                </>
              )}
            </span>
          )
        );
      },
      width: isAllow(userProfile.menuPermissions) ? '195px' : '0px',
    },
  ];

  const [searchKey, setSearchKey] = useState([
    { label: 'หมายเลขอ้างอิง', key: 'reference_1' },
    // { label: 'หมวดทะเบียนรถ', key: 'plate1' },
    // { label: 'เลขทะเบียน', key: 'plate2' },
    { label: 'ชื่อ-นามสกุลผู้ยื่นคำขอ', key: 'name' },
    { label: 'ชื่อ-นามสกุลผู้พิจารณา', key: 'approver' },
    { label: 'เลขทะเบียน', key: 'plate', placeholder: 'ตัวอย่าง เช่น กก 1111, 80 1234' },
    {
      label: 'จังหวัดจดทะเบียน', key: 'offRegDesc', type: 'select2',
      options: [
        { title: "ทั้งหมด", value: "" },
        ...provinceData.map((o) => ({ title: o.province, value: o.province }))
      ]
    },
    {
      label: 'ประเภทพรบ.', key: 'plateType', type: 'select2',
      options: [
        { title: "ทั้งหมด", value: "" },
        { title: "รถยนต์", value: "1" },
        { title: "รถขนส่ง", value: "2" },
      ]
    },
    {
      label: 'ประเภทรถ', key: 'vehTypeCode', type: 'select2',
      options: [
        { title: "ทั้งหมด", value: "" },
        ...vehicleTypeData.map((o) => ({ title: o.name, value: o.id }))
      ]
    },
    {
      label: 'สถานะการชำระเงิน', key: 'is_success', type: 'select',
      options: [
        { title: "ทั้งหมด", value: "" },
        { title: "ชำระเงินแล้ว", value: "true" },
        { title: "ยังค้างชำระ", value: "false" },
      ]
    },
    {
      label: 'ผลพิจารณา', key: 'is_approved', type: 'select',
      options: [
        { title: "ทั้งหมด", value: "" },
        { title: "อนุมัติ", value: "true" },
        { title: "ไม่อนุมัติ", value: "false" },
      ]
    },
    {
      label: 'ช่วงเวลาที่ทำรายการ',
      key: 'date-range',
      type: 'rangepicker',
    },

  ]);

  return (
    <Spin spinning={loading}>
      <HeaderBlock style={{ fontSize: '20px', fontWeight: 'bold' }}>
        บันทึกพิจารณารายการคำขอใบรับรองจดทะเบียนรถรูปแบบอิเล็กทรอนิกส์
      </HeaderBlock>
      <ExportExcel
        visible={visibleExportExcelModal}
        handleOk={toggleExportExcelModal}
        handleCancel={toggleExportExcelModal}
        handleDownload={handleDownload}
        isCarmanual={true}
        total={_.get(pagination, 'total', 0)}
      />
      {/* <DrawerExport
        visible={drawerExportVisible}
        onClose={handleCloseDrawerExport}
        handleExcelSummaryDownload={handleExcelSummaryDownload}
        confirmLoading={summaryLoading}
      /> */}
      {/* <DrawerExportDaily
        visible={drawerExportDailyVisible}
        onClose={handleCloseDrawerDailyExport}
        handleExcelSummaryDailyDownload={handleExcelSummaryDailyDownload}
        confirmLoading={summaryLoading}
      /> */}
      <HeaderBlock>
        <Button type="primary" onClick={handleOpenModal}>
          ค้นหา
        </Button>
        {isAllow(userProfile.menuPermissions) && (
          <div>
            <Button
              style={{ margin: '0 0 0 16px' }}
              icon="export"
              onClick={toggleExportExcelModal}
            >
              Export ข้อมูลการทํารายการ
            </Button>
            <Button
              style={{ margin: '0 0 0 16px' }}
              icon="export"
              onClick={handleDownloadSummary}
            >
              Export รายงานสรุป
            </Button>
            {/* <Button
              Export ข้อมูลสรุปการทํารายการ
            </Button> */}
            {/* <Button
              style={{ margin: '0 0 0 16px' }}
              icon="export"
              onClick={handleOpenDrawerDailyExport}
            >
              Export ข้อมูลรายวัน
            </Button> */}
          </div>
        )}
      </HeaderBlock>
      <div style={{ overflow: "hidden" }}>
        <Table
          columns={columns(setTransactionId)}
          rowKey={(record) => record.id}
          dataSource={data}
          pagination={pagination}
          onChange={handleTableChange}
          scroll={{ x: 'max-content' }}
        />
      </div>
      <FilterModal
        visible={visible}
        handleSearch={handleSearch}
        handleCancel={handleCancel}
        renderInputFields={searchKey}
      />
      <Detail
        transactionId={transactionId}
        setTransactionId={setTransactionId}
      />
    </Spin>
  );
};
