import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Form, Input, Button, Switch, InputNumber, Row, Col, Icon, Popover
} from 'antd';
import _ from 'lodash';
import styled from 'styled-components';
import { MainContext } from '../../context/main';
import Swal from 'sweetalert2';

const Wrapper = styled.div``;

export const PopoverWrapper = styled.div`
  width: ${props => props.width};
`;

const formItemLayout = {
  labelCol: {
    xs: { span: 10 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 10 },
    sm: { span: 5 },
  },
};

const formItemLayoutLong = {
  labelCol: {
    xs: { span: 10 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 10 },
    sm: { span: 15 },
  },
};

const firstColumnItemLayout = {
  labelCol: {
    xxl: { span: 15 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xxl: { span: 7 },
    sm: { span: 5 },
  },
};

const secondColumnItemLayout = {
  labelCol: {
    xxl: { span: 10 },
    sm: { span: 5 },
  },
  wrapperCol: {
    sm: { span: 5 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const Formedit = ({
  form: { getFieldDecorator, setFieldsValue, validateFieldsAndScroll },
  data,
  onSave,
  loading,
  back,
  fetchData
}) => {
  const history = useHistory();
  useEffect(() => {
    setFieldsValue({
      serial_number: _.get(data, 'serial_number'),
      detail: _.get(data, 'detail', ''),
      active: _.get(data, 'active', false),
      paper_count: _.get(data, 'paper_count', 0),
      paper_min: _.get(data, 'paper_min', 0),
      ink_count: _.get(data, 'ink_count', 0),
      ink_min: _.get(data, 'ink_min', 0),
      notify_mail_to: _.get(data, 'notify_mail_to', ''),
      notify_mail_cc: _.get(data, 'notify_mail_cc', ''),
      operator_code: _.get(data, 'operator_code', ''),
      kiosk_code: _.get(data, 'kiosk_code', ''),
      operator_fullname: _.get(data, 'operator_fullname', ''),
      vehicle_circle_fullname: _.get(data, 'vehicle_circle_fullname', ''),
      ipAddress: _.get(data, 'ipAddress', ''),
      macAddress: _.get(data, 'macAddress', ''),
      next_kioskPaper_reference: _.get(data, 'next_kioskPaper_reference')
    });
  }, [data]);

  const [visible, setVisible] = useState(false);
  const [visibleEditNext, setVisibleEditNext] = useState(false);

  const [resultsEditNext, setResultsEditNext] = useState();

  const onVisibleChange = () => {
    setVisible((prevState) => !prevState);
    setBegin()
    setEnd()
    setPaper()
  };

  const onVisibleEditNextChange = () => {
    setVisibleEditNext((prevState) => !prevState);
  };

  useEffect(() => {
    if (visibleEditNext) {
      const res = request.get(`/kiosk/${_.get(data, 'id')}/usage_paper`)
        .then((res) => {
          setResultsEditNext(res)
        })
    }
  }, [visibleEditNext])

  const [begin_ref, setBegin] = useState();
  const [end_ref, setEnd] = useState();
  const [paper_count, setPaper] = useState();

  const { request } = useContext(MainContext);

  // const handleSelectChange = (value) => {
  //   console.log(value);
  //   const{onSave} = props;
  //   onSave(value);
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        onSave(values);
      }
    });
  };

  const handleSubmitRef = async (e) => {
    try {
      const res = await request.put(`/kiosk/${_.get(data, 'id')}/renew_paper`, {
        begin_ref,
        end_ref,
        paper_count
      });
    } catch (error) {
      console.error(error);
    } finally {
      onVisibleChange()
      fetchData()
    }
  }

  const handleReset = async (e) => {
    try {
      const res = await request.post(`/kiosk/${_.get(data, 'id')}/reset_paper`, {
        begin_ref,
        end_ref,
        paper_count
      });
    } catch (error) {
      console.error(error);
    } finally {
      fetchData()
    }
  }

  const handleChooseEdit = async (object) => {
    try {
      const res = await request.post(`/kiosk/${_.get(data, 'id')}/undo_paper`, object);
    } catch (error) {
      console.error(error);
    } finally {
      fetchData()
      const res = request.get(`/kiosk/${_.get(data, 'id')}/usage_paper`)
        .then((res) => {
          setResultsEditNext(res)
        })
    }
  }

  const handleDelete = async (e) => {
    // confirmation for delete
    Swal.fire({
      title: 'คุณแน่ใจหรือไม่ที่จะลบตู้นี้?',
      text: "ข้อมูลทั้งหมดที่เกี่ยวข้องกับตู้นี้จะถูกลบทั้งหมด!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'ใช่, ลบตู้นี้!',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.value) {
        deleteKiosk()
      }
    }
    )
  }

  const deleteKiosk = async () => {
    try {
      const res = await request.delete(`/kiosk/${_.get(data, 'id')}`);
      history.push('/kiosk');
      back();
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <Wrapper>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Form.Item {...formItemLayout} label={<span>หมายเลขตู้</span>}>
              {getFieldDecorator('serial_number', {
                // rules: [
                //   {
                //     required: true,
                //     whitespace: true,
                //     message: 'กรุณากรอกหมายเลขตู้',
                //   },
                // ],
              })(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col>
            <Form.Item {...formItemLayoutLong} label="รายละเอียด">
              {getFieldDecorator('detail', {
                rules: [{ required: true, message: 'กรุณากรอกรายละเอียด' }],
              })(<Input disabled={_.get(data, ['status']) === 'canceled'} />)}
            </Form.Item>
          </Col>
          <Col
            xxl={10}
            sm={24}
          // span={24}
          >
            <Form.Item {...firstColumnItemLayout} label="ปริมาณกระดาษ">
              {getFieldDecorator('paper_count', {
                // rules: [{ required: true, message: 'กรุณากรอกปริมาณกระดาษ' }],
              })(
                <InputNumber
                  readOnly
                  disabled
                />,
              )}
              <span className="ant-form-text"> แผ่น</span>
              <Popover
                content={
                  <PopoverWrapper width="300px">
                    <Row>
                      <Col sm={9}>เลขคุมเริ่มต้น</Col>
                      <Col sm={1}>:</Col>
                      <Col sm={14}>
                        <Input
                          style={{ width: '100%' }}
                          name="begin_ref"
                          onChange={(e) => setBegin(e.target.value)} value={begin_ref}
                        /></Col>
                      <Col sm={9}>เลขคุมสุดท้าย</Col>
                      <Col sm={1}>:</Col>
                      <Col sm={14}>
                        <Input
                          style={{ width: '100%' }}
                          name="end_ref"
                          onChange={(e) => setEnd(e.target.value)} value={end_ref}
                        /></Col>
                      <Col sm={9}>จำนวนกระดาษ</Col>
                      <Col sm={1}>:</Col>
                      <Col sm={14}>
                        <InputNumber
                          type="number"
                          style={{ width: '100%' }}
                          name="paper_count"
                          onChange={(e) => setPaper(e)}
                          value={paper_count}
                          min={1}
                        /></Col>
                      <Col sm={24}><Button type="primary" onClick={handleSubmitRef}>แก้ไข</Button></Col>
                    </Row>
                  </PopoverWrapper>
                }
                trigger="click"
                visible={visible}
                onVisibleChange={onVisibleChange}
                placement="right"
                getPopupContainer={() => document.getElementById("icon-edit")}
              >
                <Icon id="icon-edit" type="edit" style={{ fontSize: '20px', color: '#09c' }} />
              </Popover>
            </Form.Item>
          </Col>
          <Col
            xxl={14}
            sm={24}
          // span={24}
          >
            <Form.Item
              {...secondColumnItemLayout}
              label="แจ้งเตือนเมื่อปริมาณกระดาษคงเหลือ"
              className="two-rows-label"
            >
              {getFieldDecorator('paper_min', {
                // rules: [{ required: true, message: 'กรุณากรอกปริมาณกระดาษ' }],
              })(
                <InputNumber
                  disabled={_.get(data, ['status']) === 'canceled'}
                  min={0}
                />,
              )}
              <span className="ant-form-text"> แผ่น</span>
            </Form.Item>
          </Col>
          <Col
            xxl={10}
            sm={24}
          // span={24}
          >
            <Form.Item
              {...firstColumnItemLayout}
              label="ปริมาณหมึกพิมพ์คงเหลือที่สามารถพิมพ์ได้"
              className="two-rows-label"
            >
              {getFieldDecorator('ink_count', {
                // rules: [{ required: true, message: 'กรุณากรอกปริมาณหมึกพิมพ์' }],
              })(
                <InputNumber
                  disabled={_.get(data, ['status']) === 'canceled'}
                  min={0}
                />,
              )}
              <span className="ant-form-text"> แผ่น</span>
            </Form.Item>
          </Col>
          <Col
            xxl={14}
            sm={24}
          // span={24}
          >
            <Form.Item
              {...secondColumnItemLayout}
              label="แจ้งเตือนเมื่อปริมาณหมึกพิมพ์คงเหลือ"
              className="two-rows-label"
            >
              {getFieldDecorator('ink_min', {
                // rules: [{ required: true, message: 'กรุณากรอกปริมาณหมึกพิมพ์' }],
              })(
                <InputNumber
                  disabled={_.get(data, ['status']) === 'canceled'}
                  min={0}
                />,
              )}
              <span className="ant-form-text"> หน่วย</span>
            </Form.Item>
          </Col>
          <Col sm={24}>
            <Form.Item
              {...formItemLayout}
              label={`เครื่องมือ`}
            >
              <ResetButton onClick={handleReset}>รีเซ็ตเลขคุม</ResetButton>
              <Popover
                content={
                  <PopoverWrapper width="500px">
                    <Row>
                      <Col sm={1}>&nbsp;</Col>
                      <Col sm={8}><strong>เลขคุม</strong></Col>
                      <Col sm={7}><strong>สถานะ</strong></Col>
                      <Col sm={8} style={{ textAlign: "center" }}><strong>เปลี่ยนสถานะเลขคุม</strong></Col>
                    </Row>
                    {
                      resultsEditNext && resultsEditNext.results.map((item, index) => {
                        return (
                          <Row>
                            <Col sm={1}><strong>{resultsEditNext.next_kioskPaper_reference == item.reference ? ">" : ""}</strong></Col>
                            <Col sm={8}>{item.reference}</Col>
                            <Col sm={7}>{item.status == "used" ? "ใช้งานแล้ว" : "ยังไม่ได้ใช้งาน"}</Col>
                            <Col sm={8} style={{ textAlign: "center" }}>
                              <ChooseEdit
                                onClick={() => handleChooseEdit({
                                  next_kioskPaper_id: resultsEditNext.next_kioskPaper_id,
                                  next_kioskPaper_reference: resultsEditNext.next_kioskPaper_reference,
                                  undo_kioskPaper_id: item._id,
                                  undo_kioskPaper_reference: item.reference,
                                })}>
                                เลือก
                              </ChooseEdit>
                            </Col>
                          </Row>
                        )
                      })
                    }
                  </PopoverWrapper>
                }
                trigger="click"
                visible={visibleEditNext}
                onVisibleChange={onVisibleEditNextChange}
                placement="right"
                getPopupContainer={() => document.getElementById("edit-next")}
              >
                <EditNextButton id="edit-next">เปลี่ยนสถานะเลขคุม</EditNextButton>
              </Popover>
              <Button
                type="danger"
                ghost
                onClick={() => {
                  handleDelete()
                }}
              >
                ลบตู้Kiosk
              </Button>
            </Form.Item>
          </Col>
          <Col sm={24}>
            <Form.Item
              {...formItemLayout}
              label={<span>เลขคุมต่อไป</span>}
            >
              {getFieldDecorator('next_kioskPaper_reference', {

              })(
                <Input
                  readOnly
                  disabled
                />,
              )}
            </Form.Item>
          </Col>
          <Col sm={24}>
            <Form.Item
              {...formItemLayout}
              label={<span>อีเมลผู้รับ</span>}
            >
              {getFieldDecorator('notify_mail_to', {
                rules: [{ required: true, message: 'กรุณากรอกอีเมลผู้รับ' }],
              })(
                <Input
                  disabled={_.get(data, ['status']) === 'canceled'}
                />,
              )}
            </Form.Item>
          </Col>
          <Col sm={24}>
            <Form.Item
              {...formItemLayout}
              label={<span>อีเมลผู้รับ (สำเนา)</span>}
            >
              {getFieldDecorator('notify_mail_cc', {
                rules: [{ required: true, message: 'กรุณากรอกอีเมลผู้รับสำเนา' }],
              })(
                <Input
                  disabled={_.get(data, ['status']) === 'canceled'}
                />,
              )}
            </Form.Item>
          </Col>
          <Col sm={24}>
            <Form.Item
              {...formItemLayout}
              label={<span>รหัสเจ้าหน้าที่</span>}
            >
              {getFieldDecorator('operator_code', {
                rules: [{ required: true, message: 'กรุณากรอกรหัสเจ้าหน้าที่' }],
              })(
                <Input
                  disabled={_.get(data, ['status']) === 'canceled'}
                />,
              )}
            </Form.Item>
          </Col>
          <Col sm={24}>
            <Form.Item
              {...formItemLayout}
              label={<span>รหัสตู้</span>}
            >
              {getFieldDecorator('kiosk_code', {
                rules: [{ required: true, message: 'กรุณากรอกรหัสตู้' }],
              })(
                <Input
                  disabled={_.get(data, ['status']) === 'canceled'}
                />,
              )}
            </Form.Item>
          </Col>
          <Col sm={24}>
            <Form.Item
              {...formItemLayout}
              label={<span>ชื่อผู้รับเงิน</span>}
            >
              {getFieldDecorator('operator_fullname', {
                rules: [{ required: true, message: 'กรุณากรอกชื่อผู้รับเงิน' }],
              })(
                <Input
                  disabled={_.get(data, ['status']) === 'canceled'}
                />,
              )}
            </Form.Item>
          </Col>
          <Col sm={24}>
            <Form.Item
              {...formItemLayout}
              label={<span>ชื่อนายทะเบียน</span>}
            >
              {getFieldDecorator('vehicle_circle_fullname', {
                rules: [{ required: true, message: 'กรุณากรอกชื่อนายทะเบียน' }],
              })(
                <Input
                  disabled={_.get(data, ['status']) === 'canceled'}
                />,
              )}
            </Form.Item>
          </Col>
          <Col sm={24}>
            <Form.Item
              {...formItemLayout}
              label={<span>IP Address</span>}
            >
              {getFieldDecorator('ipAddress', {
                rules: [{ required: true, message: 'กรุณากรอก IP Address' }],
              })(
                <Input
                  disabled={_.get(data, ['status']) === 'canceled'}
                />,
              )}
            </Form.Item>
          </Col>
          <Col sm={24}>
            <Form.Item
              {...formItemLayout}
              label={<span>MAC Address</span>}
            >
              {getFieldDecorator('macAddress', {
                rules: [{ required: true, message: 'กรุณากรอก MAC Address' }],
              })(
                <Input
                  disabled={_.get(data, ['status']) === 'canceled'}
                />,
              )}
            </Form.Item>
          </Col>
        </Row>
        {/* <Form.Item {...formItemLayout} label="สถานะ">
          {getFieldDecorator('active', { valuePropName: 'checked' })(
            <Switch />,
          )}
          </Form.Item> */}
        <Form.Item {...tailFormItemLayout}>
          <Button
            style={{ marginRight: '20px' }}
            type="danger"
            ghost
            onClick={back}
          >
            ย้อนกลับ
          </Button>
          <Button
            disabled={_.get(data, ['status']) === 'canceled'}
            loading={loading}
            type="primary"
            htmlType="submit"
          >
            บันทึก
          </Button>
        </Form.Item>
      </Form>
    </Wrapper>
  );
};

export default Form.create()(Formedit);

const ResetButton = styled.p`
  color: #0e90e2;
  text-decoration: underline;
  cursor: pointer;
  margin: 0px;
`;

const EditNextButton = styled.p`
  color: #0e90e2;
  text-decoration: underline;
  cursor: pointer;
  margin: 0px;
`;

const ChooseEdit = styled.span`
  color: #0e90e2;
  text-decoration: underline;
  cursor: pointer;
  margin: 0px;
`;