import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { convertToDatetimeBE } from '../../utils'
import { Table, Tag, Button, Icon, Divider, Popconfirm, Popover, Spin } from 'antd';
import { TagContainer, ButtonTag } from './style';
import _ from 'lodash';
import { MainContext } from '../../context/main';

import {
  getFilter,
  getSort,
  getPage,
  getColumnSearchProps,
  perPage,
  defaultPageSize,
  pageSizeOptions,
  showSizeChanger,

} from '../../utils/table';
import { formatPhoneNumber } from '../../utils';
import { HeaderBlock } from '../../style/global';
import FilterModal from '../../component/FilterModal';
import FormModal from './form';
const searchKey = [
  { label: 'ชื่อกลุ่มผู้ใช้งาน', key: 'groupName' },
  {
    label: 'สถานะ',
    key: 'isActive',
    type: 'select',
    options: [
      { title: 'ไม่ระบุ', value: '' },
      { title: 'เปิดใช้งาน', value: 'true' },
      { title: 'ปิดใช้งาน', value: 'false' },
    ],
  },
];

const isAllow = (menuPermissions, targetmenuPermissions) => {
  const thisMenuId = "10"
  const permission = _.find(menuPermissions, { menuId: thisMenuId });
  // console.log('permission', permission);
  return permission && permission.canManage;
};

function getColor(status) {
  switch (status) {
    case true:
      return 'green';
    case false:
      return 'orange';
    default:
      return '';
  }
}

function getWord(status) {
  switch (status) {
    case true:
      return 'เปิดใช้งาน';
    case false:
      return 'ปิดใช้งาน';
    default:
      return 'ไม่พร้อมใช้งาน';
  }
}
const PopOverTag = ({ adminpermission, onStatusChange }) => {
  const currentStatus = _.get(adminpermission, ['isActive']);
  const color = getColor(currentStatus);
  const [visible, setVisible] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const { request } = useContext(MainContext);

  const onVisibleChange = (e) => {
    setVisible((prevState) => !prevState);
  };

  const handleSelect = (newStatus) => async (e) => {
    if (newStatus === currentStatus) return;

    const body = {
      isActive: newStatus,
    };
    setIsLoading(true);

    const res = await request.put(`/adminpermission/${adminpermission._id}/isActive`, body);

    setIsLoading(false);

    if (_.get(res, ['success'])) {
      onStatusChange({
        id: adminpermission._id,
        newStatus,
      });
    }

    onVisibleChange();
  };

  return (
    <Popover
      content={(
        <Spin spinning={isLoading}>
          <TagContainer>
            <ButtonTag underline onClick={handleSelect(true)}>
              <Tag color={currentStatus === true ? 'grey' : 'green'}>
                {getWord(true)}
              </Tag>
            </ButtonTag>
            <ButtonTag underline onClick={handleSelect(false)}>
              <Tag color={currentStatus === false ? 'grey' : 'orange'}>
                {getWord(false)}
              </Tag>
            </ButtonTag>
          </TagContainer>
        </Spin>
      )}
      title="สถานะการเปิดใช้งาน"
      trigger="click"
      visible={visible}
      onVisibleChange={onVisibleChange}
    >
      <ButtonTag>
        <Tag color={color}>
          {' '}
          {getWord(currentStatus)}
        </Tag>
      </ButtonTag>
    </Popover>
  );
};
export default () => {
  const { request, userProfile } = useContext(MainContext);
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState({});
  const [sorter, setSorter] = useState({
    sort: { key: 'created', desc: false },
  });
  const [pagination, setPagination] = useState({
    // pageSize: perPage,
    defaultPageSize,
    pageSizeOptions,
    showSizeChanger,
  });
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [formVisible, setFormVisible] = useState(false);
  const [targetadminpermission, settargetadminpermission] = useState(null);


  const fetchData = async (params = {}) => {
    try {
      setLoading(true);
      const res = await request.get('/adminpermission', {
        ...params,
        ...searchData,
      });

      const totalData = _.get(res, ['total_data'], 0);

      setData(_.get(res, ['results'], []));
      setPagination({ ...pagination, total: totalData, current: params.page, pageSize: params.per_page });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (adminpermissionId) => async () => {
    try {
      setLoading(true);
      const res = await request.delete(`/adminpermission/${adminpermissionId}`);

      if (_.get(res, ['success'])) {
        fetchData();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (paginationChange, filters, sorterChange) => {
    const pager = { ...pagination };
    pager.current = paginationChange.current;

    setPagination(pager);
    setSorter(getSort(sorterChange));

    fetchData({
      ...getFilter(filters),
      ...getSort(sorterChange),
      ...getPage(paginationChange),
    });
  };

  useEffect(() => {
    fetchData({
      ...getPage(pagination),
      ...sorter,
    });
  }, [searchData]);

  const handleSearch = (value) => {
    pagination.current = 1;
    setSearchData({ ..._.omitBy(value, (o) => _.isNil(o) || _.isEmpty(o)) });

    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOpenModal = () => {
    setVisible(true);
  };

  const handleOpenModalForm = (adminpermission) => (e) => {
    settargetadminpermission(adminpermission);
    setFormVisible(true);
    // console.log(ToS);
  };

  const handleCloseModalForm = () => {
    settargetadminpermission(null);
    setFormVisible(false);
  };
  const onStatusChange = ({ newStatus, id }) => {
    setData(
      _.map(data, (o) => {
        if (o._id === id) o.isActive = newStatus;

        return o;
      }),
    );
  };

  const columns = [
    {
      title: 'ลำดับ',
      key: 'index',
      dataIndex: 'index',
    },
    {
      title: 'ชื่อกลุ่มผู้ใช้งาน',
      key: 'groupName',
      render: (record) => `${record.groupName}`,
    },
    {
      title: 'สถานะ',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (status, o) => (
        isAllow(userProfile.menuPermissions) ? (
        <PopOverTag onStatusChange={onStatusChange} adminpermission={o} />
        ) : (
          <Tag color={status ? 'green' : 'orange'}>{status ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}</Tag>
        )
      ),
    },
    {
      title: 'วันเวลาสร้างข้อมูล',
      key: 'created',
      render: (record) => convertToDatetimeBE(record.created),
    },
    {
      title: 'วันเวลาแก้ไขล่าสุด',
      key: 'created',
      render: (record) => convertToDatetimeBE(record.updated),
    },
    {
      title: isAllow(userProfile.menuPermissions) ? 'จัดการ' : '',
      width: isAllow(userProfile.menuPermissions) ? '10%' : '0px',
      key: 'action',
      render: (o) =>
        isAllow(userProfile.menuPermissions) ? (
          <span>
            <a
              onClick={
                isAllow(userProfile.menuPermissions, o.menuPermissions)
                  ? handleOpenModalForm(o)
                  : () => { }
              }
            >
              แก้ไข
            </a>
            <Divider type="vertical" />
            <Popconfirm
              key="delete"
              title="กรุณายืนยันการลบ กลุ่มผู้ใช้งาน ดังกล่าว"
              onConfirm={handleDelete(_.get(o, ['_id']))}
              okText="ตกลง"
              cancelText="ยกเลิก"
            >
              <a>ลบ</a>
            </Popconfirm>
          </span>
        ) : null,
    },
  ];

  const onSubmit = async ({
    groupName,
    isActive,
    menuPermissions,
  }) => {
    setFormLoading(true);
    let body = {};
    let res;
    try {
      if (targetadminpermission) {
        body.adminpermissionId = _.get(targetadminpermission, ['_id']);
        if (targetadminpermission.groupName !== groupName)
          body.groupName = groupName;
        if (targetadminpermission.isActive !== isActive)
          body.isActive = isActive;
        body.menuPermissions = menuPermissions;
        res = await request.put('/adminpermission', body);
      } else {
        body = {
          groupName,
          isActive,
          menuPermissions,
        };
        res = await request.post('/adminpermission', body);
      }

      if (_.get(res, ['success'])) {
        handleCloseModalForm();
        fetchData({
          ...getPage(pagination),
          ...sorter,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setFormLoading(false);
    }
  };

  return (
    <>
      <HeaderBlock style={{ fontSize: '20px', fontWeight: 'bold' }}>
        บันทึก/แก้ไข กลุ่มผู้ใช้งานและสิทธิ์ใช้งานระบบ
      </HeaderBlock>
      <HeaderBlock>
        <Button type="primary" onClick={handleOpenModal}>
          ค้นหา
        </Button>
        {isAllow(userProfile.menuPermissions) && (
          <Button type="primary" onClick={handleOpenModalForm(null)}>
            เพิ่ม
          </Button>
        )}
      </HeaderBlock>
      <Table
        columns={columns}
        rowKey={(record) => record._id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 'max-content' }}
      />
      <FilterModal
        visible={visible}
        handleSearch={handleSearch}
        handleCancel={handleCancel}
        renderInputFields={searchKey}
      />
      <FormModal
        onSubmit={onSubmit}
        formLoading={formLoading}
        data={targetadminpermission}
        visible={formVisible}
        onCancel={handleCloseModalForm}
      ></FormModal>
    </>
  );
};
